import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeRoleAPI, resendInvitationAPI } from '../helperFunctions/dataServices.js'
import DashboardTranslations from '../../DashboardTranslations.js';
import "react-toggle-switch/dist/css/switch.min.css"
import '../styles/memberCard.scss';
import {getErrorMessage} from "../../messages/errors";
import SuccessModal from "./SuccessModal";

class MemberCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: "",
      showSuccess: false
    }
  }

  componentDidMount() {
    var role = "member"
    if (this.props.memberDetails.is_admin === true) {
      role = "admin"
    }
    this.setState({role : role})
  }

  handleChange = event => {
    var value = event.target.value
    this.setState({ [event.target.name]: event.target.value }, () => this.changeRole(value));
  }

  resendInvitation() {
    const payload = {invitee_email: this.props.memberDetails.email}

    resendInvitationAPI(payload).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({ loading: false, showSuccess: true})
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
        .catch((e) => {
          this.setState({loading: false, showError: true})
        })
  }

  handleSuccess() {
    this.setState({showSuccess: false})
  }

  changeRole(isAdmin) {
    var payload = {}
    var isAdminValue = true
    if (isAdmin === "member") {
      isAdminValue = false
    }
    payload.is_admin = isAdminValue
    this.setState({loading: true})
    changeRoleAPI(payload, this.props.memberDetails.id).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({ loading: false})
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  render() {
    var { role, showSuccess } = this.state
    var { user, memberDetails } = this.props

    var name = DashboardTranslations.team_page_invited_email_header
    var nameColor = "#3BB324"
    var fontStyle = "italic"
    var disabled = true
    if (memberDetails.display_name) {
      name = memberDetails.display_name
      nameColor = "#000000"
      fontStyle = "normal"
      disabled = false
    }

    if (user.email === memberDetails.email) {
      disabled = true
    }

    return (
      <div className="member-card-container">
        <table style={{width: '100%'}}>
          <tbody>
          <tr>
            <td>
              <div className="name-container">
                <div className="name" style={{color: nameColor, fontStyle: fontStyle}}>
                  {name}
                </div>
              </div>
            </td>

            { !memberDetails.display_name &&
              <td style={{width: '40%'}}>
                <div className="section-link" onClick={() => this.resendInvitation()}>
                  招待を再送する
                </div>
              </td>
            }
          </tr>
          </tbody>
        </table>

        <div className="email-container">
          <div className="email">
            <div className="value">
              {memberDetails.email}
            </div>
          </div>
          <div className="role">
            <div className="label">
              {DashboardTranslations.team_page_role_label}
            </div>
            <div className="select">
              <select
                label="Select"
                name="role"
                value={role}
                disabled={disabled}
                onChange={(event) => this.handleChange(event)}>
                <option value="admin">管理者（全機能にアクセスできます。）</option>
                <option value="member">一般（お支払以外の全機能にアクセスできます。）</option>
              </select>
            </div>
            <div className="explanatory-text">ユーザーがログインすると役割が変更できます。</div>
          </div>
        </div>

        <SuccessModal
            successMessage='招待を再送しました'
            handleClose={this.handleSuccess.bind(this)}
            showSuccess={showSuccess}
        />

      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        selectedScreen: state.selectedScreen,
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token,
        user: state.user
    }
};

MemberCard = connect(mapStateToProps)(MemberCard);

export default MemberCard;
