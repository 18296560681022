const errorMessages = {
    'generic': {
        'en': 'Please refresh the page.',
        'jp': 'ページをリフレッシュしてください。'
    },
    'jobs_page_payment_required_error_text': {
        'en': 'Once you have paid, you can start publishing articles.',
        'jp': 'お支払いを完了すると記事掲載を開始できます。'
    },
    'mismatched_plan_purchased': {
        'en': 'The plan you are using is {{planName}}.\nPlease create an article that matches your plan.',
        'jp': 'ご利用中のプランは{{ planName }}です。\nご利用プランにマッチした記事を作成してください。'
    },
    'plan_max_reached': {
        'en': 'The plan you are using is {{planName}}.\nYou can publish this article by stopping any of the articles being published.',
        'jp': 'ご利用中のプランは{{ planName }}です.\n掲載中の記事のどれかを停止すると、この記事を掲載する事ができます。'
    },
    'from_api_response': {
        'en': '{{ apiErrorMessage }}',
        'jp': '{{ apiErrorMessage }}'
    },
    'register_page_email_verification_error_message': {
        'en': 'Sorry, we couldn\'t verify your email. Possible reasons: you have already clicked the link earlier and thus verified your email already.',
        'jp': 'メールの認証は完了しているので、登録したメールとパスワードでログインしてください。'
    },
    'register_page_passwords_do_not_match_error_message': {
        'en': 'The passwords do not match',
        'jp': '入力したパスワードが異なります。'
    },
    'register_page_agree_to_policy_error_message': {
        'en': 'Please view the privacy policy and check the button to confirm',
        'jp': '利用規約に同意してください'
    },
    'add_interview_times_select_date_and_time_error_message': {
        'en': 'Please select a date and time',
        'jp': '面接枠の日時を正しく選択してください。'
    },
    'error_message_for_valid_mobile': {
        'en': 'You can not register with the prefix (050). Please try with another Mobile Number, or contact info@workjapan.jp for help.',
        'jp': '(050)で始まる番号の登録はできません。別の携帯番号を登録してください。不明な場合はinfo@workjapan.jpまで！'
    },
    'add_job_image_upload_exceed_maximum_number_error_message': {
        'en': 'Max 5 images only',
        'jp': '掲載できる画像は５枚までです。'
    },
    'add_job_nationality_exceed_maximum_error_message': {
        'en': 'Max 5 nationality only',
        'jp': '選択は5件までです。'
    },
    'billing_page_trial_plan_payment_failed_message': {
        'en': 'We could not charge your card. Please add another card.',
        'jp': '決済ができませんでした。他の決済方法でお支払いください。'
    },
    'add_job_work_images_not_added_error': {
        'en': '仕事内容が伝わる写真を掲載しましょう！ \n 掲載数（5枚まで）が多いほど応募が増えます！',
        'jp': '仕事内容が伝わる写真を掲載しましょう！ \n 掲載数（5枚まで）が多いほど応募が増えます！'
    },
    'team_page_add_member_email_already_present_message': {
        'en': 'Email already present',
        'jp': 'このメールアドレスは既に登録されています。'
    }
}


export function getErrorMessage(key, replacements) {
    if (replacements) {
        return errorMessages[key].jp.replace(/{{\s\w+\s}}/g, function(all) {
            return cleanReplacements(replacements)[all] || all;
        })
    } else {
        return errorMessages[key].jp;
    }
}

function cleanReplacements(replacements) {
    for (const oldProp in replacements) {
        if (replacements.hasOwnProperty(oldProp)) {
            Object.defineProperty(replacements, `{{ ${oldProp} }}`, {value: replacements[oldProp]});
            delete replacements[oldProp];
        }
    }
    return replacements;
}
