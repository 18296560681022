import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import closeModal from "../../images/svg/close-modal.svg";
import mail from "../../images/svg/mail.svg";

import '../styles/employerRegistrationSuccessModal.scss'


const EmployerRegistrationSuccessModal = (props) => (
    <Dialog
        open={props.showSuccess}
        onClose={props.handleCloseReloadPage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="registration-success-modal"
    >
        <div className="container">
            <img src={closeModal} alt="close" className="close-icon" onClick={() => props.handleClose()}/>
            <div style={{height: 33}}/>
            <img src={mail} alt="mail" className="mail-icon"/>
            <div style={{height: 11}}/>
            <div className="title">メールアドレスを認証してください！</div>
            <div style={{height: 16}}/>
            <div className="email">
                {props.email}
                <div className="confirmation-message">に確認メールを送りました</div>
            </div>
            <div style={{height: 16}}/>
            <div className="verify-email-message">メールアドレスを認証して、<br/>求人記事の作成を始めましょう！</div>
            <div style={{height: 16}}/>
            <div className="check-spam-message">※メールが届かない場合は迷惑メールもご確認ください。</div>
        </div>
      </Dialog>
);

export default EmployerRegistrationSuccessModal;
