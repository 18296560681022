import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import closeIcon from '../../images/close-icon.png';
import './styles/selectPlanWarningModal.scss';


const SelectPlanWarningModal = (props) => (
    <div>
      <Dialog
        open={props.showSuccess}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="success-modal"
      >
        <DialogTitle className="dialog-title" id="alert-dialog-title">
            <img
                height="15"
                width="15"
                onClick={() => props.handleClose()}
                className="main-close-icon"
                src={closeIcon}
                alt="close-icon" />
        </DialogTitle>
        <div className="dialog-message-container">
          <div style={{height: 175, padding: 15}}>
            <DialogContentText id="alert-dialog-description" style={{fontSize: 14}}>
              <div className="close-icon-center" >
                <div className="success-modal-header-text">
                    ご注意ください
                </div>
              </div>

                <div style={{whiteSpace: 'pre-line'}} className='warning-modal-text'>
                    購入後のプラン変更やキャンセルはできません。<br/>
                    請求書払いで審査が不通過となった場合は<br/>
                    ご利用ができません。ご注意ください。
                </div>
              <div className="ok-button" onClick={() => props.warningAccepted()}>OK</div>
            </DialogContentText>
          </div>
        </div>
      </Dialog>
    </div>
);

export default SelectPlanWarningModal;
