import React, { Component } from 'react';
import InnerHeader from '../components/InnerHeader.js'
import { connect } from 'react-redux';
import DashboardTranslations from '../../DashboardTranslations.js';

import 'react-overlay-loader/styles.css';
import './styles/selectPreviousPaymentMethod.scss';

class SelectPreviousPaymentMethod extends Component {
  backArrowOnClick() {
      this.props.history.push({
          pathname: '/dashboard/billing/select-plan',
          state: {
              subscription_status: this.props.location.state.subscription_status,
              is_cc_added_in_gmo: this.props.location.state.is_cc_added_in_gmo,
              verification_status: this.props.location.state.verification_status,
              paidBy: this.props.location.state.paidBy,
              currSubscriptionData: this.props.location.state.currSubscriptionData
          }
      });
  }

    continueSameMethod() {
        this.props.history.push({
            pathname: '/dashboard/billing/confirm-purchase', state: {
                subscription_status: this.props.location.state.subscription_status,
                is_cc_added_in_gmo: this.props.location.state.is_cc_added_in_gmo,
                verification_status: this.props.location.state.verification_status,
                plan: this.props.location.state.plan,
                paidBy: this.props.location.state.paidBy,
                paymentMethod: this.props.location.state.paidBy,
                currSubscriptionData: this.props.location.state.currSubscriptionData,
                backToSelectPreviousPaymentMethod: true
            }
        });
    }

    changeMethod() {
        this.props.history.push({
            pathname: '/dashboard/billing/select-payment-method',
            state: {
                subscription_status: this.props.location.state.subscription_status,
                is_cc_added_in_gmo: this.props.location.state.is_cc_added_in_gmo,
                verification_status: this.props.location.state.verification_status,
                paidBy: this.props.location.state.paidBy,
                plan: this.props.location.state.plan,
                currSubscriptionData: this.props.location.state.currSubscriptionData,
                backToSelectPreviousPaymentMethod: true
            }
        });
    }

  render() {
    return (
      <div className="billing-page-container">
        <InnerHeader
            primary={false}
            history={this.props.history}
            headerText={DashboardTranslations.select_previous_payment_method_page_title}
            backArrowOnClick={this.backArrowOnClick.bind(this)}
        />

          以前のお支払い方法は{(this.props.location.state.paidBy === 'CC') ? 'クレジットカード払い' : '請求書払い'}でした。
          <br/>

        <div className="billing-page-inner" style={{margin: "20px auto"}}>
          <div className="transactions-button-container">
            <div className="section-link" onClick={() => this.continueSameMethod()}>
                {(this.props.location.state.paidBy === 'CC') ? 'このクレジットカードで支払う' : '請求書払いで支払う'}
            </div>
              <div className="section-link" onClick={() => this.changeMethod()}>
                  {DashboardTranslations.change_payment_method}
              </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token,
        isAdmin: state.user.isAdmin,
    }
};

SelectPreviousPaymentMethod = connect(mapStateToProps)(SelectPreviousPaymentMethod);

export default SelectPreviousPaymentMethod;
