import React, { Component } from 'react';
import { connect } from 'react-redux';
import { jobs, getInterviewsAPI, deleteSlotAPI } from '../helperFunctions/dataServices.js'
import { ENV_TYPE } from '../../constants';
import DashboardTranslations from '../../DashboardTranslations.js';
import FontAwesomeIcon from 'react-fontawesome'
import ConfirmModal from '../components/ConfirmModal'
import ReactGA from 'react-ga';
import InnerHeader from '../components/InnerHeader.js'
import ScaleLoader from 'react-spinners/ScaleLoader';
import SuccessModal from '../components/SuccessModal'
import SelectDropdown from '../components/SelectDropdown.js'
import Table, { Column } from 'react-base-table'
import 'react-base-table/styles.css'
import './styles/interviewTimes.scss';
import {getErrorMessage} from "../../messages/errors";

class InterviewTimes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      interviews: [],
      originalData: [],
      deleteModal: false,
      specialText: "",
      loading: false,
      showSuccess: false,
      successMessage: "",
      selectedDeleteSlot: '',
      sortBy: "DateASC",
    }
  }

  componentDidMount() {
    this.getInterviews()
    if (ENV_TYPE === "PROD") {
      this.initializeReactGA()
    }
  }

  initializeReactGA() {
    ReactGA.initialize('UA-104174852-1');
    ReactGA.pageview('/dashboard/interview-times');
  }

  handleClose = () => {
    this.setState({ showSuccess: false, successMessage: '' });
  };

  getInterviews() {
    this.setState({loading: true})
    getInterviewsAPI().then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        var data = response.data.map((row, id) => {
          var single = {}
          single.interviewId = row.id
          single.ivDate = row.date + " " + row.start_time + "-" + row.end_time
          single.ivDateMobile = <div><div>{row.date}</div><div>{row.start_time} - {row.end_time}</div></div>
          single.ivLocation = row.interview_location
          single.ivLocationMobile = <div style={{width: 100, display: "inline-block", whiteSpace: "initial"}}>{row.interview_location}</div>
          single.workLocation = row.job_location
          single.interviewer = row.interviewer
          var applicationId = row.job_application ? row.job_application.id : 0
          single.applicant = "———"
          if (applicationId !== 0 && row.is_fixed === true) {
            single.applicant = <a style={{textTransform: "capitalize"}} href={"/dashboard/applicant-details/"+applicationId} >{row.job_application.applicant_profile.last_name + " " + row.job_application.applicant_profile.first_name}</a>
          }
          single.statusMobile = <div style={{display: "flex", alignItems: "center"}}>
            {row.is_fixed ? DashboardTranslations.interview_times_status_fixed : DashboardTranslations.interview_times_status_open}
            {row.is_fixed ? "" : <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>}
            {!row.is_fixed && <span>
              <FontAwesomeIcon
                style={{fontSize: 20, color: "#e06e6f", margin: "0px 15px", cursor: "pointer"}}
                name="trash" className="icon" onClick={() => this.handleDelete(row.id)}/>
            </span>}
            {row.is_fixed && <a href={"/dashboard/applicant-details/"+applicationId} >
              <FontAwesomeIcon
                style={{fontSize: 20, color: "#007AFF", margin: "0px 15px", cursor: "pointer"}}
                name="user" className="icon"/>
            </a>}
          </div>
          single.status = <div style={{display: "flex", alignItems: "center"}}>
            {row.is_fixed ? DashboardTranslations.interview_times_status_fixed : DashboardTranslations.interview_times_status_open}
            {!row.is_fixed && <span>
              <FontAwesomeIcon
                style={{fontSize: 20, color: "#e06e6f", margin: "0px 15px", cursor: "pointer"}}
                name="trash" className="icon" onClick={() => this.handleDelete(row.id)}/>
            </span>}
          </div>

          return single;
        })
        this.setState({interviews: data, loading: false, originalData: data})
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  handleDelete(id) {
    this.state.interviews.map(int => {
      if (parseInt(int.interviewId) === parseInt(id)) {
        this.setState({specialText: int.ivDate + " ?"})
      }
    })
    this.setState({deleteModal: true, selectedDeleteSlot: id})
  }

  deleteSlot() {
    var id = this.state.selectedDeleteSlot
    deleteSlotAPI(id).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({deleteModal: false, loading: false, specialText: "", showSuccess: true, successMessage: DashboardTranslations.interview_times_delete_slot_success_message})
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  backArrowOnClick() {
    this.props.history.goBack();
  }

  closeConfirmModal() {
    this.setState({deleteModal: false, selectedDeleteSlot: '', specialText: ""})
  }

  sortByDate( a, b) {
    var key1 = a.ivDate;
    var key2 = b.ivDate;

    if (key1 < key2) {
        return -1;
    } else if (key1 == key2) {
        return 0;
    } else {
        return 1;
    }
  }

  specialSortIVLocationAsc(a, b) {
    return this.totalCompare(a, b) || a.ivLocation.localeCompare(b.ivLocation);
  };

  totalCompare(a, b) {
    if (a.ivDate === b.ivDate) {
      return 0;
    }
    return b.ivDate - a.ivDate;
  }

  specialSortWorkLocationAsc(a, b) {
    return this.totalCompare(a, b) || a.workLocation.localeCompare(b.workLocation);
  };

  specialSortInterviewerAsc(a, b) {
    return this.totalCompare(a, b) || a.interviewer.localeCompare(b.interviewer);
  };

  sortData() {
    var { interviews, originalData, sortBy } = this.state
    var newData = originalData
    if (sortBy === "DateASC") {
      newData = originalData.sort(this.sortByDate.bind(this))
    }
    else if (sortBy === "DateDesc") {
      newData = originalData.sort(this.sortByDate.bind(this)).reverse()
    }
    else if (sortBy === "ivLocation") {
      newData = originalData.sort(this.specialSortIVLocationAsc.bind(this))
    }
    else if (sortBy === "workLocation") {
      newData = originalData.sort(this.specialSortWorkLocationAsc.bind(this))
    }
    else {
      newData = originalData.sort(this.specialSortInterviewerAsc.bind(this))
    }
    this.setState({interviews: newData, loading: false});
  }

  handleChange(event) {
    var name = event.target.name
    var value = event.target.value
    this.setState({loading: true, [name]: value}, () => this.sortData())
  }

  render() {
    var { showSuccess, successMessage, loading, interviews, specialText, sortBy } = this.state

    var sortByList = [
      {value: "DateASC", label: DashboardTranslations.interview_times_date_old_date_to_new_date},
      {value: "DateDesc", label: DashboardTranslations.interview_times_date_new_date_to_old_date},
      {value: "ivLocation", label: DashboardTranslations.interview_times_interview_location_ascending},
      {value: "workLocation", label: DashboardTranslations.interview_times_work_location_ascending},
      {value: "interviewer", label: DashboardTranslations.interview_times_interviewer_ascending}
    ]

    return (
      <div className="interview-times-container">
        <InnerHeader
          primary={true}
          history={this.props.history}
          headerText={DashboardTranslations.interview_times_header}
          backArrowOnClick={this.backArrowOnClick.bind(this)}
        />
        <ScaleLoader sizeUnit={"px"} height={50}
          width={4} color={'#007AFF'} loading={loading}
        />
        {!loading && <div className="inner-container">
          <div className="sort-select">
            <SelectDropdown
              error={false}
              noNullOption={true}
              label={DashboardTranslations.interview_times_sort_by_label}
              name="sortBy"
              value={sortBy}
              options={sortByList}
              handleChange={this.handleChange.bind(this)}
            />
          </div>
          <div className="calendar-container">
            {interviews.length === 0 && <div className="no-data-text">{DashboardTranslations.add_interview_times_no_slots_added_yet}</div>}
            {interviews.length > 0 &&
              <div className="desktop-calendar">
                <Table data={interviews} width={1000} height={500}>
                  <Column key="ivDate" title={DashboardTranslations.interview_times_interview_date_column_header} dataKey="ivDate" width={180} />
                  <Column key="ivLocation" title={DashboardTranslations.interview_times_interview_location_column_header} dataKey="ivLocation" width={180} />
                  <Column key="workLocation" title={DashboardTranslations.interview_times_work_location_column_header} dataKey="workLocation" width={180} />
                  <Column key="interviewer" title={DashboardTranslations.interview_times_interviewer_column_header} dataKey="interviewer" width={180} />
                  <Column key="status" title={DashboardTranslations.interview_times_status_column_header} dataKey="status" width={100} />
                  <Column key="applicant" title={DashboardTranslations.interview_times_applicant_details_column_header} dataKey="applicant" width={180} />
                </Table>
              </div>
            }
            {interviews.length > 0 &&
              <div className="mobile-calendar">
                <Table data={interviews} width={350} height={500}>
                  <Column key="ivDate" title={DashboardTranslations.interview_times_interview_date_column_header} dataKey="ivDateMobile" width={70} />
                  <Column key="ivLocation" title={DashboardTranslations.interview_times_interview_location_column_header} dataKey="ivLocationMobile" width={110} />
                  <Column key="interviewer" title={DashboardTranslations.interview_times_interviewer_column_header} dataKey="interviewer" width={90} />
                  <Column key="status" title={DashboardTranslations.interview_times_status_column_header} dataKey="statusMobile" width={80} />
                </Table>
              </div>
            }
          </div>
        </div>}
        <ConfirmModal confirmAction={this.deleteSlot.bind(this)}
          confirmationText={DashboardTranslations.interview_times_delete_slot_modal_description}
          handleClose={this.closeConfirmModal.bind(this)}
          specialText={specialText}
          headerText={DashboardTranslations.interview_times_delete_slot_modal_header}
          showConfirmationModal={this.state.deleteModal}/>
        <SuccessModal successMessage={successMessage} handleClose={this.handleClose.bind(this)} showSuccess={showSuccess}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token
    }
};

InterviewTimes = connect(mapStateToProps)(InterviewTimes);


export default InterviewTimes;
