import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerHeader from '../components/InnerHeader.js'
import ScaleLoader from 'react-spinners/ScaleLoader';
import DashboardTranslations from '../../DashboardTranslations.js';

import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import 'react-overlay-loader/styles.css';
import './styles/confirmPurchase.scss';
import {API_ROOT} from "../../constants";

const useStyles = theme => ({
  root: {
    height: 200,
    width: 200
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

class ConfirmPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      agreeTnC: false,
      showAcceptTnCAlert: false
    }
  }

  backArrowOnClick() {
    let path = '/dashboard/billing/select-payment-method';
    let state = {
      subscription_status: this.props.location.state.subscription_status,
      is_cc_added_in_gmo: this.props.location.state.is_cc_added_in_gmo,
      verification_status: this.props.location.state.verification_status,
      paidBy: this.props.location.state.paidBy,
      currSubscriptionData: this.props.location.state.currSubscriptionData,
      plan: this.props.location.state.plan,
      backToSelectPreviousPaymentMethod: false
    };
    if (this.props.location.state.backToSelectPreviousPaymentMethod) {
      path = '/dashboard/billing/select-previous-payment-method'
      state = {
        subscription_status: this.props.location.state.subscription_status,
        is_cc_added_in_gmo: this.props.location.state.is_cc_added_in_gmo,
        verification_status: this.props.location.state.verification_status,
        paidBy: this.props.location.state.paidBy,
        currSubscriptionData: this.props.location.state.currSubscriptionData,
        plan: this.props.location.state.plan,
        backToSelectPreviousPaymentMethod: this.props.location.state.backToSelectPreviousPaymentMethod
      }
    }

    this.props.history.push({
      pathname: path,
      state: state
    });
  }

  redirectToGMO(gmoUrl) {
    window.location.href = gmoUrl;
  }

  onConfirmClick() {
    if (!this.state.agreeTnC) {
      this.setState({showAcceptTnCAlert: true})
      return
    }

    // const subscription_status = this.props.location.state.subscription_status;
    const is_cc_added_in_gmo = this.props.location.state.is_cc_added_in_gmo;
    const verification_status = this.props.location.state.verification_status;
    const plan = this.props.location.state.plan;
    const paymentMethod = this.props.location.state.paymentMethod;

    if (paymentMethod === 'CC') {
      if (is_cc_added_in_gmo) {
        this.setState({loading: true})
        let component = this;
        fetch(API_ROOT + `api/v4/employer/plans/${plan.id}/purchase/?payment_mode=CC`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.props.token}`
          }
        })
            .then(response => {
              console.log('response.status: ', response.status);
              if (!response.ok) {
                component.props.history.push({
                  pathname: '/dashboard/billing/transaction-success',
                  state: {
                    messageToDisplay: '次のプランを購入済みです。'
                  }
                });
                throw new Error("HTTP status " + response.status);
              }

              return response.json();
            })
            .then(data => {
              component.props.history.push({
                pathname: '/dashboard/billing/transaction-success',
                state: {
                  messageToDisplay: 'お手続き完了！'
                }
              });
            })
            .catch((error) => {
              console.error('Error:', error);
              component.setState({loading: false});
            });
      } else {
        this.setState({loading: true})
        let component = this;
        fetch(API_ROOT + `api/v4/employer/plans/${plan.id}/purchase/?payment_mode=CC`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.props.token}`
          },
        })
            .then(response => {
              console.log('response.status: ', response.status);
              if (!response.ok) {
                component.props.history.push({
                  pathname: '/dashboard/billing/transaction-success',
                  state: {
                    messageToDisplay: '次のプランを購入済みです。'
                  }
                });

                throw new Error("HTTP status " + response.status);
              }
              return response.json();
            })
            .then(data => {
          if (data) {
            // if (data.hasOwnProperty('is_processed')) {
            fetch(API_ROOT + 'api/v3/employer/crud_cc/', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${component.props.token}`
              }
            })
                .then(response => response.json())
                .then(data => {
                  if (data.hasOwnProperty('gmo_url')) {
                    component.redirectToGMO(data.gmo_url);
                  }
                })
                .catch((error) => {
                  console.error('Error:', error);
                  component.setState({loading: false});
                });
            // }
          }
          else {
            component.setState({loading: false});
          }
        })
            .catch((error) => {
              console.error('Error:', error);
              component.setState({loading: false});
            });
      }

    } else {
      const component = this;
      switch (verification_status) {
        case 'NA':
          this.setState({loading: true})
          fetch(API_ROOT + `api/v4/employer/plans/${plan.id}/purchase/?payment_mode=INV`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${this.props.token}`
            },
          })
              .then(response => {
                console.log('response.status: ', response.status);
                if (!response.ok) {
                  component.props.history.push({
                    pathname: '/dashboard/billing/transaction-success',
                    state: {
                      messageToDisplay: '次のプランを購入済みです。'
                    }
                  });
                  throw new Error("HTTP status " + response.status);
                }
                return response.json();
              })
              .then(data => {
                fetch(API_ROOT + 'api/v3/employer/request_pay_by_invoice/', {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${component.props.token}`
                  }
                })
                    .then(response => {
                      console.log('response.status: ', response.status);
                      if (!response.ok) {
                        component.props.history.push({
                          pathname: '/dashboard/billing/transaction-success',
                          state: {
                            messageToDisplay: 'You are already an invoice member!'
                          }
                        });
                        throw new Error("HTTP status " + response.status);
                      }
                      return {foo: 'bar'};
                    })
                    .then(data => {
                      component.props.history.push({
                        pathname: '/dashboard/billing/transaction-success',
                        state: {
                          messageToDisplay: '請求書払いを受け付けました!',
                          isInvVerificationPending: true
                        }
                      });
                    })
                    .catch((error) => {
                      console.error('Error:', error);
                      component.setState({loading: false});
                    });
              })
              .catch((error) => {
                console.error('Error:', error);
                component.setState({loading: false});
              });
          break;
        case 'VERIFIED':
          this.setState({loading: true})
          fetch(API_ROOT + `api/v4/employer/plans/${plan.id}/purchase/?payment_mode=INV`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${this.props.token}`
            },
          })
              .then(response => {
                console.log('response.status: ', response.status);
                if (!response.ok) {
                  component.props.history.push({
                    pathname: '/dashboard/billing/transaction-success',
                    state: {
                      messageToDisplay: '次のプランを購入済みです。'
                    }
                  });
                  throw new Error("HTTP status " + response.status);
                }
                return response.json();
              })
              .then(data => {
                component.props.history.push({
                  pathname: '/dashboard/billing/transaction-success',
                  state: {
                    messageToDisplay: 'お手続き完了！'
                  }
                });
              })
              .catch((error) => {
                console.error('Error:', error);
                component.setState({loading: false});
              });
          break;

        case 'REJECTED':
          component.props.history.push({
            pathname: '/dashboard/billing/transaction-success',
            state: {
              messageToDisplay: '請求書払いのご利用ができません。',
              isInvRejected: true
            }
          });
          break;
        default:
          this.setState({loading: true})
          fetch(API_ROOT + `api/v4/employer/plans/${plan.id}/purchase/?payment_mode=INV`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${this.props.token}`
            },
          })
              .then(response => {
                console.log('response.status: ', response.status);
                if (!response.ok) {
                  component.props.history.push({
                    pathname: '/dashboard/billing/transaction-success',
                    state: {
                      messageToDisplay: '次のプランを購入済みです。'
                    }
                  });
                  throw new Error("HTTP status " + response.status);
                }
                return response.json();
              })
              .then(data => {
                component.props.history.push({
                  pathname: '/dashboard/billing/transaction-success',
                  state: {
                    messageToDisplay: '請求書払いを受け付けました!',
                    isInvVerificationPending: true
                  }
                });
              })
              .catch((error) => {
                console.error('Error:', error);
                component.setState({loading: false});
              });
          break;
      }
    }
  }

  handleChangeAgreeTnC() {
    this.setState({agreeTnC: !this.state.agreeTnC})
  }

  render() {
    const {loading, showAcceptTnCAlert} = this.state;

    return (
        <div className="confirm-purchase-page-container">
          <InnerHeader
              primary={false}
              history={this.props.history}
              headerText={DashboardTranslations.confirm_purchase_page_header_text}
              backArrowOnClick={this.backArrowOnClick.bind(this)}
          />

          <ScaleLoader sizeUnit={"px"} height={50} width={4} color={'#007AFF'} loading={loading}/>

          <div className="billing-page-inner" style={{margin: "20px auto"}}>

          {!loading && this.props.location.state.paidBy === null &&
          <>
            <br/>
            <div className="single-box">
              <div className="single-box-header">
                料金プラン
              </div>
              <div className="details-container">
                {this.props.location.state.plan.name_jp}
                <div className="description">
                  料金: {this.props.location.state.plan.price.toLocaleString() + ' 円'}<br/>
                  掲載期間: {this.props.location.state.plan.max_days / 7}週間
                </div>
              </div>
            </div>
            <div className="single-box">
              <div className="single-box-header">
                お支払方法
              </div>
              <div className="details-container">
                {(this.props.location.state.paymentMethod === 'CC') ? 'クレジットカード' : '請求書払い'}
              </div>
            </div>
          </>
          }

          {!loading && this.props.location.state.paidBy !== null &&
          <>
            <br />
            <div className="single-box">
              <div className="single-box-header">
                現在の料金プラン
              </div>
              <div className="details-container">
                {this.props.location.state.currSubscriptionData.plan.name_jp}
                <div className="description">
                  料金: {this.props.location.state.currSubscriptionData.plan.price.toLocaleString() + ' 円'} <br/>
                  掲載期間: {this.props.location.state.currSubscriptionData.plan.max_days / 7}週間 <br/>
                  お支払い方法: {(this.props.location.state.paidBy === 'CC') ? 'クレジットカード払い' : '請求書払い'}
                </div>
              </div>
            </div>
            <div className="single-box">
              <div className="single-box-header">
                次の料金プラン
              </div>
              <div className="details-container">
                {this.props.location.state.plan.name_jp}
                <div className="description">
                  料金: {this.props.location.state.plan.price.toLocaleString() + ' 円'} <br/>
                  掲載期間: {this.props.location.state.plan.max_days / 7}週間 <br/>
                  お支払い方法: {(this.props.location.state.paymentMethod === 'CC') ? 'クレジットカード払い' : '請求書払い'}
                </div>
              </div>
            </div>
          </>
          }
          <br/>
            {!loading && <>
              <FormControlLabel
                  control={
                    <Checkbox
                        checked={this.state.agreeTnC}
                        onChange={this.handleChangeAgreeTnC.bind(this)}
                        name="checkedB"
                        color="primary"
                    />
                  }
                  label={<a href="https://www.workjapan.jp/terms/" target="_blank">利用規約に同意する</a>}
              />
              {showAcceptTnCAlert && <div style={{color: "#FD595C"}}>利用規約に同意してください。</div>}
              <div className="transactions-button-container">
                <div className="section-link"  onClick={() => this.onConfirmClick()}>
                  この内容で購入手続きを完了する
                </div>
              </div>
            </>}
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    language: state.language,
    token: state.user.token
  }
};

ConfirmPurchase = connect(mapStateToProps)(ConfirmPurchase);
export default withStyles(useStyles)(ConfirmPurchase)
