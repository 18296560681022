import React, { Component } from 'react';
import ImageSlide from './ImageSlide'
import Arrow from './Arrow'
import closeIcon from '../../images/carousel-close.png'
import './styles/carousel.scss'

class Carousel extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      currentImageIndex: 0
    };
    this.nextSlide = this.nextSlide.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
  }

  previousSlide () {
    const lastIndex = this.props.imgUrls.length - 1;
    const { currentImageIndex } = this.state;
    const shouldResetIndex = currentImageIndex === 0;
    const index =  shouldResetIndex ? lastIndex : currentImageIndex - 1;

    this.setState({
      currentImageIndex: index
    });
  }

  nextSlide () {
    const lastIndex = this.props.imgUrls.length - 1;
    const { currentImageIndex } = this.state;
    const shouldResetIndex = currentImageIndex === lastIndex;
    const index =  shouldResetIndex ? 0 : currentImageIndex + 1;

    this.setState({
      currentImageIndex: index
    });
  }

  deleteImage() {
    if (this.state.currentImageIndex === this.props.imgUrls.length-1) {
      this.previousSlide()
    }
    this.props.deleteOldImages(this.props.ids[this.state.currentImageIndex], this.state.currentImageIndex)
  }


  render () {
    return (
      <div className="carousel">
        <Arrow
          direction="left"
          clickFunction={ this.previousSlide }
          glyph="&#9664;" />

        <ImageSlide url={this.props.imgUrls[this.state.currentImageIndex]} deleteImage={this.deleteImage.bind(this)}/>
        <img height="15" width="15" onClick={() => this.deleteImage()} className="carousel-close-icon" src={closeIcon} alt="carousel-close-icon" />
        {this.props.noImagesAdded && this.state.currentImageIndex === 0 && <div style={{color: '#1A9EFF', fontSize: 14, marginTop: 5}}>この写真がメイン画像として求人記事に掲載されます</div>}
        <Arrow
          direction="right"
          clickFunction={ this.nextSlide }
          glyph="&#9654;" />
      </div>
    );
  }
}

export default Carousel
