import React, { Component } from 'react';
// import Select, {Option, OptGroup} from 'rc-select';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import '../styles/multiSelect.scss';
import HelpIcon from '../../images/help-icon.png'
import DashboardTranslations from '../../DashboardTranslations.js';

class SelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "valid",
      selectedOption: props.value ? props.value : {}
    }
  }

  componentDidMount() {
  }

  handleChange = (e) => {
    this.props.handleChange(e);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      var selectedOption = this.props.value
      this.setState({selectedOption});
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.value!==prevState.selectedOption){
      return {value: nextProps.value}
    }
    else return null;
  }


  render() {
    var {label, error, errorMessage, options, leaveErrorSpace, disabled,
        value, explanatoryText, tooltipText, showMandatory, showOptional } = this.props
    var inputContainerClass = "select-container"
    var singleSelectClassName = "single-select"
    var displayOptions = []
    displayOptions = options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox checked={value.indexOf(option.value) >= 0} />
          <span style={{paddingLeft: 10}}>{option.label}</span>
        </MenuItem>
      )
    })

    var mandatoryBox
    if (showMandatory) {
      mandatoryBox =
        <span className="mandatory-container">
          {DashboardTranslations.field_mandatory_text}
        </span>
    }
    var optionalBox
    if (showOptional) {
      optionalBox =
        <span className="optional-container">
          {DashboardTranslations.field_optional_text}
        </span>
    }
    var errorMessageClass = "error-message-container"
    if (leaveErrorSpace) {
      errorMessageClass = "error-message-container error-message-leave-space"
    }

    var errorText = ""
    if (error) {
      errorText = errorMessage
    }

    return (
      <div className={inputContainerClass}>
        <div className="label-container">{label} {mandatoryBox} {optionalBox}
          {tooltipText &&
            <Tooltip title={tooltipText} placement="top">
              <img alt="tooltip" className="tooltip-text" src={HelpIcon} />
            </Tooltip>
          }
        </div>
        {<div className={errorMessageClass}>{errorText}</div>}
        {!disabled && <div className="drop-list">
          <Select
            multiple
            className={error ? "error "+singleSelectClassName : singleSelectClassName}
            value={value}
            onChange={(e) => this.handleChange(e)}
            displayEmpty
            disabled={disabled}
            name={this.props.name}
            renderValue={selected => <em>選択</em>}
          >
            {displayOptions}
          </Select>
        </div>}
        {explanatoryText && <div className="explanatory-text">{explanatoryText}</div>}
      </div>
    );
  }
}

export default SelectDropdown;
