const tooltipMessages = {
    'jobs_page_new_slot_request_by_employer_tooltip_text': {
        'en': '面接時間の48時間前まで変更依頼ができます。応募者が新しい面接枠を選択すると変更が完了します。変更依頼は応募者の離脱に繋がる可能性が高いのでご注意ください。',
        'jp': '面接時間の48時間前まで変更依頼ができます。応募者が新しい面接枠を選択すると変更が完了します。変更依頼は応募者の離脱に繋がる可能性が高いのでご注意ください。'
    },
    'jobs_page_cancelled_by_employer_tooltip_text': {
        'en': '面接日の2日前までキャンセルができます。',
        'jp': '面接時間の48時間前までキャンセルができます。'
    },
    'add_job_sales_merit_tooltip_text': {
        'en': 'Sales Merit',
        'jp': 'できるだけ多くの項目を選択して、応募を集めましょう！'
    },
    'add_location_create_new_location_tooltip_text': {
        'en': 'Create Location',
        'jp': '面接場所を新規登録する場合は「ブランド名」より入力開始してください。'
    },
    'add_location_brand_name_jp_tootlip_text': {
        'en': 'Brand Name',
        'jp': '求人記事に表示されます。人材会社様などクラインアント名を記載できない場合は、貴社名をご登録ください。'
    },
    'add_location_brand_name_en_tooltip_text': {
        'en': 'Brand Name EN',
        'jp': '求人記事に表示されます。英語で記載をお願いします。'
    },
    'add_location_branch_location_name_tooltip_text': {
        'en': 'Location Name',
        'jp': '求人記事には表示されません。多店舗展開されている企業様の記事管理の便宜上、設定しております。「店舗名・勤務地名」がない企業様も、最寄駅名や、本社・本店など、何らかの値を入力してください。'
    },
    'add_location_building_name_en_tooltip_text': {
        'en': 'Building Name En',
        'jp': '応募者のために英語で記載してください。'
    },
    'add_location_is_FUEI__tooltip_text': {
        'en': 'IS FUEI',
        'jp': '風営法対象業種では就労できない在留資格があるため、該当する場合は選択をお願いします。'
    },
    'add_job_in_charge_tooltip_text': {
        'en': 'Interview In Charge',
        'jp': '登録した面接者に、面接に関連する通知メールが届きます。'
    },
    'add_job_salary_min_tooltip_text': {
        'en': 'Salary Minimum',
        'jp': '地域別の最低賃金以上の金額をご入力ください。'
    },
    'add_job_sales_merit_copy_merit_tooltip_text': {
        'en': 'Copy Merit',
        'jp': '登録済みの求人記事で選択したセールスポイントを利用できます。'
    },
    'user_settings_confirm_password_tooltip_text': {
        'en': 'Confirm Password',
        'jp': '※数字の他、大文字と小文字を最低一つずつ含む８文字以上'
    },
    'add_new_slot_tooltip_text': {
        'en': '応募者が別日程をリクエストしています。新規面接枠を登録してください。',
        'jp': '応募者が別の日程をリクエストしています。新規面接枠を登録してください。'
    },
    'user_settings_new_password_tooltip_text': {
        'en': 'New password',
        'jp': '※数字の他、大文字と小文字を最低一つずつ含む８文字以上'
    }
}

export function getTooltipMessage(key, replacements) {
    if (replacements) {
        return tooltipMessages[key].jp.replace(/{{\s\w+\s}}/g, function(all) {
            return cleanReplacements(replacements)[all] || all;
        })
    } else {
        return tooltipMessages[key].jp;
    }
}

function cleanReplacements(replacements) {
    for (const oldProp in replacements) {
        if (replacements.hasOwnProperty(oldProp)) {
            Object.defineProperty(replacements, `{{ ${oldProp} }}`, {value: replacements[oldProp]});
            delete replacements[oldProp];
        }
    }
    return replacements;
}
