import React, { Component } from 'react';
import InputBox from '../../components/InputBox.js'
import { connect } from 'react-redux';
import DashboardTranslations from '../../../DashboardTranslations.js';

import '../styles/companyDetailsPart3.scss';
import { nameRegex, katakanaRegex } from '../../../regex';

class CompanyDetailsPart3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CEOFirstName: props.CEOFirstName ? props.CEOFirstName : "",
      CEOLastName: props.CEOLastName ? props.CEOLastName : "",
      CEOFirstNameKT: props.CEOFirstNameKT ? props.CEOFirstNameKT : "",
      CEOLastNameKT: props.CEOLastNameKT ? props.CEOLastNameKT : "",
    }
  }

  componentDidMount() {

  }

  handleChange = event => {
    var name = event.target.name
    var value = event.target.value
    this.setState({ [event.target.name]: value }, () => this.props.updateValues(name, value));
  }

  render() {
    var { CEOLastName, CEOFirstName, CEOLastNameKT, CEOFirstNameKT
    } = this.state

    var { submitted } = this.props

    return (
      <div className="company-details-part3-container">
        <div className="company-details-part3-form">
          <InputBox
            value={CEOLastName}
            errorMessage={DashboardTranslations.register_page_ceo_last_name_error_message}
            error={submitted === true && !nameRegex.test(CEOLastName)}
            label="代表者様の姓（漢字）"
            name="CEOLastName"
            className="single-field"
            handleChange={this.handleChange.bind(this)}
          />
          <InputBox
            value={CEOFirstName}
            errorMessage={DashboardTranslations.register_page_ceo_first_name_error_message}
            error={submitted === true && !nameRegex.test(CEOFirstName)}
            label="代表者様の名 （漢字）"
            name="CEOFirstName"
            className="single-field"
            handleChange={this.handleChange.bind(this)}
          />
          <InputBox
            value={CEOLastNameKT}
            errorMessage={DashboardTranslations.register_page_ceo_last_name_kt_error_message}
            error={submitted === true && !katakanaRegex.test(CEOLastNameKT)}
            label="代表者様の姓 （カタカナ）"
            name="CEOLastNameKT"
            className="single-field"
            handleChange={this.handleChange.bind(this)}
          />
          <InputBox
            value={CEOFirstNameKT}
            errorMessage={DashboardTranslations.register_page_ceo_first_name_kt_error_message}
            error={submitted === true && !katakanaRegex.test(CEOFirstNameKT)}
            label="代表者様の名（カタカナ）"
            name="CEOFirstNameKT"
            className="single-field"
            handleChange={this.handleChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.user.isLoggedIn,
    }
};

CompanyDetailsPart3 = connect(mapStateToProps)(CompanyDetailsPart3);

export default CompanyDetailsPart3;
