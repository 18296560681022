import React, { Component } from 'react';
import { connect } from 'react-redux';

import InfoImage1 from '../../images/info-modal-image-1.png'
import './infoModalBeforeFirstJobCreation.scss';


class InfoModalBeforeFirstJobCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {

    return (
      <div className="info-modal-before-first-job-creation">
        <div className="modal-outer-container">
          <div className="modal-inner-container">
            <div className="header">
              記事の作成
            </div>

            <div className="sub-header">
              記事は、「勤務地・面接場所・募集内容」の<br/>
              3ページ構成となっています。

              <br />
              <span style={{color: "red"}}>
                募集条件や掲載写真（5枚まで掲載できます）など、仕事に関する<br />
                情報をあらかじめご用意いただくとスムースに作成できます。<br />
                作成途中で保存できる記事は1記事までです。
              </span><br/>
              <br /><br />
            </div>

            <img className="info-image-1" src={InfoImage1} />

            <div className="sub-header">
              外国人に好まれるセールスポイントを選択すると、<br/>
              応募数が増えまます。<br/><br/>

              1つの記事の面接担当者になれるのは1人です、記事作成者と別の人が面接を受け持つ場合は、あらかじめその人を、ユーザー招待しておきましょう。<br/><br/>

              面接をオンラインで行う場合は<br/>
              <span style={{color: "red"}}>
                「オンライン面接」
              </span>
              を選択してください。<br/><br/>

              <span style={{color: "red"}}>
                ※SkypeやZoomなど「オンライン面接」の詳細を<br/>
                応募者に通知する機能もあります。
              </span>
              <br/>
            </div>

            <button style={{backgroundColor: "#FFFFFF",margin: "10px auto", fontSize: 17, borderRadius: "12px", width: "300px", border: "1px solid #007Aff", color: "#007AFF"}} onClick={() => this.props.closeInfoModalBeforeFirstJobCreation()}>
              閉じる
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.user.isLoggedIn,
    }
};

InfoModalBeforeFirstJobCreation = connect(mapStateToProps)(InfoModalBeforeFirstJobCreation);

export default InfoModalBeforeFirstJobCreation;
