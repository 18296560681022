import React, {Component} from 'react';
import SelectDropdown from '../../components/SelectDropdown.js';
import InputBox from '../../components/InputBox.js';
import {connect} from 'react-redux';
import {getCitiesDetails, getPostalCode, getWardDetails} from '../../helperFunctions/dataServices.js';
import DashboardTranslations from '../../../DashboardTranslations.js';
import '../styles/companyDetailsPart2.scss';
import {getErrorMessage} from "../../../messages/errors";


class CompanyDetailsPart2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatchAgency: false,
      postalCode: props.postalCode ? props.postalCode: "",
      prefecture: props.prefecture ? props.prefecture: "",
      city1: props.city1 ? props.city1: "",
      city2: props.city2 ? props.city2: "",
      lotNo: props.lotNo ? props.lotNo: "",
      buildingNameJp: props.buildingNameJp ? props.buildingNameJp: "",
      showIndustryOther: props.industryOtherValue ? true : false,
      industryType: props.industryType ? parseInt(props.industryType): "",
      industryOtherValue: props.industryOtherValue ? props.industryOtherValue : "",
      options: props.options,
      postalCodeId: props.postalCodeId ? props.postalCodeId : "",
      postalCodeOptions: [],
      postalCodeSubmitted: false,
      submitted: false
    }
  }

  componentDidMount() {
    if (this.state.postalCodeId) {
      this.getPostalCodeAdress( this.state.postalCode, false);
    }
  }

  multipleUpdate() {
    this.props.updateValues("prefecture", this.state.prefecture)
    this.props.updateValues("city1", this.state.city1)
    this.props.updateValues("city2", this.state.city2)
  }

  getPostalCodeAdress(value, refill) {
    this.setState({loading: true})
    var intValue = parseInt(value, 10)
    getPostalCode(intValue).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        var prefecture = ''
        var city1 = ''
        var city2 = ''
        var postalCodeId = ''
        if (response.data) {
          prefecture = response.data.prefectures[0].id
          city1 = response.data.cities[0].id
          city2 = response.data.wards[0].id
          postalCodeId = response.data.postcode_id
        }
        this.setState({ postalCodeOptions: response.data, postalCodeId: postalCodeId}, () => this.props.updateValues("postalCodeId", postalCodeId))
        if (refill) {
          this.setState({ prefecture: prefecture, city1: city1, city2: city2,}, () => this.multipleUpdate())
        }
      }
      else {
        this.setState({loading: false, showError: false, postalCodeSubmitted: true, postalCode: '', error: getErrorMessage('from_api_response', {'apiErrorMessage': response.data.error})})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  getWards(value, refill) {
    this.setState({loading: true})
    var intValue = parseInt(value, 10)
    getWardDetails(intValue).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        var postalCodeOptions = this.state.postalCodeOptions
        postalCodeOptions.wards = response.data
        this.setState({postalCodeOptions: postalCodeOptions})
        if (refill) {
          this.setState({ city2: ''}, () => this.props.updateValues("city2", ""))
        }
      }
      else {
        this.setState({loading: false})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  getCities(value, refill) {
    this.setState({loading: true})
    var intValue = parseInt(value, 10)
    getCitiesDetails(intValue).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        var postalCodeOptions = this.state.postalCodeOptions
        postalCodeOptions.cities = response.data
        this.setState({postalCodeOptions: postalCodeOptions})
        if (refill) {
          this.setState({city1: '', city2: ''}, () => this.multipleUpdate())
        }
      }
      else {
        this.setState({loading: false})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  handleChange = event => {
    var value = event.target.value
    var name = event.target.name
    if (name === "postalCode" || name === "prefecture") {
      if (name === "postalCode") {
        if (value.length === 7) {
          this.getPostalCodeAdress(value, true)
        }
        this.setState({ [event.target.name]: event.target.value }, () => this.props.updateValues(name, value));
      }
      else {
        if (value !== "") {
          this.setState({[event.target.name]: value}, () => this.props.updateValues(name, value))
          this.getCities(value, true)
        }
        else {
          this.setState({prefecture: "", city1: "", city2: ""})
        }
      }
      this.setState({[event.target.name]: value}, () => this.props.updateValues(name, value))
    }
    else if (event.target.name === "industryType") {
      this.state.options.industries.forEach((option, optionId) => {
        if (option.name === "その他" && parseInt(event.target.value) === parseInt(option.value)) {
          this.setState({showIndustryOther: true}, () => this.props.updateValues("showIndustryOther", true))
        }
        else {
          this.setState({showIndustryOther: false}, () => this.props.updateValues("showIndustryOther", false))
        }
      })
      this.setState({industryType: event.target.value}, () => this.props.updateValues(name, value))
    }
    else {
      var value = event.target.value
      this.setState({[event.target.name]: value}, () => this.props.updateValues(name, value))
    }
  }

  handleDispatchAgencyRadio(value) {
    this.setState({dispatchAgency: value}, () => this.props.updateValues('dispatchAgency', value))
  }

  render() {
    var { dispatchAgency, postalCode, city1, city2, prefecture, lotNo, options, postalCodeOptions,
      postalCodeSubmitted, buildingNameJp, industryType, industryOtherValue, showIndustryOther
    } = this.state

    var { submitted } = this.props

    var lotNoPattern = new RegExp(/^\d+(-\d+){0,3}$/)

    var industryOptions = []
    var prefectureOptions = []
    var city1Options = []
    var city2Options = []
    // var foundThroughOptions = []
    if (options.industries) {
      industryOptions = options.industries.map((option, optionId) => {
        return {value: option.value, label: option.name};
      })
      // foundThroughOptions = options.found_through_options.map((option, optionId) => {
      //   var foundThrough = {value: option.id, label: option.name}
      //   return foundThrough;
      // })
    }
    if (postalCodeOptions.prefectures && postalCodeOptions.prefectures.length > 0) {
      prefectureOptions = options.prefectures.map((option, id) => {
        return {value: option.value, label: option.name}
      })
      city1Options = postalCodeOptions.cities.map((option, id) => {
        return {value: option.id, label: option.name}
      })
      city2Options = postalCodeOptions.wards.map((option, id) => {
        return {value: option.id, label: option.name}
      })
    }

    return (
      <div className="company-details-part2-container">
        <div className="company-details-part2-form">
          <div className="is-dispatch-agency-container">
            <div className="dispatch-agency-text">
              貴社は人材会社ですか？
            </div>
            <div className="dispatch-agency-values">
              <div className="dispatch-agency-single">
                <input
                    type="radio"
                    name="dispatchAgency"
                    checked={!!dispatchAgency}
                    id="dispatchAgency"
                    value={dispatchAgency}
                    style={{marginRight: 10}}
                    onChange={() => this.handleDispatchAgencyRadio(true)}
                />
                <div className="text">
                  はい、人材会社です。
                </div>
              </div>
              <div className="dispatch-agency-single">
                <input
                    type="radio"
                    name="dispatchAgency"
                    checked={!dispatchAgency}
                    id="dispatchAgency"
                    value={dispatchAgency}
                    style={{marginRight: 10}}
                    onChange={() => this.handleDispatchAgencyRadio(false)}
                />
                <div className="text">
                  いいえ、人材会社ではないです。
                </div>
              </div>
            </div>
            {!dispatchAgency && <div className="single-fields-container">
              <SelectDropdown
                errorMessage={DashboardTranslations.register_page_industry_type_error_message}
                error={submitted === true && industryType === ""}
                label={DashboardTranslations.register_page_industry_type_label}
                name="industryType"
                value={industryType}
                options={industryOptions}
                handleChange={this.handleChange.bind(this)}
              />
            </div>}

            {!dispatchAgency && showIndustryOther && <div className="single-fields-container">
              <InputBox
                value={industryOtherValue}
                errorMessage={DashboardTranslations.register_page_industry_other_error_message}
                error={submitted === true && (industryOtherValue.length === 0 || industryOtherValue.length > 30) ? true : false}
                label={DashboardTranslations.register_page_industry_other_label}
                name="industryOtherValue"
                handleChange={this.handleChange.bind(this)}
                className="single-field"
              />
            </div>}
          </div>
          <div className="street-address-container">
            <div className="street-address-header">
              住所
            </div>

            <div className="street-address-values">
              <InputBox
                value={postalCode}
                errorMessage={DashboardTranslations.register_page_postcode_error_message}
                error={(postalCodeSubmitted === true || submitted === true) && (postalCode.length === 0 || postalCode.length > 7) ? true : false}
                label={DashboardTranslations.register_page_postcode_label}
                name="postalCode"
                inputType="number"
                handleChange={this.handleChange.bind(this)}
              />
              <SelectDropdown
                errorMessage={DashboardTranslations.register_page_prefecture_error_message}
                error={false}
                label={DashboardTranslations.register_page_prefecture_label}
                name="prefecture"
                value={prefecture}
                disabled={true}
                options={prefectureOptions}
                handleChange={this.handleChange.bind(this)}
              />
              <SelectDropdown
                errorMessage={DashboardTranslations.register_page_city1_error_message}
                error={false}
                label={DashboardTranslations.register_page_city1_label}
                name="city1"
                options={city1Options}
                disabled={true}
                value={city1}
                handleChange={this.handleChange.bind(this)}
              />
              <SelectDropdown
                errorMessage={DashboardTranslations.register_page_city2_error_message}
                error={submitted === true && city2 === ""}
                label={DashboardTranslations.register_page_city2_label}
                name="city2"
                value={city2}
                options={city2Options}
                handleChange={this.handleChange.bind(this)}
              />
              <InputBox
                value={lotNo}
                errorMessage={DashboardTranslations.register_page_lot_no_error_message}
                error={submitted === true && !lotNoPattern.test(lotNo) ? true : false}
                label={DashboardTranslations.register_page_lot_no_label}
                name="lotNo"
                handleChange={this.handleChange.bind(this)}
                className="single-field"
              />
              <InputBox
                value={buildingNameJp}
                error={false}
                label={DashboardTranslations.register_page_building_name_jp_label}
                name="buildingNameJp"
                handleChange={this.handleChange.bind(this)}
                className="single-field"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.user.isLoggedIn,
    }
};

CompanyDetailsPart2 = connect(mapStateToProps)(CompanyDetailsPart2);

export default CompanyDetailsPart2;
