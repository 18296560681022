import React from 'react';
import FontAwesomeIcon from 'react-fontawesome'
import './styles/customEvent.scss'

export default class CustomEvent extends React.Component {
    render() {
        return (
        <div className="custom-event-container" title={this.props.event.tooltipAccessor}>
          <div>
            {this.props.title}
          </div>
          {this.props.event.showDelete ?
            <div style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 'calc(100% - 28px)',
            }}>
              <FontAwesomeIcon
                style={{fontSize: 18, cursor: "pointer"}} onClick={() => this.props.event.handleDelete()}
                name="close" className="icon"/>
            </div>
            :
            <div className="booked" style={{color: this.props.event.bookingColor}} onClick={() => this.props.event.history.push("/dashboard/applicant-details/"+this.props.event.applicantId)}>
              予
            </div>
          }
        </div>
        );
    }
}
