import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import './styles/customToolbar.scss'
const reactStringReplace = require('react-string-replace');

export default class CustomToolbar extends Toolbar {

	componentDidMount() {
		const view = this.props.view;
	}

	render() {
		var label = reactStringReplace(this.props.label, /(January)/g, (match, i) => (
			<span key={0}>1月</span>
		));

		label = reactStringReplace(label, /(February)/g, (match, i) => (
		  <span key={1}>2月</span>
		));

		label = reactStringReplace(label, /(March)/g, (match, i) => (
		  <span key={2}>3月</span>
		));

		label = reactStringReplace(label, /(April)/g, (match, i) => (
		  <span key={3}>4月</span>
		));

		label = reactStringReplace(label, /(May)/g, (match, i) => (
		  <span key={4}>5月</span>
		));

		label = reactStringReplace(label, /(June)/g, (match, i) => (
		  <span key={5}>6月</span>
		));

		label = reactStringReplace(label, /(July)/g, (match, i) => (
		  <span key={6}>7月</span>
		));

		label = reactStringReplace(label, /(August)/g, (match, i) => (
		  <span key={7}>8月</span>
		));

		label = reactStringReplace(label, /(September)/g, (match, i) => (
		  <span key={8}>9月</span>
		));

		label = reactStringReplace(label, /(October)/g, (match, i) => (
		  <span key={9}>10月</span>
		));

		label = reactStringReplace(label, /(November)/g, (match, i) => (
		  <span key={10}>11月</span>
		));

		label = reactStringReplace(label, /(December)/g, (match, i) => (
		  <span key={11}>12月</span>
		));

		label = reactStringReplace(label, /( -)/g, (match, i) => (
		  <span key={12}>日 -</span>
		));

		label = reactStringReplace(label, /(月 )/g, (match, i) => (
		  <span >月</span>
		));




		label = reactStringReplace(label, /(01)/g, (match, i) => (
			<span key={3}>1</span>
		));

		label = reactStringReplace(label, /(02)/g, (match, i) => (
			<span key={4}>2</span>
		));

		label = reactStringReplace(label, /(03)/g, (match, i) => (
			<span key={5}>3</span>
		));

		label = reactStringReplace(label, /(04)/g, (match, i) => (
			<span key={6}>4</span>
		));

		label = reactStringReplace(label, /(05)/g, (match, i) => (
			<span key={7}>5</span>
		));

		label = reactStringReplace(label, /(06)/g, (match, i) => (
			<span key={8}>6</span>
		));

		label = reactStringReplace(label, /(07)/g, (match, i) => (
			<span key={9}>7</span>
		));

		label = reactStringReplace(label, /(08)/g, (match, i) => (
			<span key={10}>8</span>
		));

		label = reactStringReplace(label, /(09)/g, (match, i) => (
			<span key={11}>9</span>
		));



		return (
			<div>
				<div className="custom-toolbar-container">
          <div className="arrow-left" onClick={() => this.navigate('PREV')}></div>
          <div className="rbc-toolbar-label">{label}日</div>
          <div className="arrow-right" onClick={() => this.navigate('NEXT')}></div>
				</div>
			</div>
		);
	}
}
