import React, { Component } from 'react';
import { connect } from 'react-redux';

import InfoImage1 from '../../images/info-modal-image-1.png'
import InfoImage2 from '../../images/info-modal-image-2.png'
import InfoImage3 from '../../images/info-modal-image-3.png'
import './infoModalAfterRegistration.scss';


class InfoModalAfterRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {

    return (
      <div className="info-modal-after-registration-container">
        <div className="modal-outer-container">
          <div className="modal-inner-container">
            <div className="header">
              WORK JAPANの使い方
            </div>

            <div className="sub-header">
              ワークジャパンへようこそ！ <br />
              まずは最初の記事を作成して外国人のアルバイト採用を始めましょう！<br /><br />
              記事の掲載を始めるまで一切料金はかかりませんので <br />
              安心して多くの記事を作成してください。<br />
            </div>

            <img className="info-image-1" src={InfoImage1} />

            <div className="sub-header">
              ◆ 掲載までの流れ ◆  <br />
              記事を作成 → 面接できる時間の登録 → お支払い → 掲載開始 <br /><br />
              記事を書くのはウェブ上で簡単に完結！ <br />
              会社のイメージが伝わる写真を用意すると作成がスムースです<br />
            </div>

            <img className="info-image-1" src={InfoImage2} />

            <div className="sub-header">
              ワークジャパンでは候補者が応募をした瞬間に面接の予約が完了し<br/>
              ます。前もって面接に対応できる時間をなるべく多く登録して候補者<br/>
              の応募をお待ちください。<br/>
              （ご利用サービスの終了日から10日先まで登録しましょう！）<br/>
              <div style={{color: "red"}}>
                登録面接枠が少ないと応募数が伸びない場合ががあります。
              </div>
              <br/><br/><br/>
              ◆1記事とは？◆<br/>
              一つの記事に「募集職種＋雇用形態＋勤務地」<br/>
              が一つずつ掲載できます。<br/>
              ※同じ勤務地でも、「皿洗い＋ウエイター」など<br/>
              ２職種の募集する場合はそれぞれ別の記事を作成してください。<br/>
              （記事を複製する機能があるので手間はかかりません）<br/><br/>
            </div>

            <img className="info-image-1" src={InfoImage3} />

            <div className="sub-header">
              お支払いはクレジットカード払いか請求書払いを選べます。 <br /><br />

              請求書払いは審査に最大5日ほどかかりますので <br />
              すぐに掲載を開始できるクレジットカード払いをオススメします。 <br /><br />

              ◆ ご利用期間について ◆ <br />
              クレジットカード払いの場合はお支払日から28日間、<br />
              請求書払いの場合は審査が通過した日から28日間となります。 <br /><br />

              あらかじめ記事を作成しておいてからお支払いいただくと <br />
              28日間フルに無駄なくご利用いただけます。<br /><br />
            </div>

            <button style={{backgroundColor: "#FFFFFF",margin: "10px auto", fontSize: 17, borderRadius: "12px", width: "300px", border: "1px solid #007Aff", color: "#007AFF"}} onClick={() => this.props.closeInfoModalAfterRegistration()}>
              記事の作成を始める
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.user.isLoggedIn,
    }
};

InfoModalAfterRegistration = connect(mapStateToProps)(InfoModalAfterRegistration);

export default InfoModalAfterRegistration;
