const SalesMeritsIconList = {
  sales_no_experience: "drawIconSalesMeritNoExp",
  sales_no_cv: "drawIconSalesMeritNoResume",
  sales_promotion: "drawIconSalesMeritPromotion",
  sales_raise: "drawIconSalesMeritPayRaise",
  sales_no_nihongo: "drawIconSalesMeritNoNihongo",
  sales_foreigner: "drawIconSalesMeritForeignersWorking",
  sale_bicycle_parking: "drawIconSalesMeritBicycleParking",
  sales_car_parking: "drawIconSalesMeritCarParking",
  sales_near_by: "drawIconSalesMeritNearby",
  sales_transport: "drawIconSalesMeritPaidTransport",
  sales_few_hours: "drawIconSalesMeritShortTime",
  sales_less_over: "drawIconSalesMeritLessOvertime",
  sales_many_over: "drawIconSalesMeritMoreOvertime",
  sales_morning_shift: "drawIconSalesMeritMorningShift",
  sales_night_shift: "drawIconSalesMeritNightShift",
  "sales_2-3_days": "drawIconSalesMerit23Days",
  sales_wknd_shift: "drawIconSalesMeritWeekendShift",
  "sales_wknd_&_hol": "drawIconSalesMeritWeekendOff",
  sales_paid_daily: "drawIconSalesMeritDailyPay",
  sales_advance_salary: "drawIconSalesMeritAdvanceSalary",

  skype_interview: "drawIconSalesMeritSkype",
  male_preferred: "drawIconSalesMeritPreferMan",
  female_preferred: "drawIconSalesMeritPreferWoman",
  chance_to_get_hired_fulltime: "drawIconSalesMeritFullTimeChance",
  high_earning_potential: "drawIconSalesMeritEarnLot",
  student_visa_preferred: "drawIconSalesMeritStudentVisa",
  dormitory_provided: "drawIconSalesMeritDormitory",
  training_manual_for_foreigners: "drawIconSalesMeritManual",
  bus_service_from_nearby_station: "drawIconSalesMeritBus",
  short_term: "drawIconSalesMeritShortTerm",
  joining_bonus: "drawIconSalesMeritJoinBonus",
  bonus: "drawIconSalesMeritBonus",
  meals_provided: "drawIconSalesMeritMeal",
}

export default SalesMeritsIconList
