import { DISPLAY_LANGUAGE } from '../constants';


const language = (state = DISPLAY_LANGUAGE, action) => {
    switch (action.type) {
        case 'SET_LANGUAGE':
            return action.language;
        default:
            return state
    }
};

export default language;
