import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import SelectDropdown from '../components/SelectDropdown.js'
import InputBox from '../components/InputBox.js'
import DashboardTranslations from '../../DashboardTranslations.js';
import close from '../../images/svg/close.svg'
import '../styles/confirmCancelInterviewModal.scss'


class ConfirmCancelInterviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReason: "",
      otherValue: "",
      submitted: false,
      showOtherValue: false
    }
  }

  confirmAction() {
    this.setState({submitted: true})
    var { selectedReason, otherValue } = this.state
    var payload = {}
    if (selectedReason !== "") {
      this.props.employerCancelReasons.forEach((item) => {
        if (parseInt(item.id) === parseInt(selectedReason)) {
          if (item.name === "その他" && otherValue.length > 0) {
            payload.status = "CBE"
            payload.employer_cancel_reason = parseInt(selectedReason)
            payload.custom_employer_cancel_reason = otherValue
            this.props.confirmAction(payload)
          }
          else if(item.name !== "その他"){
            payload.status = "CBE"
            payload.employer_cancel_reason = parseInt(selectedReason)
            this.props.confirmAction(payload)
          }
        }
      })
    }
    // this.props.confirmAction()
  }

  handleChange(event) {
    if (event.target.name === "selectedReason") {
      this.props.employerCancelReasons.forEach((item) => {
        if (parseInt(item.id) === parseInt(event.target.value) && item.name === "その他") {
          this.setState({showOtherValue: true})
        }
        else {
          this.setState({showOtherValue: false})
        }
      })
    }
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    var { submitted, selectedReason, otherValue, showOtherValue } = this.state

    var options = [{value: "", label: "選択"}]
    if (this.props.employerCancelReasons) {
      options = this.props.employerCancelReasons.map((option, id) => {
        var single = {value: option.id, label: option.name}
        return single
      })
    }

    return (
        <div>
          <Modal
            open={this.props.showConfirmationModal}
            onClose={this.props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{}}
          >
            <div className="confirm-cancel-interview-modal">
              <div className="responsive-header" style={{backgroundColor: "#E3E3E3"}}>
                <div className="login-header-text">
                  選考不合格を通知する
                </div>
                <div className="burger" style={{border: "0.75px solid #0A57BF", borderRadius: "50%"}} 
                  onClick={() => this.props.handleClose()}
                >
                  <img src={close} alt="close"/>
                </div>
              </div>
              <div className="dialog-message-container">
                <div >
                  <div className="cancel-reason-container">
                    {this.props.warningText && this.props.warningText !== '' && 
                      <div className='warning-text'>
                        ーご注意くださいー<br/>
                      面接直前の変更は応募者の混乱を招く可能性があります。<br/>
                      メールや電話による個別フォローもご検討ください。<br/>
                      </div>
                    }
                    <SelectDropdown
                      errorMessage={DashboardTranslations.jobs_page_cancel_interview_reason_error_message}
                      error={submitted === true && selectedReason === ""}
                      label={'選考不合格理由（応募者には通知されません）'}
                      name="selectedReason"
                      value={selectedReason}
                      options={options}
                      handleChange={this.handleChange.bind(this)}
                    />
                    <div className="info-text2">
                      選択すると、選考不合格通知が応募者に送信され、面接予約がキャンセルされます。
                    </div>
                    {showOtherValue && <InputBox error={submitted === true && otherValue.length === 0}
                      errorMessage={DashboardTranslations.jobs_page_cancel_interview_other_error_message}
                      label={'その他を選考した場合はご入力ください'}
                      name="otherValue"
                      value={otherValue}
                      handleChange={this.handleChange.bind(this)}
                      textArea={true}
                    />}
                  </div>
                  <div className="button-container">
                  <button className="btn btn-secondary" onClick={() => this.props.handleClose()}>{this.props.cancelButtonText ? this.props.cancelButtonText : DashboardTranslations.confirm_modal_cancel_button_text}</button>
                    <button className="btn btn-primary" onClick={() => this.confirmAction()}>{this.props.confirmButtonText ? this.props.confirmButtonText : DashboardTranslations.confirm_modal_confirm_button_text}</button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
    );
  }
}

export default ConfirmCancelInterviewModal;
