import React, {Component} from 'react';
import InputBox from "../components/InputBoxNew.js";
import {loginSuccess, setRegisterStep} from '../../actions';
import {connect} from 'react-redux';
import Modal from '@material-ui/core/Modal';
import {inviteeRegisterAPI, loginAPI} from '../helperFunctions/dataServices.js';
import ErrorModal from '../components/ErrorModal';
import DashboardTranslations from '../../DashboardTranslations.js';
import {getErrorMessage} from '../../messages/errors';
import SuccessModal from '../components/SuccessModal';
import EmployerRegistrationSuccessModal from '../components/EmployerRegistrationSuccessModal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import correct from '../../images/correct.svg';
import wrong from '../../images/wrong.svg';
import {ToggleLayer, Arrow} from "react-laag";
import ResizeObserver from 'resize-observer-polyfill';
import './styles/register2.scss';
import close from '../../images/svg/close.svg';
import {nameRegex, passwordRegex, emailRegex} from '../../regex';
import CustomCheckbox from "../components/CustomCheckbox";


class InviteRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSuccess: false,
            email: "",
            lastName: "",
            firstName: "",
            companyNameTemp: "",
            password: "",
            confirmPassword: "",
            inviteToken: '',
            mobileNumber: "",
            showPasswordTooltip: false,
            showConfirmPasswordTooltip: false,
            agreeToPolicy: false,
            showEmployerRegistrationModal: false,
            reloadOnClose: false,
            showPasswordHelper: false,
            showConfirmPasswordHelper: false,
            emailLogin: "",
            passwordLogin: "",
            openLoginDrawer: false,
            errorEnLogin: null,
            errorJpLogin: null,
            submitted: false,
            showError: false,
            loginSubmitted: false,
            error: '',
        }
    }

    componentDidMount() {
        if (this.props.isLoggedIn) {
            this.props.history.push('/dashboard/jobs')
        }
        if (this.props.history.location.search.indexOf("?verified=true") >= 0) {
            this.setState({
                showSuccess: true,
                successMessage: DashboardTranslations.register_page_email_verification_success_message
            })
        } else if (this.props.history.location.search.indexOf("?verified=false") >= 0) {
            this.setState({showError: true, error: getErrorMessage('register_page_email_verification_error_message')})
        }
        if (this.props.history.location.search.indexOf('?') >= 0) {
            var urlParams = this.props.history.location.search
            var split = urlParams.split('&')
            var inviteToken = split[0].replace('?invite_token=', '')
            var emailArray = split[1].split('email=')
            var email = emailArray[0]
            if (emailArray.length > 1) {
                email = emailArray[1]
            }
            this.setState({inviteToken: inviteToken, email: email})
        }
    }

    handleChange = event => {
        var value = event.target.value
        if (event.target.name === "password") {
            this.setState({showPasswordHelper: true})
        } else if (event.target.name === "confirmPassword") {
            this.setState({showConfirmPasswordHelper: true})
        }
        this.setState({[event.target.name]: value});
    }

    handleLogin(event) {
        this.setState({loginSubmitted: true})
        if (!this.state.emailLogin) {
            this.setState({
                errorEnLogin: 'Email not provided.', error: 'メールアドレスは必須項目です', // showError: true,
            });
            return;
        }

        // Validate Email
        if (this.state.emailLogin) {
            const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!emailRegex.test(this.state.emailLogin)) {
                this.setState({
                    errorEnLogin: 'Email is in incorrect format.',
                    error: '正しいメールアドレスを入力してください', // showError: true,
                });
                return;
            }
        }

        if (!this.state.passwordLogin) {
            this.setState({
                errorEnLogin: 'Password not provided.', error: 'パスワードは必須項目です', // showError: true,
            });
            return;
        }

        const payload = {
            email: this.state.emailLogin, password: this.state.passwordLogin
        };
        this.setState({loading: true});
        loginAPI(payload).then((response) => {
            if (response && response.status >= 200 && response.status < 300) {
                this.setState({openLoginDrawer: false})
                this.props.dispatch(setRegisterStep(response.data.register_stage));
                this.props.dispatch(loginSuccess(response.data));
                this.props.history.push('/dashboard')
            } else {
                this.setState({
                    loading: false,
                    showError: true,
                    error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""
                })
            }
        })
            .catch((e) => {
                this.setState({loading: false, showError: true})
            })
        event.preventDefault();
    }

    handleCheckbox = name => event => {
        this.setState({[name]: event.target.checked});
    };

    handleSuccess() {
        this.setState({showSuccess: false, showEmployerRegistrationModal: false})
        if (this.state.reloadOnClose) {
            window.location.href = "/register"
        }
    }

    handleErrorClose = () => {
        this.setState({showError: false, error: ''})
    }

    validate() {
        let {
            email, password, confirmPassword, mobileNumber, firstName, lastName, agreeToPolicy, inviteToken
        } = this.state;
        const isMobileNumberValid = mobileNumber === "" || mobileNumber.length === 10 || mobileNumber.length === 11;

        if (emailRegex.test(email) && firstName.length > 0 && lastName.length > 0 && passwordRegex.test(password) && passwordRegex.test(confirmPassword)) {
            if (agreeToPolicy) {
                if (password === confirmPassword) {
                    if (isMobileNumberValid) {
                        const payload = {
                            email: email, password: password, first_name: firstName, last_name: lastName,
                        };

                        if (inviteToken.length > 0) {
                            payload.invite_token = inviteToken
                        }
                        if (mobileNumber !== "") {
                            payload.contact_num = mobileNumber
                        }

                        this.registerEmployer(payload)
                    }
                } else {
                    this.setState({
                        showError: true, error: getErrorMessage('register_page_passwords_do_not_match_error_message')
                    })
                }
            } else {
                this.setState({showError: true, error: getErrorMessage('register_page_agree_to_policy_error_message')})
            }
        } else {
            this.setState({submitted: true})
        }
    }

    registerEmployer(payload) {
        inviteeRegisterAPI(payload).then((response) => {
            if (response && response.status >= 200 && response.status < 300) {
                this.setState({
                    showSuccess: true,
                    successMessage: DashboardTranslations.register_page_invited_user_register_success_message,
                    reloadOnClose: true
                })
            } else {
                this.setState({
                    loading: false,
                    showError: true,
                    error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""
                })
            }
        })
            .catch((e) => {
                this.setState({loading: false, showError: true})
            })
    }

    render() {
        var {
            companyNameTemp,
            email,
            password,
            confirmPassword,
            lastName,
            firstName,
            mobileNumber,
            error,
            showError,
            showSuccess,
            loading,
            submitted,
            agreeToPolicy,
            showEmployerRegistrationModal,
            showPasswordHelper,
            showConfirmPasswordHelper,
            emailLogin,
            passwordLogin,
            loginSubmitted,
        } = this.state

        var isNumberPresentPassword = false
        var isNumberPresentConfirmPassword = false
        var isUpperCasePresentPassword = false
        var isUpperCasePresentConfirmPassword = false
        var isLowerCasePresentPassword = false
        var isLowerCasePresentConfirmPassword = false
        var isMinimumLengthPassword = false
        var isMinimumLengthConfirmPassword = false

        if (/\d/.test(password)) {
            isNumberPresentPassword = true
        }
        if (/[A-Z]/.test(password)) {
            isUpperCasePresentPassword = true
        }
        if (/[a-z]/.test(password)) {
            isLowerCasePresentPassword = true
        }
        if (password.length >= 8) {
            isMinimumLengthPassword = true
        }

        if (/\d/.test(confirmPassword)) {
            isNumberPresentConfirmPassword = true
        }
        if (/[A-Z]/.test(confirmPassword)) {
            isUpperCasePresentConfirmPassword = true
        }
        if (/[a-z]/.test(confirmPassword)) {
            isLowerCasePresentConfirmPassword = true
        }
        if (confirmPassword.length >= 8) {
            isMinimumLengthConfirmPassword = true
        }

        let mobileNumberError = !(mobileNumber === "" || mobileNumber.length === 10 || mobileNumber.length === 11);

        return (<div className="register-page-container">
                <div className="login-text-container">
                    <div className="login-main-container">
                        <div className="login-text">
                            すでに登録済みの企業様は<br />こちらからログインしてください
                        </div>
                        <button onClick={(event) => this.setState({openLoginDrawer: true})}>
                            ログイン
                        </button>
                    </div>
                </div>

                <div className="register-form-container">
                    <div className="register-form-title">新規ユーザー登録</div>
                    <div className="register-form-main-container">
                        <div className="register-form-top-text">
                            ご登録後すぐに掲載を開始できます！
                        </div>

                        <div className="form-fields">
                            <InputBox
                                value={lastName}
                                errorMessage={DashboardTranslations.register_page_last_name_error_message}
                                error={submitted === true && !nameRegex.test(lastName)}
                                label={DashboardTranslations.register_page_last_name_label}
                                name="lastName"
                                showMandatory={true}
                                handleChange={this.handleChange.bind(this)}
                            />
                            <InputBox
                                value={firstName}
                                errorMessage={DashboardTranslations.register_page_first_name_error_message}
                                error={submitted === true && !nameRegex.test(firstName)}
                                label={DashboardTranslations.register_page_first_name_label}
                                name="firstName"
                                showMandatory={true}
                                handleChange={this.handleChange.bind(this)}
                            />
                            <InputBox
                                value={email}
                                errorMessage={DashboardTranslations.register_page_email_error_message}
                                error={submitted === true && !emailRegex.test(email)}
                                label={DashboardTranslations.register_page_email_label}
                                name="email"
                                autoComplete={false}
                                showMandatory={true}
                                disabled={true}
                                handleChange={this.handleChange.bind(this)}
                                className="single-field"
                            />
                            <ToggleLayer
                                ResizeObserver={ResizeObserver}
                                renderLayer={({
                                                  isOpen,
                                                  layerProps,
                                                  arrowStyle,
                                                  layerSide
                                              }) => showPasswordHelper && !passwordRegex.test(password) && (<div
                                        ref={layerProps.ref}
                                        className="layer layer-popover"
                                        style={{
                                            ...layerProps.style,
                                            width: 180,
                                            height: 150,
                                            zIndex: 999,
                                            backgroundColor: "#FFFFFF",
                                            border: "1px solid #D0021B",
                                            borderRadius: "12px",
                                            padding: "10px"
                                        }}
                                    >
                                        <div className="register-page-validation-tooltip-container">
                                            <div className="validation-header">
                                                パスワードは
                                            </div>
                                            <div style={{height: 120}} className="validation-inner-container">
                                                <div className="single-validation">
                                                    <img src={isNumberPresentPassword ? correct : wrong}
                                                         style={{width: 14, height: 14, margin: "0px 5px"}}/>
                                                    数字
                                                </div>
                                                <div className="single-validation">
                                                    <img src={isUpperCasePresentPassword ? correct : wrong}
                                                         style={{width: 14, height: 14, margin: "0px 5px"}}/>
                                                    大文字
                                                </div>
                                                <div className="single-validation">
                                                    <img src={isLowerCasePresentPassword ? correct : wrong}
                                                         style={{width: 14, height: 14, margin: "0px 5px"}}/>
                                                    小文字
                                                </div>
                                                <div className="single-validation">
                                                    <img src={isMinimumLengthPassword ? correct : wrong}
                                                         style={{width: 14, height: 14, margin: "0px 5px"}}/>
                                                    ８文字以上
                                                </div>
                                            </div>
                                        </div>
                                        <Arrow
                                            style={arrowStyle}
                                            layerSide={layerSide}
                                            backgroundColor="#FFFFFF"
                                            borderWidth={1}
                                            borderColor="#D0021B"
                                            roundness={0.5}
                                        />
                                    </div>)}
                                placement={{
                                    anchor: "RIGHT_BOTTOM", autoAdjust: true, triggerOffset: 10
                                }}
                            >
                                {({triggerRef, toggle}) => (<div ref={triggerRef}>
                                        <InputBox
                                            value={password}
                                            errorMessage={DashboardTranslations.register_page_password_error_message}
                                            error={submitted === true && !passwordRegex.test(password) ? true : false}
                                            label={DashboardTranslations.register_page_password_label}
                                            name="password"
                                            explanatoryText={DashboardTranslations.register_page_password_explanatory_text}
                                            inputType="password"
                                            autoComplete={false}
                                            showMandatory={true}
                                            handleChange={this.handleChange.bind(this)}
                                            className="single-field"
                                        />
                                    </div>)}
                            </ToggleLayer>

                            <ToggleLayer
                                ResizeObserver={ResizeObserver}
                                renderLayer={({
                                                  isOpen,
                                                  layerProps,
                                                  arrowStyle,
                                                  layerSide
                                              }) => showConfirmPasswordHelper && !passwordRegex.test(confirmPassword) && (
                                    <div
                                        ref={layerProps.ref}
                                        className="layer layer-popover"
                                        style={{
                                            ...layerProps.style,
                                            width: 180,
                                            height: 150,
                                            backgroundColor: "#FFFFFF",
                                            border: "1px solid #D0021B",
                                            borderRadius: "12px",
                                            padding: "10px"
                                        }}
                                    >
                                        <div className="register-page-validation-tooltip-container">
                                            <div className="validation-header">
                                                パスワードは
                                            </div>
                                            <div style={{height: 120}} className="validation-inner-container">
                                                <div className="single-validation">
                                                    <img src={isNumberPresentConfirmPassword ? correct : wrong}
                                                         style={{width: 14, height: 14, margin: "0px 5px"}}/>
                                                    数字
                                                </div>
                                                <div className="single-validation">
                                                    <img src={isUpperCasePresentConfirmPassword ? correct : wrong}
                                                         style={{width: 14, height: 14, margin: "0px 5px"}}/>
                                                    大文字
                                                </div>
                                                <div className="single-validation">
                                                    <img src={isLowerCasePresentConfirmPassword ? correct : wrong}
                                                         style={{width: 14, height: 14, margin: "0px 5px"}}/>
                                                    小文字
                                                </div>
                                                <div className="single-validation">
                                                    <img src={isMinimumLengthConfirmPassword ? correct : wrong}
                                                         style={{width: 14, height: 14, margin: "0px 5px"}}/>
                                                    ８文字以上
                                                </div>
                                            </div>
                                        </div>
                                        <Arrow
                                            style={arrowStyle}
                                            layerSide={layerSide}
                                            backgroundColor="#FFFFFF"
                                            borderWidth={1}
                                            borderColor="#D0021B"
                                            roundness={0.5}
                                        />
                                    </div>)}
                                placement={{
                                    anchor: "RIGHT_BOTTOM", autoAdjust: true, triggerOffset: 10
                                }}
                            >
                                {({triggerRef, toggle}) => (<div ref={triggerRef}>
                                        <InputBox
                                            value={confirmPassword}
                                            errorMessage={DashboardTranslations.register_page_password_error_message}
                                            error={submitted === true && !passwordRegex.test(confirmPassword) ? true : false}
                                            label={DashboardTranslations.register_page_password_label}
                                            name="confirmPassword"
                                            explanatoryText={DashboardTranslations.register_page_password_explanatory_text}
                                            inputType="password"
                                            showMandatory={true}
                                            autoComplete={false}
                                            handleChange={this.handleChange.bind(this)}
                                            className="single-field"
                                        />
                                    </div>)}
                            </ToggleLayer>
                            <InputBox
                                value={mobileNumber}
                                error={submitted && mobileNumberError ? true : false}
                                errorMessage={DashboardTranslations.user_settings_mobile_error_message}
                                label={DashboardTranslations.register_page_mobile_number_label}
                                explanatoryText="ハイフンなし"
                                name="mobileNumber"
                                handleChange={this.handleChange.bind(this)}
                                className="single-field"
                            />
                        </div>


                        <div className="terms-and-conditions">
                            <FormControlLabel
                                control={<CustomCheckbox agreeToPolicy={agreeToPolicy}
                                                         handleCheckbox={this.handleCheckbox}/>}
                            />
                            <span>
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.workjapan.jp/terms"
                >
                  {" "}
                    利用規約
                </a>
                に同意します
              </span>
                        </div>
                        <button className="register-submit-button" onClick={() => this.validate()}>
                            登録する
                        </button>
                    </div>
                </div>
                <SuccessModal
                    customTitle="登録完了"
                    successMessage={'ユーザー登録が完了しました。\n「企業様ログイン」からログインしてください。'}
                    handleClose={this.handleSuccess.bind(this)}
                    showSuccess={showSuccess}
                />

                <EmployerRegistrationSuccessModal email={email} handleClose={this.handleSuccess.bind(this)}
                                                  showSuccess={showEmployerRegistrationModal}/>
                <ErrorModal error={error} handleClose={this.handleErrorClose.bind(this)} showError={showError}/>
                <Modal anchor="top" open={this.state.openLoginDrawer}
                       onClose={() => this.setState({openLoginDrawer: false})}>
                    <div className="login-modal-container">
                        <div className="login-modal-inner">
                            <div className="responsive-header">
                                <div className="login-header-text">
                                    {DashboardTranslations.login_button_text}
                                </div>
                                <div className="burger" onClick={() => this.setState({openLoginDrawer: false})}>
                                    <img src={close} alt="close"/>
                                </div>
                            </div>
                            <div className="login-inner-modal">
                                <form>
                                    <InputBox
                                        value={emailLogin}
                                        errorMessage={DashboardTranslations.register_page_email_error_message}
                                        error={loginSubmitted === true && !emailRegex.test(emailLogin) ? true : false}
                                        label={DashboardTranslations.register_page_email_label}
                                        name="emailLogin"
                                        handleChange={this.handleChange.bind(this)}
                                        className="single-field"
                                    />
                                    <InputBox
                                        value={passwordLogin}
                                        errorMessage={DashboardTranslations.register_page_password_error_message}
                                        error={loginSubmitted === true && !passwordRegex.test(passwordLogin) ? true : false}
                                        label={DashboardTranslations.register_page_password_label}
                                        name="passwordLogin"
                                        inputType="password"
                                        handleChange={this.handleChange.bind(this)}
                                        className="single-field"
                                    />
                                    <div className="submit-button-container">
                                        <div className="btn btn-primary" onClick={(event) => this.handleLogin(event)}>
                                            ログイン
                                        </div>
                                        <div className="forgot-password-text">パスワードを忘れた方は <a
                                            style={{textDecoration: "none"}}
                                            href="/forgot-password">こちらから</a> 再設定
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
    }
};

InviteRegister = connect(mapStateToProps)(InviteRegister);

export default InviteRegister;
