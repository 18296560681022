import React, {Component} from 'react';
import { connect } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import InnerHeader from '../components/InnerHeader.js'
import DashboardTranslations from '../../DashboardTranslations.js'
import ScaleLoader from 'react-spinners/ScaleLoader';
import toASCII from '../helperFunctions/toASCII'
import './styles/companyProfile.scss'
import ErrorModal from '../components/ErrorModal'
import ReactGA from 'react-ga';
import { API_ROOT, ENV_TYPE } from '../../constants';
import { nameRegex, katakanaRegex } from '../../regex';
import {getErrorMessage} from "../../messages/errors";
var lotNoPattern = new RegExp(/^\d+(-\d+){0,3}$/)

class CompanyProfile extends Component {

  constructor(props) {
      super(props);
      this.state = {
        header: '',
        companyId: '',
        edit: false,
        companyDetails: [],
        loading: false,
        options: [],
        dispatchAgency: false,
        industryType: '',
        num_of_employees: '',
        num_of_branches: '',
        name_en: '',
        name_jp: '',
        website: "",
        telephone: '',
        submitted: false,
        postalCode: '',
        postalCodeId: '',
        postalCodeOptions: [],
        prefecture: '',
        city1: '',
        city2: '',
        productTourModal: false,
        lot_num: '',
        building_name_jp: '',
        building_name_en: '',
        num_of_foreigners_hired: '',
        is_non_fluent_jp_speaker_ok: false,
        ageGroup: [],
        nationalities: [],
        is_dispatch_agency: false,
        error: '',
        showError: false,
        showIndustryOther: false,
        industryOtherValue: "",
        showSubmitButtonError: false,
        company_name_kt: "",
        ceo_first_name: "",
        ceo_last_name: "",
        ceo_first_name_kt: "",
        ceo_last_name_kt: "",
        admin_first_name_kt: "",
        admin_last_name_kt: "",
      }
    }

    componentDidMount() {
      // console.log(this.props.isAdmin);
      if (ENV_TYPE === "PROD") {
        this.initializeReactGA()
      }
      if (!this.props.isLoggedIn) {
        this.props.history.push('/employer-home')
      }

      this.retrieveCompanyDetails()
      this.initializeCompany()

    }

    initializeReactGA() {
      ReactGA.initialize('UA-104174852-1');
      ReactGA.pageview('/dashboard/company-profile');
    }

    initializeCompany() {
      var component = this
      fetch(API_ROOT + 'api/v2/employer/initialize_company/', {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${this.props.token}`
          },
      }).then(function(response) {
          return response.json()
      }).then(function(json) {
          if (!json.error) {
            // console.log(json);
            component.setState({options: json})
          } else {
              component.setState({loading: false, showError: true, error: getErrorMessage('from_api_response', {'apiErrorMessage': json.error})});
          }
      }).catch(function(ex) {
          component.setState({loading: false, showError: true, error: ex.error});
      });
    }

    handleChange = event => {
      var value = event.target.value
      var name = event.target.value
      if (name === "name_jp" || name === "name_en" || name === "phoneNumber") {
        value = toASCII(value)
      }
      if (event.target.name === 'postalCode') {
        if (value.length === 7) {
          this.getPostalCodeAdress(value, true)
        }
      }
      else if (event.target.name === 'prefecture') {
        this.getCities(value, true)
      }
      else if (event.target.name === "industryType") {
        this.state.options.industries.forEach((option, optionId) => {
          if (option.name === "その他" && parseInt(event.target.value) === parseInt(option.value)) {
            this.setState({showIndustryOther: true}, () => this.validateToShowErrorMessage())
          }
          else {
            this.setState({showIndustryOther: false}, () => this.validateToShowErrorMessage())
          }
        })
        this.setState({industryType: event.target.value}, () => this.validateToShowErrorMessage())
      }
      else if (event.target.name === 'city1') {
        this.getWards(value, true)
      }
      if (event.target.name === 'phoneNumber') {
        if (value.match(/^[0-9]+$/) || value === '') {
          this.setState({ [event.target.name]: value }, () => this.validateToShowErrorMessage());
        }
      }
      else {
        this.setState({ [event.target.name]: value }, () => this.validateToShowErrorMessage());
      }
    };

    retrieveCompanyDetails() {
      this.setState({loading: true})
      var component = this
      fetch(API_ROOT + 'api/v2/employer/company/', {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${this.props.token}`
          },
      }).then(function(response) {
          return response.json()
      }).then(function(json) {
          component.setState({loading: false})
          if (json) {
            if (json[0]) {
              var details = json[0]
              // console.log(details);
              var { is_dispatch_agency, industry, name_jp, name_en, telephone, website, preferred_age_groups,
                nationalities_hired, num_of_branches, is_non_fluent_jp_speaker_ok, num_of_employees, lot_num, building_name_jp,
                building_name_en, num_of_foreigners_hired, company_name_kt, ceo_last_name, ceo_first_name, ceo_first_name_kt, ceo_last_name_kt, admin_first_name_kt, admin_last_name_kt
              } = details
              var postalCode = details.postcode.name
              var postalCodeId = details.postcode.value
              var ageGroup = preferred_age_groups.map(age => age.value)
              var nationalities = nationalities_hired.map(nat => nat.value)
              component.setState({
                loading: false, companyId: details.id, companyDetails: details, name_jp: name_jp, name_en: name_en, telephone: telephone,
                website: website, num_of_branches: num_of_branches ? num_of_branches.value : "", num_of_employees: num_of_employees ? num_of_employees.value : "" ,
                postalCode: postalCode, postalCodeId: postalCodeId, lot_num: lot_num, building_name_jp: building_name_jp, building_name_en: building_name_en,
                num_of_foreigners_hired: num_of_foreigners_hired ? num_of_foreigners_hired.value : "", is_non_fluent_jp_speaker_ok: is_non_fluent_jp_speaker_ok,
                ageGroup: ageGroup, nationalities: nationalities, dispatchAgency: is_dispatch_agency, industryType: industry ? industry.value : null,
                company_name_kt: company_name_kt ? company_name_kt : "", ceo_first_name: ceo_first_name ? ceo_first_name : "", ceo_last_name: ceo_last_name ? ceo_last_name : "", ceo_first_name_kt: ceo_first_name_kt ? ceo_first_name_kt: "",
                ceo_last_name_kt: ceo_last_name_kt ? ceo_last_name_kt : "", admin_first_name_kt: admin_first_name_kt ? admin_first_name_kt : "", admin_last_name_kt: admin_last_name_kt ? admin_last_name_kt : ""
              })
            }
          }
          else {
              component.setState({loading: false, showError: true, error: getErrorMessage('from_api_response', {'apiErrorMessage': json.error})});
          }
      }).catch(function(ex) {
        console.log(ex);
          component.setState({loading: false, showError: true, error: ex.error});
      });
    }

    saveCompanyDetails(payload) {
      this.setState({loading: true})
      var component = this
      var { companyId } = this.state
      fetch(API_ROOT + 'api/v2/employer/company/'+companyId+'/', {
          method: 'PATCH',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${this.props.token}`
          },
          body: JSON.stringify(payload)
      }).then(function(response) {
          return response.json()
      }).then(function(json) {
          if (!json.error) {
            component.setState({loading: false, edit: false}, () => component.retrieveCompanyDetails())
          } else {
              component.setState({loading: false, showError: true, error: getErrorMessage('from_api_response', {'apiErrorMessage': json.error})});
          }
      }).catch(function(ex) {
        console.log(ex);
          component.setState({loading: false, showError: true, error: ex.error});
      });
    }

    editCompanyProfile() {
      var { companyDetails } = this.state
      this.getPostalCodeAdress(companyDetails.postcode.name)
      this.setState({edit: true, prefecture: companyDetails.prefecture.value, city1: companyDetails.city.value, city2: companyDetails.ward.value})
    }

    getPostalCodeAdress(value, refill) {
      var component = this
      var intValue = parseInt(value, 10)
      fetch(API_ROOT + 'api/v2/employer/postcode_address/?code='+intValue, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${this.props.token}`
          },
      }).then(function(response) {
          return response.json()
      }).then(function(json) {
          if (!json.error) {
            var prefecture = ''
            var city1 = ''
            var city2 = ''
            var postalCodeId = ''
            if (json) {
              prefecture = json.prefectures[0].id
              city1 = json.cities[0].id
              city2 = json.wards[0].id
              postalCodeId = json.postcode_id
            }
            component.setState({postalCodeOptions: json, postalCodeId: postalCodeId})
            if (refill) {
              component.setState({prefecture: prefecture, city1: city1, city2: city2,})
            }
          } else {
              component.setState({loading: false, showError: true, postalCode: '', error: getErrorMessage('from_api_response', {'apiErrorMessage': json.error})});
          }
      }).catch(function(ex) {
          component.setState({loading: false, showError: true, postalCode: '', error: ex.error});
      });
    }

    getCities(value, refill) {
      var component = this
      var intValue = parseInt(value, 10)
      fetch(API_ROOT + 'api/v2/employer/cities/?prefecture='+intValue, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${this.props.token}`
          },
      }).then(function(response) {
          return response.json()
      }).then(function(json) {
          if (json) {
            // console.log(json);
            var postalCodeOptions = component.state.postalCodeOptions
            postalCodeOptions.cities = json
            component.setState({postalCodeOptions: postalCodeOptions})
            if (refill) {
              component.setState({city1: '', city2: ''})
            }
          } else {
              component.setState({loading: false});
          }
      }).catch(function(ex) {
        console.log(ex);
          component.setState({loading: false});
      });
    }

    getWards(value, refill) {
      var component = this
      var intValue = parseInt(value, 10)
      fetch(API_ROOT + 'api/v2/employer/wards/?city='+intValue, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${this.props.token}`
          },
      }).then(function(response) {
          return response.json()
      }).then(function(json) {
          if (json) {
            // console.log(json);
            var postalCodeOptions = component.state.postalCodeOptions
            postalCodeOptions.wards = json
            component.setState({postalCodeOptions: postalCodeOptions})
            if (refill) {
              component.setState({ city2: ''})
            }
          } else {
              component.setState({loading: false});
          }
      }).catch(function(ex) {
        console.log(ex);
          component.setState({loading: false});
      });
    }

    backArrowOnClick() {
      this.props.history.goBack();
    }

    removeCountry(id) {
      var nationalities = this.state.nationalities
      nationalities = nationalities.filter(age => age !== id)
      this.setState({nationalities: nationalities})
    }

    validateAllValues() {
      this.setState({submitted: true}, () => this.validateToShowErrorMessage())
      var {
         dispatchAgency, industryType, num_of_employees, num_of_branches, postalCode, showIndustryOther, industryOtherValue,
         name_en, name_jp, website, telephone, prefecture, city1, city2, lot_num, ageGroup,
         building_name_jp, building_name_en, num_of_foreigners_hired, is_non_fluent_jp_speaker_ok, nationalities, is_dispatch_agency, postalCodeId,
         company_name_kt, ceo_last_name, ceo_first_name, ceo_first_name_kt, ceo_last_name_kt, admin_first_name_kt, admin_last_name_kt
       } = this.state
      var payload = {}
      var lotNoPattern = new RegExp(/^\d+(-\d+){0,3}$/)
      payload.name_en = name_en
      payload.name_jp = name_jp
      if (num_of_employees) {
        payload.num_of_employees = num_of_employees
      }
      if (num_of_branches) {
        payload.num_of_branches = num_of_branches
      }
      payload.is_dispatch_agency = is_dispatch_agency
      if (website) {
        payload.website = website
      }
      else {
        payload.website = null
      }
      payload.is_dispatch_agency = dispatchAgency
      if (!dispatchAgency) {
        payload.industry = industryType
        if (showIndustryOther === true) {
          payload.custom_industry = industryOtherValue
        }
      }

      payload.telephone = telephone
      payload.prefecture = prefecture
      payload.city = city1
      payload.ward = city2
      payload.lot_num = lot_num
      payload.building_name_jp = building_name_jp
      payload.building_name_en = building_name_en
      payload.postcode = postalCodeId

      payload.num_of_foreigners_hired = num_of_foreigners_hired ? num_of_foreigners_hired : null
      payload.is_non_fluent_jp_speaker_ok = is_non_fluent_jp_speaker_ok
      payload.preferred_age_groups = ageGroup
      payload.nationalities_hired = nationalities
      payload.company_name_kt = company_name_kt
      payload.ceo_first_name = ceo_first_name
      payload.ceo_last_name = ceo_last_name
      payload.ceo_first_name_kt = ceo_first_name_kt
      payload.ceo_last_name_kt = ceo_last_name_kt
      payload.admin_first_name_kt = admin_first_name_kt
      payload.admin_last_name_kt = admin_last_name_kt

      if (name_en.length>0 && name_jp.length>0 && name_jp.length <= 30 && name_en.length <= 30 && (telephone.length === 10 || telephone.length === 11) && company_name_kt.length > 0
        && nameRegex.test(ceo_first_name) && nameRegex.test(ceo_last_name) && katakanaRegex.test(ceo_first_name_kt) && katakanaRegex.test(ceo_last_name_kt) && katakanaRegex.test(admin_first_name_kt) && katakanaRegex.test(admin_last_name_kt)
        && lotNoPattern.test(lot_num) && postalCode.toString().length===7 && city1 !== '' && city2 !== '' && prefecture !== '') {
        this.saveCompanyDetails(payload)
      }
    }

    validateToShowErrorMessage() {
      var {
         postalCode, name_en, name_jp, telephone, prefecture, city1, city2, lot_num
       } = this.state

       if (name_en.length>0 && name_jp.length>0 && name_jp.length <= 30 && name_en.length <= 30 && (telephone.length === 10 || telephone.length === 11)
         && lotNoPattern.test(lot_num) && postalCode.toString().length===7 && city1 !== '' && city2 !== '' && prefecture !== '') {
           this.setState({showSubmitButtonError: false})
       }
       else {
         this.setState({showSubmitButtonError: true})
       }
    }

    handleClose = () => {
      this.setState({ showError: false, error: '' });
    };


    render() {
      var {
          companyDetails, options, edit, dispatchAgency, industryType, loading, industryOtherValue, showIndustryOther,
          name_en, name_jp, website, telephone, submitted, postalCode, prefecture, postalCodeOptions, city1, city2, lot_num,
          building_name_jp, building_name_en, error, showSubmitButtonError, company_name_kt, ceo_last_name, ceo_first_name,
          ceo_first_name_kt, ceo_last_name_kt, admin_first_name_kt, admin_last_name_kt
       } = this.state
      var pattern = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9z_.-]+([.]{1}[a-z0-9z]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)
      var lotNoPattern = new RegExp(/^\d+(-\d+){0,3}$/)
      var correctLotNo = false;
      if (lotNoPattern.test(lot_num) === true) {
        correctLotNo =  true
      }

      var correctUrl = false;
      if (website === null || website === "" || pattern.test(website) === true) {
        correctUrl =  true
      }

      var parentContainer = "company-profile-container"
      if (loading) {
        parentContainer = "company-profile-container speacial-loading-background"
      }

      var industryValue = ""
      if (!dispatchAgency && options.industries) {
        options.industries.forEach((ind) => {
          if (ind.value === industryType) {
            industryValue = ind.name
          }
        })
      }

        return (
          <div className={parentContainer}>
            <InnerHeader
              primary={true}
              history={this.props.history}
              headerText={DashboardTranslations.profile_page_main_heading}
            />
            <ScaleLoader sizeUnit={"px"} height={50}
              width={4} color={'#007AFF'} loading={loading}
            />
            {!loading && <div className="card-main-container">
              <div className="section-header">
                会社情報
                { !edit &&
                  <button onClick={() => this.editCompanyProfile()} height= "20"  width= "20"
                    className="btn btn-primary">
                    {DashboardTranslations.company_profile_edit_company_details_button_text}
                  </button>
                }
              </div>
              <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_name_japanese_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.name_jp : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="name_jp"
                    value={name_jp}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  { edit && (name_jp.length === 0 || name_jp.length > 30) && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.company_details_name_japanese_error_message}</FormHelperText> : false}
                </div>
              </div>
              <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_name_english_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.name_en : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="name_en"
                    value={name_en}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  { edit && (name_en.length === 0 || name_en.length > 30) && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.company_details_name_english_error_message}</FormHelperText> : false}
                </div>
              </div>

              <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_company_name_kt_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.company_name_kt : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="company_name_kt"
                    value={company_name_kt}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  { edit && (!katakanaRegex.test(company_name_kt)) && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.company_details_company_name_kt_error_message}</FormHelperText> : false}
                </div>
              </div>

              <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_ceo_last_name_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.ceo_last_name : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="ceo_last_name"
                    value={ceo_last_name}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  { edit && (ceo_last_name.length === 0 || ceo_last_name.length > 30) && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.company_details_ceo_last_name_error_message}</FormHelperText> : false}
                </div>
              </div>

              <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_ceo_first_name_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.ceo_first_name : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="ceo_first_name"
                    value={ceo_first_name}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  { edit && (ceo_first_name.length === 0 || ceo_first_name.length > 30) && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.company_details_ceo_first_name_error_message}</FormHelperText> : false}
                </div>
              </div>

              <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_ceo_last_name_kt_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.ceo_last_name_kt : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="ceo_last_name_kt"
                    value={ceo_last_name_kt}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  { edit && (!katakanaRegex.test(ceo_last_name_kt)) && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.company_details_ceo_last_name_kt_error_message}</FormHelperText> : false}
                </div>
              </div>

              <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_ceo_first_name_kt_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.ceo_first_name_kt : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="ceo_first_name_kt"
                    value={ceo_first_name_kt}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  { edit && (!katakanaRegex.test(ceo_first_name_kt)) && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.company_details_ceo_first_name_kt_error_message}</FormHelperText> : false}
                </div>
              </div>

              <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_admin_last_name_kt_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.admin_last_name_kt : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="admin_last_name_kt"
                    value={admin_last_name_kt}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  { edit && (!katakanaRegex.test(admin_last_name_kt)) && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.company_details_admin_last_name_kt_error_message}</FormHelperText> : false}
                </div>
              </div>

              <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_admin_first_name_kt_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.admin_first_name_kt : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="admin_first_name_kt"
                    value={admin_first_name_kt}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  { edit && (!katakanaRegex.test(admin_first_name_kt)) && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.company_details_admin_first_name_kt_error_message}</FormHelperText> : false}
                </div>
              </div>

              {<div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_dispatch_agency_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                { !edit ? dispatchAgency ? "はい" : "いいえ" : ""}
                {edit &&
                  <div className="table-column-right special-option-box">
                    <div className="option-button" style={{marginRight: 10, border: dispatchAgency ? '2px solid #007AFF' : '1px solid rgba(201, 201, 201, 0.800000011920929)'}} onClick={() => this.setState({dispatchAgency: true})}>
                      はい
                    </div>
                    <div className="option-button" style={{border: dispatchAgency ? '1px solid rgba(201, 201, 201, 0.800000011920929)' : '2px solid #007AFF'}} onClick={() => this.setState({dispatchAgency: false})}>
                      いいえ
                    </div>
                  </div>
                }
              </div>}
              {!dispatchAgency && <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_industry_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit ? !dispatchAgency ? industryValue : "" : ""}
                  { edit && <select
                    className="outlined-select-field"
                    value={industryType}
                    onChange={this.handleChange}
                    name="industryType"
                  >
                    <option value="" disabled>
                      {DashboardTranslations.company_details_industry_label}
                    </option>
                    {options && options.industries && options.industries.map(option => <option key={option.value} value={option.value}>{option.name}</option>)}
                  </select>}
                  {edit && industryType.length === 0 && !dispatchAgency && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.company_details_industry_error_message}</FormHelperText> : false}
                </div>
              </div>}

              {!dispatchAgency && showIndustryOther && <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.register_page_industry_other_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit ? !dispatchAgency ? industryOtherValue : "" : ""}
                  { edit &&
                    <input
                      value={industryOtherValue}
                      errorMessage={DashboardTranslations.register_page_industry_other_error_message}
                      error={submitted === true && (industryOtherValue.length === 0 || industryOtherValue.length > 30)}
                      name="industryOtherValue"
                      onChange={(event) => this.handleChange(event)}
                      className="single-field"
                    />
                  }
                  {edit && submitted === true && (industryOtherValue.length === 0 || industryOtherValue.length > 30) ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.register_page_industry_other_error_message}</FormHelperText> : false}
                </div>
              </div>}

              <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_website_label}{edit && <span className="optional-container">{DashboardTranslations.field_optional_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.website : " "}
                  { edit && <input
                    className="outlined-input-field"
                    name="website"
                    value={website}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  { edit && !correctUrl && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.company_details_website_error_message}</FormHelperText> : false}
                </div>
              </div>
              <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.company_details_phone_number_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.telephone : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="telephone"
                    value={telephone}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  {edit && telephone.length !== 10 && telephone.length !== 11 && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.company_details_phone_number_error_message}</FormHelperText> : false}
                </div>
              </div>

              {edit && <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.address_details_postal_code_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails && companyDetails.postcode ? companyDetails.postcode.name : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="postalCode"
                    value={postalCode}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  {edit && postalCode.length === 0 && submitted ? <FormHelperText className='company-details-error-message' id="name-error-text">{DashboardTranslations.address_details_postal_code_error_message}</FormHelperText> : false}
                </div>
              </div>}
              {edit && <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.address_details_prefecture_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails && companyDetails.prefecture ? companyDetails.prefecture.name : ""}
                  { edit && <select
                    className="outlined-select-field"
                    value={prefecture}
                    onChange={this.handleChange}
                    name="prefecture"
                  >
                    <option value="" disabled>
                      {DashboardTranslations.address_details_prefecture_label}
                    </option>
                    {postalCodeOptions.prefectures && postalCodeOptions.prefectures.map((option, optionId) => <option key={option.id+'prefecture'+optionId} value={option.id}>{option.name}</option>)}
                  </select>}
                  {edit && prefecture.length === 0 && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.address_details_prefecture_error_message}</FormHelperText> : false}
                </div>
              </div>}
              {edit && <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.address_details_city1_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails && companyDetails.city ? companyDetails.city.name : ""}
                  { edit && <select
                    className="outlined-select-field"
                    value={city1}
                    onChange={this.handleChange}
                    name="city1"
                  >
                    <option value="" disabled>
                      {DashboardTranslations.address_details_city1_label}
                    </option>
                    {postalCodeOptions.cities && postalCodeOptions.cities.map((option, optionId) => <option key={option.id+'cities'+optionId} value={option.id}>{option.name}</option>)}
                  </select>}
                  {edit && city1.length === 0 && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.address_details_city1_error_message}</FormHelperText> : false}
                </div>
              </div>}
              {edit && <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.address_details_city2_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails && companyDetails.ward ? companyDetails.ward.name : ""}
                  { edit && <select
                    className="outlined-select-field"
                    value={city2}
                    onChange={this.handleChange}
                    name="city2"
                  >
                    <option value="" disabled>
                      {DashboardTranslations.address_details_city2_label}
                    </option>
                    {postalCodeOptions.wards && postalCodeOptions.wards.map((option, optionId) => <option key={option.id+'wards'+optionId} value={option.id}>{option.name}</option>)}
                  </select>}
                  {edit && city2.length === 0 && submitted ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.address_details_city2_error_message}</FormHelperText> : false}
                </div>
              </div>}
              {edit && <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.address_details_lot_number_label}{edit && <span className="mandatory-container">{DashboardTranslations.field_mandatory_text}</span>}
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.lot_num : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="lot_num"
                    value={lot_num}
                    onChange={(event) => this.handleChange(event)}
                  />}
                  {edit && submitted && !correctLotNo ? <FormHelperText className="company-details-error-message" id="name-error-text">{DashboardTranslations.address_details_lot_number_error_message}</FormHelperText> : false}

                </div>
              </div>}
              {edit && <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.address_details_building_name_japanese_label}
                  <span className="optional-container">{DashboardTranslations.field_optional_text}</span>
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.building_name_jp : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="building_name_jp"
                    value={building_name_jp}
                    onChange={(event) => this.handleChange(event)}
                  />}
                </div>
              </div>}
              {edit && <div className="table-row">
                <div className="table-column-left">
                  {DashboardTranslations.address_details_building_name_label}
                  <span className="optional-container">{DashboardTranslations.field_optional_text}</span>
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.building_name_en : ""}
                  { edit && <input
                    className="outlined-input-field"
                    name="building_name_en"
                    value={building_name_en}
                    onChange={(event) => this.handleChange(event)}
                  />}
                </div>
              </div>}

              { !edit && <div className="table-row">
                <div className="table-column-left">
                  住所
                </div>
                <div className="table-column-right">
                  { !edit && companyDetails ? companyDetails.address : ""}

                </div>
              </div>}
            </div>}

            {!loading && edit &&
              <div className="submit-button-container">
                {<div className="btn btn-primary"
                  style={{border: submitted && showSubmitButtonError ? "2px solid #ED5356" : "0px solid #007AFF"}}
                  onClick={() => this.validateAllValues()}>
                  保存
                </div>}
                {<div className="btn btn-secondary" onClick={() => this.setState({edit: false})}>
                  戻る
                </div>}
              </div>
            }
            {!loading && submitted && showSubmitButtonError && edit &&
              <div style={{fontSize: 10, color: "#ED5356", marginBottom: 25}}>
                {DashboardTranslations.submit_button_error_message}
              </div>
            }
            <ErrorModal error={error} handleClose={this.handleClose.bind(this)} showError={this.state.showError}/>
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        token: state.user.token,
        registerStep: state.user.registerStep,
        isAdmin: state.user.is_admin
    }
};

CompanyProfile = connect(mapStateToProps)(CompanyProfile);

export default CompanyProfile;
