import React, { Component } from 'react';
import DashboardTranslations from '../../DashboardTranslations.js';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '../../images/help-icon.png'
import '../styles/inputBox.scss';

class InputBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    }
  }

  componentDidMount() {}

  handleChange = event => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    var { name, label, error, errorMessage, showMandatory, showOptional,
          type, value, inputType, leaveErrorSpace, tooltipText, placeholder,
          disabled, actionLabelText, explanatoryText, salary, specialExplanatoryText, autoComplete, explanatoryText2,
          textArea, readOnly, id, height, textOnly, mandatoryText, isFullHeight,
         } = this.props
    var inputClass = "single-input"
    var inputContainerClass = "single-input-container"
    var errorText = ""
    var errorMessageClass = "error-message-container"
    if (error) {
      inputClass = "single-input error"
      errorText = errorMessage
    }
    if (type === "multiline") {
      inputContainerClass += " single-input-multiline"
    }
    if (leaveErrorSpace) {
      errorMessageClass = "error-message-container error-message-leave-space"
    }
    var mandatoryBox
    if (showMandatory) {
      mandatoryBox =
        <span className="mandatory-container">
          {DashboardTranslations.field_mandatory_text}
        </span>
    }
    var optionalBox
    if (showOptional) {
      optionalBox =
        <span className="optional-container">
          {DashboardTranslations.field_optional_text}
        </span>
    }
    return (
      <div className={inputContainerClass} style={isFullHeight ? {height: 'calc(100% - 70px)'} : {}}>
        {!textOnly && <div className="label-container">{label}{mandatoryText} {mandatoryBox} {optionalBox}
          {tooltipText &&
          <Tooltip title={tooltipText} placement="top">
            <img alt="tooltip" className="tooltip-text" src={HelpIcon} />
          </Tooltip>
          }
          {actionLabelText && <span onClick={() => this.props.actionLabelCallback()} className="action-button">{actionLabelText}</span>}
        </div>}
        {!textOnly && <div className={errorMessageClass}>{errorText}</div>}

        {textArea &&
        <div style={isFullHeight ? {height: '100%', position: 'relative'} : {position: 'relative'}}>
          <textarea
            id={id}
            readOnly={readOnly}
            className={inputClass}
            onChange={(event) => this.props.handleChange(event)}
            name={name}
            placeholder={placeholder}
            style={{opacity: disabled ? 0.7 : 1, width: '100%', padding: '15px', paddingBottom: '35px', height: height ? height : 'auto', resize: readOnly ? 'none' : '', overflow: readOnly ? 'hidden' : ''}}
            value={value}
            disabled={disabled}
          />
          {readOnly && !textOnly && !disabled && <div
            style={{ color: '#0078FF', fontSize: '12px', cursor: 'pointer', position: 'absolute', right: '36%', bottom: '10px'}}
            onClick={(event) => this.props.handleClick(event)}
            className='modal-open-button'
          >
            もっと見る/編集する
          </div>}
        </div>
        }

        {!salary && !textArea &&
          <input type={inputType ? inputType: "text"} disabled={disabled} className={type==="multiline" ? "single-input-multiline" : ""}
          onChange={(event) => this.props.handleChange(event)}
          name={name}
          placeholder={placeholder}
          style={{opacity: disabled ? 0.7 : 1}}
          value={value}
          className={inputClass}
        />}
        {salary && !textArea && <div className="salary-input-container">
          <input type="number" className="single-input-multiline"
            onChange={(event) => this.props.handleChange(event)}
            name={name}
            value={value}
            className={inputClass}
          />
          <span>&nbsp;/時</span>
        </div>}
        {explanatoryText && <div className="explanatory-text">{explanatoryText}</div>}
        {explanatoryText2 && <div className="explanatory-text">{explanatoryText2}</div>}
        {specialExplanatoryText && <div className="special-explanatory-text">{specialExplanatoryText}</div>}
      </div>
    );
  }
}

export default InputBox;
