import React, { Component } from 'react';
import closeIcon from '../../images/carousel-close.png'

const ImageSlide = ({ url, deleteImage }) => {
  const styles = {
    backgroundImage: `url(${url})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: "no-repeat"
  };

  return (
    <div className="image-slide" style={styles}>

    </div>
  );
}

export default ImageSlide
