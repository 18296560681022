const initialState = {
    // todo : Validate token first. If token not valid, treat user as not logged in

    email: localStorage.getItem('email') ? localStorage.getItem('email') : null,
    firstName: localStorage.getItem('firstName') ? localStorage.getItem('firstName') : null,
    lastName: localStorage.getItem('lastName') ? localStorage.getItem('lastName') : null,
    isLoggedIn: !!localStorage.getItem('token'),
    loginError: null,
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    isAdmin: !!localStorage.getItem('isAdmin'),
    isEmailVerified: !!localStorage.getItem('isEmailVerified'),
    displayLanguage: localStorage.getItem('displayLanguage') ? localStorage.getItem('displayLanguage') : 'EN',
    registerStep: localStorage.getItem('registerStep') ? localStorage.getItem('registerStep') : 1,
};

function user(state = initialState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'REGISTRATION_SUCCESSFUL':
            newState.email = action.response.email;
            newState.firstName = action.response.first_name;
            newState.lastName = action.response.last_name;
            newState.token = action.response.token;
            newState.isLoggedIn = true;
            newState.isAdmin = action.response.is_admin;
            newState.isEmailVerified = action.response.is_email_verified;
            newState.registerStep = action.response.register_stage;
            newState.isExistingRecruiter = action.response.is_existing_recruiter;

            localStorage.setItem('email', newState.email);
            localStorage.setItem('firstName', newState.firstName);
            localStorage.setItem('lastName', newState.lastName);
            localStorage.setItem('token', newState.token);
            localStorage.setItem('isAdmin', newState.isAdmin);
            localStorage.setItem('isEmailVerified', newState.isEmailVerified);
            localStorage.setItem('registerStep', newState.registerStep);
            localStorage.setItem('isExistingRecruiter', newState.isExistingRecruiter);

            return newState;


        case 'SET_REGISTER_STEP':
            newState.registerStep = action.step;
            localStorage.setItem('registerStep', newState.registerStep);
            return newState;

        case 'LOGIN_SUCCESSFUL':
            // console.log("action", action.response);
            newState.email = action.response.email;  //
            newState.firstName = action.response.first_name;  //
            newState.lastName = action.response.last_name;  //
            newState.token = action.response.token;  //
            newState.isLoggedIn = true;  //
            newState.isAdmin = action.response.is_admin;  //
            newState.isEmailVerified = action.response.is_email_verified;  //
            newState.isExistingRecruiter = action.response.is_existing_recruiter;

            localStorage.setItem('email', newState.email);
            localStorage.setItem('firstName', newState.firstName);
            localStorage.setItem('lastName', newState.lastName);
            localStorage.setItem('token', newState.token);
            localStorage.setItem('isAdmin', newState.isAdmin);
            localStorage.setItem('isEmailVerified', newState.isEmailVerified);
            localStorage.setItem('registerStep', newState.registerStep);
            localStorage.setItem('isExistingRecruiter', newState.isExistingRecruiter);

            return newState;

        // case 'LOGIN_FAILED':
        //     newState.loginError = action.error;
        //     return newState;
        //

        // case 'LOGOUT_FAILED':
        //     // todo : Handle logout failed
        //     return newState;
        //
        case 'LOGOUT':
            localStorage.removeItem('email');
            localStorage.removeItem('firstName');
            localStorage.removeItem('lastName');
            localStorage.removeItem('token');
            localStorage.removeItem('isAdmin');
            localStorage.removeItem('isEmailVerified');
            localStorage.removeItem('registerStep');
            localStorage.removeItem('numberOfJobs');

            newState.email = null;
            newState.firstName = null;
            newState.lastName = null;
            newState.isLoggedIn = false;
            newState.loginError = null;
            newState.token = null;

            newState.isAdmin = false;
            newState.isEmailVerified = false;
            // newState.displayLanguage = 'EN';
            newState.registerStep = null;

            return newState;

        default:
            return state;
    }
}

export default user;
