import React, { Component } from 'react';
import { connect } from 'react-redux';

import modalImage from '../../images/modals/infoModalVerifyJobDetails.png';
import closeIcon from '../../images/svg/close-icon-with-circle.svg';

import './infoModalVerifyJobDetails.scss';

class InfoModalVerifyJobDetails extends Component {
    render() {
        return (
            <div className="info-modal-verify-job-details">
                <div className="modal-outer-container">
                    <img className="info-image-1" src={modalImage}  alt="ss" />

                    <button className="btn" onClick={() => this.props.closeInfoModalVerifyJobDetails()}>
                        閉じる
                    </button>
                    <img className="close-icon" src={closeIcon}  alt="ss" onClick={() => this.props.closeInfoModalVerifyJobDetails()}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
    }
};

InfoModalVerifyJobDetails = connect(mapStateToProps)(InfoModalVerifyJobDetails);

export default InfoModalVerifyJobDetails;
