import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerHeader from '../components/InnerHeader.js'
import ScaleLoader from 'react-spinners/ScaleLoader';
import DashboardTranslations from '../../DashboardTranslations.js';

import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import 'react-overlay-loader/styles.css';
import './styles/selectPlan.scss';
import {API_ROOT} from "../../constants";
import SelectPlanWarningModal from "./SelectPlanWarningModal";
import JobTypeImage from "../../images/job-type-image.svg";
import SelectNewPlanImage from '../../images/new-select-plan.svg'


const useStyles = theme => ({
  table: {
    minWidth: 650,
  },
});

class SelectPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      data: {},
      showSuccess: false,
      selectedPlan: null,
      apiResponse: {},
      planId: null
    }
  }

  componentDidMount() {
    this.retrievePlans();
  }

  handleClose = () => {
    this.setState({ showSuccess: false, selectedPlan: null, planId: null});
  };

  warningAccepted = () => {
    let pathname = '/dashboard/billing/select-payment-method';
    if (this.props.location.state.subscription_status === 'IS') {
      pathname = '/dashboard/billing/select-previous-payment-method';
    }

    this.props.history.push({
      pathname: pathname,
      state: {
        subscription_status: this.props.location.state.subscription_status,
        is_cc_added_in_gmo: this.props.location.state.is_cc_added_in_gmo,
        verification_status: this.props.location.state.verification_status,
        paidBy: this.props.location.state.paidBy,
        plan: this.state.selectedPlan,
        currSubscriptionData: this.props.location.state.currSubscriptionData,
        backToSelectPreviousPaymentMethod: false
      }
    });

    this.setState({ showSuccess: false, selectedPlan: null});
  };

  retrievePlans() {
    this.setState({loading: true})
    let component = this;
    fetch(API_ROOT + 'api/v4/employer/plans/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${this.props.token}`
      },
    }).then(function(response) {
      return response.json()
    }).then(function(json) {
      component.setState({loading: false})
      if (json) {
        component.setState({apiResponse: json})
        const maxJobs = Array.from(new Set(json.map(item => item.max_jobs))).sort((a,b) => a - b)
        const maxDays = Array.from(new Set(json.map(item => item.max_days))).sort((a,b) => a - b)
        let finalData = {}
        for(let maxJob of maxJobs) {
          finalData[maxJob] = {}
          finalData[maxJob][maxDays[0]] = {}
          finalData[maxJob][maxDays[1]] = {}
        }

        for(let data of json) {
          if(data.is_all_in_one) {
            finalData[data.max_jobs][data.max_days].is_all_in_one = data.price
            finalData[data.max_jobs][data.max_days].is_all_in_one_id = data.id
          } else {
            finalData[data.max_jobs][data.max_days].is_not_all_in_one = data.price
            finalData[data.max_jobs][data.max_days].is_not_all_in_one_id = data.id
          }
        }
        component.setState({data: finalData})
      }
      else {
        component.setState({loading: false});
      }
    }).catch(function(ex) {
      component.setState({loading: false});
    });
  }

  backArrowOnClick() {
    this.props.history.goBack();
  }

  handlePlanSelected() {
    if(this.state.planId) {
      let plan = this.state.apiResponse.find(x => String(x.id) === this.state.planId)
      this.setState({showSuccess: true, selectedPlan: plan})
    }
  }

  render() {
    const {loading, rows} = this.state;
    const { classes } = this.props;

    return (
        <div className="transaction-history-page-container margin-top-110">
          <InnerHeader
              primary={false}
              history={this.props.history}
              headerText={DashboardTranslations.select_plan_page_header_text}
              backArrowOnClick={this.backArrowOnClick.bind(this)}
          />
          <ScaleLoader sizeUnit={"px"} height={50}
                       width={4} color={'#007AFF'} loading={loading}
          />
          プラン間の記事の流用はできません。 <br/>
          購入プランを切り替える場合は、該当プラン用の記事作成が必要です。
          { !loading &&
          <div className="select-plan-container-2 table-container">
             {
              Object.keys(this.state.data).length &&
              <div className='select-plan-container-2 table-container'>
                <RadioGroup
                  aria-label="pricing"
                  name="pricing"
                  onChange={(e) => this.setState({planId: e.target.value})}
                  value={this.state.planId}
                >
                  <table className='new-table-container'>
                  <tr>
                    <th className='text custom-th'>
                      {' '}
                    </th>
                    <th colSpan={2} className='new-table-header new-table-header-red'>
                    アルバイト採用限定プラン
                    </th>
                    <th colSpan={2} className='new-table-header new-table-header-blue'>
                      <p style={{fontSize: 14, marginBottom: 0}}>オールインワンプラン</p>アルバイト・正社員・特定技能
                    </th>
                  </tr>
                  <tr>
                    <td className='new-table-value new-table-title' style={{paddingTop: 12, paddingBottom: 12}}>記事数</td>
                    <td className='new-table-value' style={{paddingTop: 12, paddingBottom: 12}}>ショート<br/>4週間</td>
                    <td className='new-table-value' style={{paddingTop: 12, paddingBottom: 12}}>ロング<br/>12週間</td>
                    <td className='new-table-value' style={{paddingTop: 12, paddingBottom: 12}}>ショート<br/>4週間</td>
                    <td className='new-table-value' style={{paddingTop: 12, paddingBottom: 12}}>ロング<br/>12週間</td>
                  </tr>
                  {Object.keys(this.state.data).map(maxJob => (
                    <tr key={`${maxJob}`}>
                      <td className='new-table-value new-table-title'>
                        {maxJob}記事
                      </td>
                    {Object.keys(this.state.data[maxJob]).map((maxDay, index) => (
                        <td className='new-table-value'>
                          <FormControlLabel
                            classes={{
                              root: 'new-table-radio-value',
                              label: 'new-table-radio-value'
                            }}
                            value={String(this.state.data[maxJob][maxDay]['is_not_all_in_one_id'])}
                            control={<Radio disableRipple={true} color="primary" size="small" className='table-radio'/>}
                            label={this.state.data[maxJob][maxDay]['is_not_all_in_one'].toLocaleString("ja-JP", {style: "currency", currency: "JPY"})}
                          />
                        </td>
                    ))}
                    {Object.keys(this.state.data[maxJob]).map((maxDay, index) => (
                        <td className='new-table-value'>
                          <FormControlLabel
                            classes={{
                              root: 'new-table-radio-value',
                              label: 'new-table-radio-value'
                            }}
                            value={String(this.state.data[maxJob][maxDay]['is_all_in_one_id'])}
                            control={<Radio disableRipple={true} color="primary" size="small" className='table-radio' />}
                            label={String(this.state.data[maxJob][maxDay]['is_all_in_one'].toLocaleString("ja-JP", {style: "currency", currency: "JPY"}))}
                          />
                        </td>
                    ))}
                    </tr>
                  ))}
                </table>
                </RadioGroup>
             </div>
             }
          </div>
          }
          <div className='select-plan-container-2 container-2-mobile' style={{
              font: "W4 17px/31px Hiragino Maru Gothic Pro",
              textAlign: 'left',
              color: "#555555",
              marginTop: 0,
            }}>
              ※購入後のプラン変更やキャンセルはできません。<br/>
              ※同じ企業様による複数のプランの同時購入はできません<br/>
              ※ロングプランはプランは一括払いとなります。<br/>
              ※請求書払いで審査不通過となった場合はご利用できません。クレジットカードでのお支払いをご検討ください。
            </div>

            <br/>

            <div className='select-plan-container-2'>
              <div className='transactions-button-container'>
                <button
                  className='section-link'
                  onClick={(e) => this.handlePlanSelected()}
                >
                  プランを購入する
                </button>
              </div>

              <div className='transactions-button-container'>
                <button
                  className='section-link'
                  onClick={(e) => this.props.history.push("/dashboard/transaction-history")}
                >
                  {DashboardTranslations.billing_page_transactions_redirect_button_text}
                </button>
              </div>
            </div>
          <br/>
          <div className='billing-body-container-2'>
            <div className='billing-heading-text'>
              プラン詳細
            </div>
            <br/>
            <div>
              <img src={JobTypeImage} alt='Job Type Info' style={{width: '100%'}} />
            </div>

          </div>

          <div
            className='select-plan-container-2 container-2-mobile'
            style={{
              paddingBottom: '20px',
              textAlign: 'justify',
              fontSize: '14px'
            }}
          >
            ♢<span style={{fontWeight: '500'}}>掲載について</span>
             <br/>
            　※１記事に「募集職種＋雇用形態＋勤務地」が各一つずつ掲載できます。
                <br/>
            　　例１）正社員とアルバイトを募集する場合は2記事となります。
                <br/>
            　　例２）同じ勤務地でも、皿洗い・ウエイターなど２職種の募集する場合は2記事となります。
              <br/>
            　※プラン間の記事の流用はできません。
            {/*  <br/>*/}
            {/*　※2つのプランの同時掲載はできませんが、掲載中に別プランの購入はできます。*/}

            <br/>
            <br/>
            ♢<span style={{fontWeight: '500'}}>お支払い方法</span>
                <br/>
            　　お支払はクレジットカードまたは請求書払いがあります。
                <br/>
            　　クレジットカード払なら即掲載開始できます。請求書払いの場合はご利用にあたり審査が必要です。
                <br/>
                <br/>
            ♢<span style={{fontWeight: '500'}}>その他の注意事項</span>
              <br/>
              <div style={{marginLeft: '13px'}}>
                <div>※料金は一括払いとなります。</div>
                <div>※購入したプランの変更やキャンセルはできません。</div>
                <div>※WORK JAPANは求人サイトであり、適性や能力等を保証するものではありません。</div>
                <div style={{marginLeft: '13px'}}>また、紹介フィーをいただく事はありません。</div>
              </div>
          </div>

          <SelectPlanWarningModal
              handleClose={this.handleClose.bind(this)}
              warningAccepted={this.warningAccepted.bind(this)}
              showSuccess={this.state.showSuccess}
          />

        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    language: state.language,
    token: state.user.token
  }
};

SelectPlan = connect(mapStateToProps)(SelectPlan);
export default withStyles(useStyles)(SelectPlan)
