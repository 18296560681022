import React, { Component } from 'react';
// import { connect } from 'react-redux';
import DashboardTranslations from '../../DashboardTranslations.js';
import FontAwesomeIcon from 'react-fontawesome'
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmCancelInterviewModal from '../components/ConfirmCancelInterviewModal'
import ConfirmModal from '../components/ConfirmModal'
import SuccessModal from '../components/SuccessModal'
import SeekerMessageModal from '../components/SeekerMessageModal'
import { sendOnlineIVInfoToSeeker } from '../helperFunctions/dataServices.js'
import close from '../../images/svg/close.svg'
import "react-toggle-switch/dist/css/switch.min.css"
import './styles/applicantCell.scss';
import {getErrorMessage} from "../../messages/errors";
import {getTooltipMessage} from "../../messages/tooltips";

var hiredStatus = {
  "SL": DashboardTranslations.jobs_page_dropdown_hired_text,
  "RJ": DashboardTranslations.jobs_page_dropdown_rejected_text,
  "AB": DashboardTranslations.jobs_page_dropdown_absent_text,
  "RS": DashboardTranslations.jobs_page_dropdown_rejected_by_seeker_text,
}

class ApplicantCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiredStatus: "",
      seekerFeedback: "",
      showCancelInterviewConfirmation: false,
      submitted: false,
      showOutcomeModal: false,
      showRequestNewSlotConfirmation: false,
      showMessageModal: false,
      successMessage: ""
    }
  }

  componentDidMount() {
    this.setState({
      seekerFeedback: this.props.application.feedback_by_employer ? this.props.application.feedback_by_employer : ""
    })
  }

  closeRequestNewSlotConfirmation() {
    this.setState({showRequestNewSlotConfirmation: false, showMessageModal: false,})
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  sendFeedback() {
    var payload = {}
    if (this.state.seekerFeedback !== "") {
      payload.feedback_by_employer = this.state.seekerFeedback
    }
    this.props.updateApplicantStatus(payload, this.props.application.id)
    this.setState({showOutcomeModal: false})
  }

  cancelInterview(payload) {
    this.props.updateApplicantStatus(payload, this.props.application.id)
    this.setState({showCancelInterviewConfirmation: false})
  }

  convert(timestamp) {
    var date = new Date(timestamp*1000);
    var cDate = date.getDate();
    var cMonth = date.getMonth();
    var cYear = date.getFullYear();

    if (timestamp !== null) {
      return [
        cYear,
        "年",
        cMonth+1,
        "月",
        cDate,
        "日"
      ].join('');                                  // Glue the pieces together
    }
    else {
      return "";
    }
  }

  convertTime(timestamp) {
    var date = new Date(timestamp*1000);
    var cHours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var cMinutes = date.getMinutes() === 0 ? "00" : date.getMinutes() ;
    var AMPM = "PM"
    if (cHours < 12) {
      AMPM = "AM"
    }

    if (timestamp !== null) {
      return [
        cHours,
        ":",
        cMinutes,
        AMPM
      ].join('');                                  // Glue the pieces together
    }
    else {
      return "";
    }
  }

  diffHours(dt2, dt1) {
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.round(diff);
  }

  closeConfirmation() {
    this.setState({showCancelInterviewConfirmation: false})
  }

  requestUpdateSlot() {
    this.props.requestUpdateSlot(this.props.application.id)
    this.setState({showRequestNewSlotConfirmation: false})
  }

  messageSeeker(payload) {
    sendOnlineIVInfoToSeeker(payload, this.props.application.id).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({showMessageModal: false, loading: false}, () => {
          this.setState({showSuccess: true, successMessage: "メッセージが送信されました。"})
        })
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  handleSuccessClose = () => {
    this.setState({ showSuccess: false, successMessage: '' });
  };


  render() {
    // var { application } = this.props.application
    var applicant_profile = {}
    var currentDateTime = new Date()
    var interviewDateTime = this.props.application && this.props.application.interview_start_timestamp ? new Date(this.props.application.interview_start_timestamp*1000) : new Date()
    var diffHours = this.diffHours(interviewDateTime, currentDateTime)
    if (this.props.application !== undefined) {
      applicant_profile = this.props.application.applicant_profile
    }
    var interviewDate = this.convert(this.props.application.interview_start_timestamp)
    var interviewTime = this.convertTime(this.props.application.interview_start_timestamp)
    var appliedDate = this.convert(this.props.application.applied_at_timestamp)
    var appliedTime = this.convertTime(this.props.application.applied_at_timestamp)
    var actionButton = <div className="action-button-none" />
    var cancelBySeekerButton = <div className="action-button-none" />
    var actionText = ""
    var contactNumber = ""
    var contactNumberType = "string"
    var contactNumberHref
    var sideColor = "#007AFF"
    var showMessageIcon = false
    var mobileNumberMessage
    if (this.props.cancelled === true) {
      sideColor = "#000000"
      actionButton =
        <div className="action-button-none" />
      actionText = DashboardTranslations.jobs_page_cancelled_by_applicant_text
    }
    else {
      if (this.props.application.status === "IF") {
        showMessageIcon = true
        mobileNumberMessage = "面接の60分前になるとここに応募者の電話番号を表示します"
        sideColor = "#007AFF"
        contactNumberType = "integer"
        contactNumber = applicant_profile.contact_num
        if (contactNumber && contactNumber.indexOf('+81-') >= 0) {
          contactNumber = contactNumber.replace('+81-', '0')
        }
        contactNumberHref = "tel:" + contactNumber

        if (diffHours < 1) {
          var href = <a href={contactNumberHref} style={{fontSize: contactNumberType === "string" ? 10 : 17}}>{contactNumber}</a>
          // mobileNumberMessage = "応募者の電話番号は" + {href} + "です"
        }
        if (diffHours > 48) {
          actionButton =
            <Tooltip title={getTooltipMessage('jobs_page_new_slot_request_by_employer_tooltip_text')} placement="top">
              <div className="btn btn-primary btn-action btn-dimension special-buttons" style={{backgroundColor: "gray", borderColor: "gray"}} onClick={() => this.setState({showRequestNewSlotConfirmation: true})}>
                {DashboardTranslations.jobs_page_reschedule_interview_button}
              </div>
            </Tooltip>
        }

        actionText = DashboardTranslations.jobs_page_fixed_interview_text
        if (diffHours < 48) {
          actionText = DashboardTranslations.jobs_page_fixed_interview_2_days_left_text
        }
        else if (diffHours < 1) {
          actionText = DashboardTranslations.jobs_page_fixed_interview_1_hour_left_text
        }
        cancelBySeekerButton =
          <Tooltip title={getTooltipMessage('jobs_page_cancelled_by_employer_tooltip_text')} placement="top">
            <div className="btn btn-danger btn-dimension special-buttons" style={{backgroundColor: "gray", borderColor: "gray"}} onClick={() => this.setState({showCancelInterviewConfirmation: true})}>
              {DashboardTranslations.jobs_page_cancel_interview_button_text}
            </div>
          </Tooltip>

      }
      else if (this.props.application.status === "RJ") {
        sideColor = "#9FCF6F"
        actionButton =
          <div className="action-button-none" />
        actionText = DashboardTranslations.jobs_page_dropdown_rejected_by_seeker_text
      }
      else if (this.props.application.status === "NSRS") {
        sideColor = "#ED5356"
        actionButton =
          <Tooltip title={getTooltipMessage('add_new_slot_tooltip_text')} placement="top">
            <div className="btn btn-primary btn-action btn-dimension special-buttons" style={{fontSize: 12, backgroundColor: "green", borderColor: "green"}} onClick={() => this.props.history.push('/dashboard/add-interview-times/'+this.props.jobId)}>
              {DashboardTranslations.jobs_page_add_interview_times_button_text}
            </div>
          </Tooltip>
        actionText = DashboardTranslations.jobs_page_requesting_interview_times_text
        cancelBySeekerButton =
          <Tooltip title={getTooltipMessage('jobs_page_cancelled_by_employer_tooltip_text')} placement="top">
            <div className="btn btn-danger btn-dimension special-buttons" onClick={() => this.setState({showCancelInterviewConfirmation: true})}>
              {DashboardTranslations.jobs_page_cancel_interview_button_text}
            </div>
          </Tooltip>
      }
      else if (this.props.application.status === "CBE") {
        mobileNumberMessage = ""
        sideColor = "#007AFF"
        actionText = DashboardTranslations.jobs_page_cancelled_by_employer_text
      }
      else if (this.props.application.status === "NSAE") {
        sideColor = "#ED5356"
        actionButton =
          <div className="action-button-none" />
        actionText = DashboardTranslations.jobs_page_time_request_accepted_text
      }
      else if (this.props.application.status === "NSRE") {
        mobileNumberMessage = ""
        sideColor = "#F5A623"
        actionButton =
          <div className="action-button-none" />
        actionText = DashboardTranslations.jobs_page_reschedule_requested_text
      }
      else if (this.props.application.status === "SL" || this.props.application.status === "AB" || this.props.application.status === "AR") {
        sideColor = "#9FCF6F"
        mobileNumberMessage = "面接の60分前になるとここに応募者の電話番号を表示します"
        contactNumberType = "integer"
        contactNumber = applicant_profile.contact_num
        if (contactNumber && contactNumber.indexOf('+81-') >= 0) {
          contactNumber = contactNumber.replace('+81-', '0')
        }
        contactNumberHref = "tel:" + contactNumber

        if (diffHours < 1) {
          // mobileNumberMessage = "応募者の電話番号は" + <a href={contactNumberHref} style={{fontSize: contactNumberType === "string" ? 10 : 17}}>{contactNumber}</a> + "です"
        }

        actionButton =
          <button className="btn btn-primary btn-action btn-dimension special-buttons" style={{margin: "0px auto", backgroundColor: "#EA696C", borderColor: "#EA696C"}} onClick={() => this.setState({showOutcomeModal: true})}>
            {this.props.application.status === "AR" ? DashboardTranslations.jobs_page_applicant_cell_mark_outcome_button_text : "面接の結果を見る"}
          </button>
        actionText = DashboardTranslations.jobs_page_completed_text
        if (this.state.hiredStatus !== "") {
          actionText = hiredStatus[this.state.hiredStatus]
        }
      }
    }

    if (applicant_profile && applicant_profile.contact_num) {
      contactNumberType = "integer"
      contactNumber = applicant_profile.contact_num
      if (contactNumber && contactNumber.indexOf('+81-') >= 0) {
        contactNumber = contactNumber.replace('+81-', '0')
      }
      contactNumberHref = "tel:" + contactNumber
    }

    var gender = "male"
    if (applicant_profile && applicant_profile.gender === "F") {
      gender = "female"
    }

    var age
    if (gender === "male" && applicant_profile && applicant_profile.age) {
      age = applicant_profile.age + "歳　男性"
    }
    else {
      age = applicant_profile.age + "歳　女性"
    }


    return (
      <div className="applicant-cell-container">
        <div className="left-block">
          <div className="profile-pic"
            style={{cursor: this.props.cancelled ? "default" : "pointer"}}
            onClick={() => !this.props.cancelled && this.props.history.push('/dashboard/applicant-details/'+this.props.application.id)}>
            {!this.props.cancelled && applicant_profile && applicant_profile.profile_image && <img alt="profile" src={applicant_profile ? applicant_profile.profile_image : ""} />}
            {!this.props.cancelled && applicant_profile && applicant_profile.profile_image === null &&
              <FontAwesomeIcon
                style={{fontSize: 100, color: "lightGray"}}
                name="user"/>
            }
            {this.props.cancelled &&
              <FontAwesomeIcon
                style={{fontSize: 100, color: "lightGray"}}
                name="user"/>
            }
          </div>
        </div>
        <div className="right-block">
          <div className="status-message">
            <div className="message">
              {actionText}
            </div>
          </div>
          <div className="action-details-container">
            <div className="seeker-details">
              <div className="name-container">
                <div className="name-inner" style={{height: 40}}>
                  <div className="full-name"
                    style={{
                      color: this.props.cancelled ? "#9B9B9B" : "#007AFF", cursor: this.props.cancelled ? "default" : "pointer",
                      textDecoration: this.props.cancelled ? "line-through" : "none"
                    }}
                    onClick={() => !this.props.cancelled && this.props.history.push('/dashboard/applicant-details/'+this.props.application.id)}>
                    {applicant_profile && applicant_profile.last_name} { applicant_profile && applicant_profile.first_name}
                  </div>
                  <div className="age-gender-container">
                    (&nbsp;{age})
                  </div>
                </div>
              </div>
              <div className="date-container">
                {this.props.application.status !== "CBE" && this.props.application.status !== "NSRE" && this.props.application.interview_start_timestamp && <div className="date" style={{color: "#000000", fontWeight: "bold"}}>
                  <div>面接日時：{interviewDate}<span style={{marginLeft: 10}}>{interviewTime}</span></div>
                </div>}
                <div className="date">
                  応募日：{appliedDate}<span style={{marginLeft: 10}}>{appliedTime}</span>
                </div>
              </div>
              {(contactNumber && (this.props.application.status === "IF" && diffHours < 1) || this.props.application.status === "RJ" || this.props.application.status === "SL" || this.props.application.status === "AR" || this.props.application.status === "AB") &&
                <div className="mobile-number-message">
                  応募者の電話番号は <a href={contactNumberHref} style={{backgroundColor: "#ea696c", borderRadius: 9, padding: "0px 3px", color: "#FFFFFF", fontSize: contactNumberType === "string" ? 10 : 13}}>{contactNumber}</a> です
              </div>}
              {(!this.props.cancelled && !contactNumber && ((this.props.application.status === "IF" && diffHours > 1) && this.props.application.status !== "RJ" && this.props.application.status !== "SL" && this.props.application.status !== "AR" && this.props.application.status !== "AB")) &&
                <div className="mobile-number-message">
                  面接の60分前になるとここに応募者の電話番号を表示します
              </div>}
            </div>
            <div className="action-button-container">
              {showMessageIcon && <div className="single-action-button">
                { <button onClick={() => this.setState({showMessageModal: true})}
                  name="sms" className="btn btn-danger" style={{fontSize: 14, width: 180, minWidth: 180, padding: 0}}>応募者にメッセージを送る</button>}
              </div>}
              <div className="single-action-button">
                {actionButton}
              </div>
              <div className="single-action-button">
                {((diffHours > 48 && this.props.application.status === "IF") || this.props.application.status === "NSRS") && cancelBySeekerButton}
              </div>
            </div>
          </div>
        </div>
        <ConfirmCancelInterviewModal confirmAction={this.cancelInterview.bind(this)}
          confirmationText={DashboardTranslations.jobs_page_cancel_interview_confirmation_text}
          handleClose={this.closeConfirmation.bind(this)}
          employerCancelReasons={this.props.jobsOptions.employer_cancel_reasons}
          showConfirmationModal={this.state.showCancelInterviewConfirmation}/>
        <Modal
          open={this.state.showOutcomeModal}
          onClose={() => this.setState({showOutcomeModal: false})}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="outcome-modal-container">
            <div className="outcome-modal-inner">
              <div className="responsive-header">
                <div className="login-header-text">
                  {DashboardTranslations.jobs_page_mark_outcome_modal_header}
                </div>
                <div className="burger" onClick={() => this.setState({showOutcomeModal: false})}>
                  <img src={close} alt="close"/>
                </div>
              </div>
              <form className="form">
                <div className="mark-outome-label">{DashboardTranslations.jobs_page_mark_outcome_field_label}</div>
                <select name="hiredStatus" value={this.state.hiredStatus} onChange={(e) => this.handleChange(e)}>
                  <option value="" >{DashboardTranslations.jobs_page_dropdown_mark_outcome_text}</option>
                  <option value="SL" >{DashboardTranslations.jobs_page_dropdown_hired_text}</option>
                  <option value="RJ" >{DashboardTranslations.jobs_page_dropdown_rejected_text}</option>
                  <option value="AB" >{DashboardTranslations.jobs_page_dropdown_absent_text}</option>
                  <option value="RS" >{DashboardTranslations.jobs_page_dropdown_rejected_by_seeker_text}</option>
                </select>
                <textarea rows="4" cols="20" value={this.state.seekerFeedback}
                  onChange={(event) => this.onChange(event)}
                  name="seekerFeedback"
                  placeholder={DashboardTranslations.jobs_page_feedback_about_seeker_placeholder}>
                </textarea>
                <div className="button-container">
                  <button className="btn btn-primary" onClick={() => this.sendFeedback()}>
                    {this.props.application && this.props.application.has_provided_feedback ? DashboardTranslations.jobs_page_edit_feedback_button_text: DashboardTranslations.jobs_page_add_feedback_button_text}
                  </button>
                  <button className="btn btn-secondary" onClick={() => this.setState({showOutcomeModal: false})}>
                    {DashboardTranslations.modal_cancel_button_text}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <ConfirmModal confirmAction={this.requestUpdateSlot.bind(this)}
          confirmationText={DashboardTranslations.jobs_page_request_new_slot_confirm_modal_confirmation_message}
          handleClose={this.closeRequestNewSlotConfirmation.bind(this)}
          headerText={DashboardTranslations.jobs_page_request_new_slot_confirm_modal_header_text}
          showConfirmationModal={this.state.showRequestNewSlotConfirmation}/>
        <SeekerMessageModal confirmAction={this.messageSeeker.bind(this)}
          confirmationText={DashboardTranslations.jobs_page_request_new_slot_confirm_modal_confirmation_message}
          handleClose={this.closeRequestNewSlotConfirmation.bind(this)}
          nearestStation={this.props.nearestStation}
          headerText={"応募者にメッセージを送る"}
          showMessageModal={this.state.showMessageModal}/>
        <SuccessModal successMessage={this.state.successMessage} handleClose={this.handleSuccessClose.bind(this)} showSuccess={this.state.showSuccess}/>
      </div>
    );
  }
}

export default ApplicantCell;
