const toASCII = (chars) => {
  var ascii = '';
  for(var i=0, l=chars.length; i<l; i++) {
      var c = chars[i].charCodeAt(0);

      // make sure we only convert half-full width char
      if (c >= 0xFF00 && c <= 0xFFEF) {
         c = 0xFF & (c + 0x20);
      }

      ascii += String.fromCharCode(c);
  }

  return ascii;
}


export default toASCII;
