import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import { loginSuccess, setRegisterStep } from '../../actions';
import { connect } from 'react-redux';
import { loginAPI } from '../helperFunctions/dataServices.js'
import '../styles/header.scss';
import ErrorModal from './ErrorModal'
import InputBox from './InputBox.js'
import DashboardTranslations from '../../DashboardTranslations.js';
import mainImage from '../../images/main-image.png'
import close from '../../images/svg/close.svg'
import {getErrorMessage} from "../../messages/errors";
const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var passwordRegex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[1234567890]).{8,}$/)

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailLogin: "",
      passwordLogin: "",
      openLoginDrawer: false,
      errorEnLogin: null,
      errorJpLogin: null,
      submitted: false,
      showError: false,
      error: '',
    };
    this.handleInputChange=this.handleInputChange.bind(this);
  }

  componentDidMount() {
    // if (this.props.isLoggedIn) {
    //   this.props.history.push('/dashboard/jobs')
    // }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleLogin(event) {
    this.setState({submitted: true})
    if (!this.state.emailLogin) {
        this.setState({
            errorEnLogin: 'Email not provided.',
            error: 'メールアドレスは必須項目です',
            // showError: true,
        });
        return;
    }

    // Validate Email
    if (this.state.emailLogin) {
        const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailFormat.test(this.state.emailLogin)) {
            this.setState({
                errorEnLogin: 'Email is in incorrect format.',
                error: '正しいメールアドレスを入力してください',
                // showError: true,
            });
            return;
        }
    }

    if (!this.state.passwordLogin) {
        this.setState({
            errorEnLogin: 'Password not provided.',
            error: 'パスワードは必須項目です',
            // showError: true,
        });
        return;
    }

    const payload = {
        email: this.state.emailLogin,
        password: this.state.passwordLogin
    };
    this.setState({loading: true});
    loginAPI(payload).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({openLoginDrawer: false})
        this.props.dispatch(setRegisterStep(response.data.register_stage));
        this.props.dispatch(loginSuccess(response.data));
        this.props.history.push('/dashboard')
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
    event.preventDefault();
  }

  handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      var displayName = this.state.displayName
      if (name === 'firstName' || name === 'lastName') {
        if (name === 'firstName') {
          displayName = this.state.lastName + value
        }
        else if (name === 'lastName') {
          displayName = value + this.state.firstName
        }
        this.setState({
          displayName: displayName
        })
      }

      this.setState({
          [name]: value
      });
  }

  handleClose = () => {
    this.setState({ showError: false, error: '' });
  };


  render() {

    var { error, showError, emailLogin, passwordLogin, submitted } = this.state

    var display = "block"
    if (this.props.isLoggedIn || this.props.history.location.pathname === "/job-list") {
      display = "none"
    }

    return (
      <div className="header-container" style={{display: display}}>
        <header className="header">
          <div className='header-title'>外国人求人サイト</div>
          <div className="inner-container">
            <div className="link">
              <a href="https://www.workjapan.jp/job-site-japan/" target="_blank" rel="noopener noreferrer">
                <img className="main-image" alt="header" src={mainImage}/>
              </a>
            </div>
          </div>
        </header>
        <Modal anchor="top" open={this.state.openLoginDrawer} onClose={() => this.setState({openLoginDrawer: false})}>
          <div className="login-modal-container">
            <div className="login-modal-inner">
              <div className="responsive-header">
                <div className="login-header-text">
                  {DashboardTranslations.login_button_text}
                </div>
                <div className="burger" onClick={() => this.setState({openLoginDrawer: false})}>
                  <img src={close} alt="close"/>
                </div>
              </div>
              <div className="login-inner-modal">
                <form>
                  <InputBox
                    value={emailLogin}
                    errorMessage={DashboardTranslations.register_page_email_error_message}
                    error={submitted === true && !emailFormat.test(emailLogin) ? true : false}
                    label={DashboardTranslations.register_page_email_label}
                    name="emailLogin"
                    handleChange={this.handleInputChange.bind(this)}
                    className="single-field"
                  />
                  <InputBox
                    value={passwordLogin}
                    errorMessage={DashboardTranslations.register_page_password_error_message}
                    error={submitted === true && !passwordRegex.test(passwordLogin) ? true : false}
                    label={DashboardTranslations.register_page_password_label}
                    name="passwordLogin"
                    inputType="password"
                    handleChange={this.handleChange.bind(this)}
                    className="single-field"
                  />
                  <div className="submit-button-container">
                    <div className="btn btn-primary" onClick={(event) => this.handleLogin(event)}>
                      ログイン
                    </div>
                    <div className="forgot-password-text">パスワードを忘れた方は <a style={{textDecoration: "none"}} href="/forgot-password">こちらから</a> 再設定 </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
        <ErrorModal error={error} handleClose={this.handleClose.bind(this)} showError={showError}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token
    }
};

Header = connect(mapStateToProps)(Header);


export default Header;
