import React, { Component } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Sidebar from './Sidebar.js';
import InfoModalAfterRegistration from './InfoModalAfterRegistration.js'
import InfoModalOutOfSubscription from './InfoModalOutOfSubscription.js'
import axios from "axios"
import { API_ROOT } from '../../constants';
import InitialCompanyDetails from '../InitialCompanyDetails/InitialCompanyDetails'
import { notificationDataAPI, notificationReadAPI } from '../helperFunctions/dataServices.js'
import FontAwesomeIcon from 'react-fontawesome'
import '../styles/innerHeader.scss';
import {getErrorMessage} from "../../messages/errors";

class InnerHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showSidebar: false,
      subscriptionStatus: "",
      subscriptionData: [],
      notificationData: [],
      isPopoverOpen: true,
      showCompanyDetailsModal: false,
      showInfoModalAfterRegsitration: false,
      showInfoModalOutOfSubscription: false,
    }
  }

  componentDidMount() {
    this.subscription()
    this.getNotifications()
    this.notificationData()
    if (this.props.registerStep === "null" || this.props.registerStep === null) {
      this.setState({showCompanyDetailsModal: true})
    }
  }

  closeCompanyDetailsModal() {
    this.setState({showCompanyDetailsModal: false, showInfoModalAfterRegsitration: true})
  }

  closeInfoModalAfterRegistration() {
    this.setState({showInfoModalAfterRegsitration: false}, () => window.location.href = "/dashboard/select-job-type")
  }


  closeInfoModalOutOfSubscription() {
    this.setState({showInfoModalOutOfSubscription: false})
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  closeDrawer() {
    this.setState({showSidebar: false})
  }

  redirect() {
    window.location.href = "/dashboard/notifications";
  }

  notificationData() {
    this.setState({loading: true})
    notificationDataAPI().then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        this.setState({notificationData: response.data, loading: false})
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  notificationRead(id) {
    var payload = {
      is_read: true
    }
    notificationReadAPI(payload, id).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({loading: false, showSuccess: true}, () => this.redirect())
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  async getNotifications() {
    var headers = {
        "Content-Type": "application/json",
        "Authorization": "Token "+ this.props.token
    }
    this.setState({loading: true, showCancelSubscription: false, cancelSubscriptionConfirmationText: ""})
    await axios.get(API_ROOT + 'api/v3/employer/subscription_status/', {headers: headers})
    .then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        this.setState({subscriptionStatus: response.data.subscription_status}, () => this.checkToShowOutOfSubscription())
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((err) => {
      this.setState({loading: false})
    })
  }

  checkToShowOutOfSubscription() {
    const isShown = sessionStorage.getItem('showInfoModalOutOfSubscription')
    if (this.props.history && this.props.history.location && isShown !== 'true') {
      if (this.props.history.location.pathname.indexOf("dashboard/billing") >= 0 && this.props.history.location.pathname.indexOf("terms") < 0) {
        if (
            this.state.subscriptionStatus !== "IS"
            && this.props.history.location.pathname !== '/dashboard/billing/select-plan'
            && this.props.history.location.pathname !== '/dashboard/billing/select-payment-method'
            && this.props.history.location.pathname !== '/dashboard/billing/confirm-purchase'
            && this.props.history.location.pathname !== '/dashboard/billing/transaction-success'
        ) {
          sessionStorage.setItem('showInfoModalOutOfSubscription', 'true')
          this.setState({showInfoModalOutOfSubscription: true})
        }
      }
    }
  }

  async subscription() {
    var headers = {
        "Content-Type": "application/json",
        "Authorization": "Token "+ this.props.token
    }
    this.setState({loading: true})
    await axios.get(API_ROOT + 'api/v3/employer/subscription/', {headers: headers})
    .then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        this.setState({loading: false, subscriptionData: response.data})
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((err) => {
      this.setState({loading: false})
      if (err.response.status === 403) {
        this.setState({isAdmin: false})
      }
    })
  }


  render() {

    var { primary } = this.props
    var { notificationData, showCompanyDetailsModal, showInfoModalAfterRegsitration, showInfoModalOutOfSubscription } = this.state

    var count = 0
    if (notificationData.length > 0) {
      for (var i = 0; i < notificationData.length; i++) {
        if (notificationData[i].is_read === false) {
          count ++
        }
      }
    }

    return (
      <div className="main-inner-header-container">
        <div className="inner-header-container">
          { primary &&
            <FontAwesomeIcon onClick={() => this.setState({showSidebar: true})}
              name="bars" className="sidebar-icon"/>

          }
          { !primary &&
            <FontAwesomeIcon onClick={() => this.props.backArrowOnClick()}
              name="chevron-left" className="icon"/>
          }
          <div className="header-text">
            {this.props.headerText}
          </div>
        </div>
        {/*<div className="header-mobile-number-message">*/}
        {/*  【お知らせ】<br />*/}
        {/*  新型コロナウイルスの影響による対面での面接対応が難しい場合を考慮して、<br />*/}
        {/*  応募者の電話番号を面接日確定と同時に表示いたします。*/}
        {/*</div>*/}
        {showCompanyDetailsModal && <InitialCompanyDetails closeModal={this.closeCompanyDetailsModal.bind(this)}/>}
        <Drawer classes={{ paper: "drawer-styles" }} anchor="left" style={{width: "100%", height: "100%"}} open={this.state.showSidebar} onClose={() => this.setState({showSidebar: false})}>
          <div style={{width: "100%", height: "100%"}} tabIndex={-1}>
            <Sidebar
              history={this.props.history}
              closeDrawer={this.closeDrawer.bind(this)}/>
          </div>
        </Drawer>
        {showInfoModalAfterRegsitration && <InfoModalAfterRegistration closeInfoModalAfterRegistration={this.closeInfoModalAfterRegistration.bind(this)}/>}
        {showInfoModalOutOfSubscription && <InfoModalOutOfSubscription closeInfoModalOutOfSubscription={this.closeInfoModalOutOfSubscription.bind(this)}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token,
        registerStep: state.user.registerStep
    }
};

InnerHeader = connect(mapStateToProps)(InnerHeader);

export default InnerHeader;
