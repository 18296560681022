var joyrideArray = localStorage.getItem("joyride") ? JSON.parse(localStorage.getItem("joyride")) : [];

var initial = {
  type: "COMPLETED_JOYRIDE",
  completed: joyrideArray && joyrideArray.completed ? joyrideArray.completed : []
}

function joyride(state = initial, action) {
    switch (action.type) {
        case 'COMPLETED_JOYRIDE':
            localStorage.setItem("joyride", JSON.stringify(action));
            return action;
        default:
            return state
    }
};

export default joyride;
