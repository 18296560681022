import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerHeader from '../components/InnerHeader.js'
import DashboardTranslations from '../../DashboardTranslations.js';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import 'react-overlay-loader/styles.css';
import './styles/selectPaymentMethod.scss';

const useStyles = theme => ({
  root: {
    height: 300,
    width: 400
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

class SelectPaymentMethod extends Component {
  backArrowOnClick() {
    let path = '/dashboard/billing/select-plan';
    let state = {
      subscription_status: this.props.location.state.subscription_status,
      is_cc_added_in_gmo: this.props.location.state.is_cc_added_in_gmo,
      verification_status: this.props.location.state.verification_status,
      paidBy: this.props.location.state.paidBy,
      currSubscriptionData: this.props.location.state.currSubscriptionData
    };
    if (this.props.location.state.backToSelectPreviousPaymentMethod) {
      path = '/dashboard/billing/select-previous-payment-method'
      state = {
        subscription_status: this.props.location.state.subscription_status,
        is_cc_added_in_gmo: this.props.location.state.is_cc_added_in_gmo,
        verification_status: this.props.location.state.verification_status,
        paidBy: this.props.location.state.paidBy,
        currSubscriptionData: this.props.location.state.currSubscriptionData,
        plan: this.props.location.state.plan,
        backToSelectPreviousPaymentMethod: this.props.location.state.backToSelectPreviousPaymentMethod
      }
    }

    this.props.history.push({
        pathname: path,
        state: state
      });
  }

  handlePaymentMethodSelected(method) {
    console.log('method: ', method)
    this.props.history.push({
      pathname: '/dashboard/billing/confirm-purchase', state: {
        subscription_status: this.props.location.state.subscription_status,
        is_cc_added_in_gmo: this.props.location.state.is_cc_added_in_gmo,
        verification_status: this.props.location.state.verification_status,
        plan: this.props.location.state.plan,
        paidBy: this.props.location.state.paidBy,
        paymentMethod: method,
        currSubscriptionData: this.props.location.state.currSubscriptionData,
        backToSelectPreviousPaymentMethod: this.props.location.state.backToSelectPreviousPaymentMethod
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
        <div className="transaction-history-page-container">
          <InnerHeader
              primary={false}
              history={this.props.history}
              headerText={DashboardTranslations.select_payment_method_page_header_text}
              backArrowOnClick={this.backArrowOnClick.bind(this)}
          />
          <table style={{margin: "0 auto"}}>
            <tbody>
            <tr>
              <td>
                <Card className={classes.root} variant="outlined" style={{border: "10px solid #197DFB"}}>
                  <CardContent>
                    <br/><br/>
                    <Typography variant="h5" component="h2">
                      クレジットカード払い
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      すぐに掲載できるのでオススメ！<br /><br />
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button variant="outlined" size="small" color="primary" className={classes.margin} style={{margin: "0 auto"}} onClick={() => this.handlePaymentMethodSelected('CC')}>
                      購入する
                    </Button>
                  </CardActions>
                </Card>
              </td>
              <td>
                <Card className={classes.root} variant="outlined" style={{border: "10px solid #197DFB"}}>
                  <CardContent>
                    <br/><br/>
                    <Typography variant="h5" component="h2">
                      請求書払い
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      審査が必要で最大5営業日かかります<br/>
                      審査通過日よりご利用開始となります。
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button variant="outlined" size="small" color="primary" className={classes.margin} style={{margin: "0 auto"}} onClick={() => this.handlePaymentMethodSelected('INV')}>
                      購入する
                    </Button>
                  </CardActions>
                </Card>
              </td>
            </tr>
            </tbody>
          </table>

          <br/><br/>

          <span style={{
            // textAlign: "left",
            // display: "block",
            font: "W4 17px/31px Hiragino Maru Gothic Pro",
            color: "#555555"
          }}>
            ※お支払方法の変更はできませんのでご注意ください。<br/>
            ※請求書払いでのご利用について<br/>
            当社は、株式会社ラクーンフィナンシャルに請求業務を委託しており、ご利用にあたり審査<br/>
            に関するに連絡が入る場合があります。審査不通過の場合はご利用できません。クレジット<br/>
            カードでのお支払いをご検討ください。
            </span>

        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    language: state.language,
    token: state.user.token
  }
};

SelectPaymentMethod = connect(mapStateToProps)(SelectPaymentMethod);
export default withStyles(useStyles)(SelectPaymentMethod)
