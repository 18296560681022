import React, {Component} from 'react';
import SelectDropdown from './SelectDropdown.js'
import '../styles/newShifts.scss'
import DashboardTranslations from '../../DashboardTranslations.js';
import { connect } from 'react-redux';
import daysArray from '../helperFunctions/daysArray.js'


var interviewTimes = ["00:00", "00:30","01:00", "01:30","02:00", "02:30","03:00", "03:30","04:00", "04:30","05:00", "05:30","06:00", "06:30",
  "07:00", "07:30","08:00", "08:30","09:00", "09:30","10:00", "10:30","11:00", "11:30","12:00", "12:30","13:00", "13:30","14:00", "14:30","15:00", "15:30",
  "16:00", "16:30","17:00", "17:30","18:00", "18:30","19:00", "19:30","20:00", "20:30","21:00", "21:30","22:00", "22:30","23:00",
  ]

class NewShift extends Component {

  handleChange = event => {
    this.props.handleChange(event)
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    var fromSelectOptions = interviewTimes.map((option, id) => {
      var options = {value: option, label: option}
      return options
    })

    var toSelectOptions = []
    toSelectOptions = interviewTimes.map((option, id) => {
      var options = {value: option, label: option}
      return options
    })

    var leaveErrorSpace = false
    if ((this.props.toValue === "" && this.props.addShiftsSubmitted) || (this.props.endTime === "" && this.props.addShiftsSubmitted)) {
      leaveErrorSpace = true
    }

    return (
      <div className="shift-container">
        {this.props.addShiftsSubmitted && this.props.selectedDays.length === 0 && <div className="days-error-message">{DashboardTranslations.add_job_select_day_error_message}</div>}
        <div className="days-container">
          {daysArray.days.map(day => {
            return (
              <div key={day.id} onClick={() => this.props.selectDay(day.id)}
               style={{backgroundColor: this.props.selectedDays.indexOf(day.id) > -1 ? '#263242' : '#FFFFFF',
               color: this.props.selectedDays.indexOf(day.id) > -1 ? '#FFFFFF' : '#000000',
               borderColor: this.props.selectedDays.indexOf(day.id) > -1 ? 'transparent' : '#000000'
               }}
               className="day">
                {this.props.language === "En" ? day.id : day.name}
              </div>
            )
          })}
        </div>
        <div className="time-container">
          <div className="time-inner">
            <SelectDropdown
              errorMessage={DashboardTranslations.add_job_start_time_error_message}
              label={DashboardTranslations.add_job_start_time_label}
              type="multiline"
              leaveErrorSpace={leaveErrorSpace}
              error={this.props.toValue === "" && this.props.addShiftsSubmitted}
              name="startTime"
              marginRight="10px"
              value={this.props.toValue}
              options={fromSelectOptions}
              handleChange={this.handleChange.bind(this)}
            />
            <SelectDropdown
              errorMessage={DashboardTranslations.add_job_end_time_error_message}
              label={DashboardTranslations.add_job_end_time_label}
              leaveErrorSpace={leaveErrorSpace}
              type="multiline"
              error={this.props.endTime === "" && this.props.addShiftsSubmitted}
              name="endTime"
              value={this.props.endTime}
              options={toSelectOptions}
              handleChange={this.handleChange.bind(this)}
            />
          </div>
          <div className="button">
            {this.props.addJobButton}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token
    }
};

NewShift = connect(mapStateToProps)(NewShift);

export default NewShift;
