import React, { Component } from 'react';
import { connect } from 'react-redux';

import InfoImage2 from '../../images/info-modal-image-2.png'
import './infoModalAfterFirstJobCreation.scss';


class InfoModalAfterFirstJobCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {

    return (
      <div className="info-modal-after-first-job-creation">
        <div className="modal-outer-container">
          <div className="modal-inner-container">
            <div className="header">
              面接時間の設定
            </div>

            <div className="sub-header">
              最初の記事の作成が完了しました！ <br />
              次は面接に対応できる時間を登録してください。 <br /><br />
              ワークジャパンでは候補者が応募をしたタイミングで面接が予約さ<br />
              れますので、前もってなるべく多くの面接に対応できる時間を面接<br />
              カレンダーに登録してください。<br /><br />
            </div>

            <img className="info-image-1" src={InfoImage2} />


            <div className="sub-header">
              ワークジャパンでは候補者が応募をした瞬間に面接の予約が完了し<br />
               ます。前もって面接に対応できる時間をなるべく多く登録して候補者 <br />
               の応募をお待ちください。（ご利用の有料サービスの終了日から10 <br />
               日先まで登録できます。） <br /><br />

               <div style={{color: "red"}}>対応できる時間の枠が少ないとせっかく応募したいと考えている候<br />
               補者を取り逃がしてしまいます。 <br /><br /></div>

                面接の時間はサービスの利用期限の10日先まで登録しましょう！<br />
               またサービスご利用中はいつでも追加で登録できますので、<br />
                最低でも30以上の枠を確保してください。 <br /><br />
            </div>

            <button style={{backgroundColor: "#FFFFFF", margin: "10px auto", fontSize: 17, borderRadius: "12px", width: "300px", border: "1px solid #007Aff", color: "#007AFF"}} onClick={() => this.props.closeInfoModalAfterFirstJobCreation()}>
              閉じる
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.user.isLoggedIn,
    }
};

InfoModalAfterFirstJobCreation = connect(mapStateToProps)(InfoModalAfterFirstJobCreation);

export default InfoModalAfterFirstJobCreation;
