import React from 'react';
import Modal from '@material-ui/core/Modal';
import DashboardTranslations from '../../DashboardTranslations.js';
import close from '../../images/svg/close.svg'
import '../styles/confirmModal.scss'


const ConfirmModal = (props) => (
    <div>
      <Modal
        open={props.showConfirmationModal}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{}}
      >
        <div className="confirm-modal">
          <div className="responsive-header" style={{backgroundColor: "#E3E3E3"}}>
            <div className="login-header-text">
              {props.headerText ? props.headerText : DashboardTranslations.confirm_modal_header_text}
            </div>
            <div className="burger" style={{border: "0.75px solid #0A57BF", borderRadius: "50%"}} 
              onClick={() => props.handleClose()}
            >
              <img src={close} alt="close"/>
            </div>
          </div>
          <div className="dialog-message-container">
            {props.warningText && props.warningText !== '' && 
              <div className='warning-text'>
                ーご注意くださいー<br/>
              面接直前の変更は応募者の混乱を招く可能性があります。<br/>
              メールや電話による個別フォローもご検討ください
              </div>
            }
            <div >
              <div className="confirmation-text">
                {props.confirmationText ? props.confirmationText : DashboardTranslations.confirm_modal_default_text}<br />
                {props.specialText ? props.specialText : ""}
              </div>
              <div className="button-container">
              <button className="btn btn-secondary" onClick={() => props.handleClose()}>{props.cancelButtonText ? props.cancelButtonText : DashboardTranslations.confirm_modal_cancel_button_text}</button>
                <button className="btn btn-primary" onClick={() => props.confirmAction()}>{props.confirmButtonText ? props.confirmButtonText : DashboardTranslations.confirm_modal_confirm_button_text}</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
);

export default ConfirmModal;
