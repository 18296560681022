import React, {Component} from 'react';
import InputBox from '../components/InputBox.js'
import SelectDropdown from '../components/SelectDropdown.js'
import {API_ROOT} from '../../constants';
import {connect} from 'react-redux';
import {
  getCitiesDetails,
  getPostalCode,
  getWardDetails,
  initialize,
  locations
} from '../helperFunctions/dataServices.js'
import Autosuggest from 'react-autosuggest';
import DashboardTranslations from '../../DashboardTranslations.js';
import './styles/addLocations.scss';
import {getTooltipMessage} from "../../messages/tooltips";

const renderSuggestion = suggestion => (
    <div>
        {suggestion.name_jp} ({suggestion.prefecture_name_jp})
    </div>
);


class AddInterviewLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      brandNameJp: "",
      brandNameEn: "",
      branchName: "",
      postalCode: "",
      prefecture: "",
      city1: "",
      city2: "",
      lotNo: "",
      buildingNameJp: "",
      buildingNameEn: "",
      nearestStation: "",
      commuteTime: "",
      commuteType: "",
      isFUEI: false,
      phoneNumber: "",
      postalCodeOptions: [],
      suggestions: [],
      value: '',
      jobStationId: '',
      locations: [],
      locationId: "",
      locationDetailsBrand: "",
      locationDetailsBranch: "",
      locationDetailsAddress: "",
      options: [],
      jobStationName: "",
      prefectureName: "",
      city1Name: "",
      city2Name: "",
    }
  }

  componentDidMount() {
    this.mounted = true;
    this.getAllLocations()
    this.initializeCompany()
    if (this.props.edit === true) {
      if (this.props.locationType === "work" && this.props.jobDetails) {
        var locationId = this.props.jobDetails.location.id
        var brandNameEn = this.props.jobDetails.location.brand_name_en
        var brandNameJp = this.props.jobDetails.location.brand_name_jp
        var branchName = this.props.jobDetails.location.branch
        var locationDetailsBrand = this.props.jobDetails.location.brand_name_jp
        var locationDetailsBranch = this.props.jobDetails.location.branch
        var locationDetailsAddress = this.props.jobDetails.location_address
        var buildingNameEn = this.props.jobDetails.location.building_name_en
        var buildingNameJp = this.props.jobDetails.location.building_name_jp
        var isFUEI = this.props.jobDetails.location.is_fuei
        var lotNo = this.props.jobDetails.location.lot_num
        var commuteType = this.props.jobDetails.location.mode
        var commuteTime = this.props.jobDetails.location.minutes
        var phoneNumber = this.props.jobDetails.location.telephone
        var jobStationId = this.props.jobDetails.location.nearest_station.id
        var jobStationName = this.props.jobDetails.location.nearest_station.name
        var prefecture = this.props.jobDetails.location.prefecture.id
        var prefectureName = this.props.jobDetails.location.prefecture.name
        var city1Name = this.props.jobDetails.location.city.name
        var city2Name = this.props.jobDetails.location.ward.name
        var city1 = this.props.jobDetails.location.city.id
        var city2 = this.props.jobDetails.location.ward.id
        var postalCode = this.props.jobDetails.location.postcode.code
        var postalCodeId = this.props.jobDetails.location.postcode.id
      }
      else if (
          this.props.jobDetails &&
          Object.keys(this.props.jobDetails).length > 0 &&
          this.props.jobDetails.interview_location
      ) {
        locationId = this.props.jobDetails.interview_location.id
        branchName = this.props.jobDetails.interview_location.branch
        brandNameEn = this.props.jobDetails.interview_location.brand_name_en
        brandNameJp = this.props.jobDetails.interview_location.brand_name_jp
        phoneNumber = this.props.jobDetails.interview_location.telephone
        locationDetailsBrand = this.props.jobDetails.interview_location.brand_name_jp
        locationDetailsBranch = this.props.jobDetails.interview_location.branch
        locationDetailsAddress = this.props.jobDetails.interview_location_address
        buildingNameEn = this.props.jobDetails.interview_location.building_name_en
        buildingNameJp = this.props.jobDetails.interview_location.building_name_jp
        isFUEI = this.props.jobDetails.interview_location.is_fuei
        lotNo = this.props.jobDetails.interview_location.lot_num
        commuteType = this.props.jobDetails.interview_location.mode
        commuteTime = this.props.jobDetails.interview_location.minutes
        phoneNumber = this.props.jobDetails.interview_location.telephone
        jobStationId = this.props.jobDetails.interview_location.nearest_station.id
        jobStationName = this.props.jobDetails.interview_location.nearest_station.name
        prefecture = this.props.jobDetails.interview_location.prefecture.id
        prefectureName = this.props.jobDetails.interview_location.prefecture.name
        city1Name = this.props.jobDetails.interview_location.city.name
        city2Name = this.props.jobDetails.interview_location.ward.name
        city1 = this.props.jobDetails.interview_location.city.id
        city2 = this.props.jobDetails.interview_location.ward.id
        postalCode = this.props.jobDetails.interview_location.postcode.code
        postalCodeId = this.props.jobDetails.interview_location.postcode.id
      }

      this.setState({
        locationId: locationId,
        branchName: branchName,
        locationDetailsBrand: locationDetailsBrand,
        locationDetailsBranch: locationDetailsBranch,
        locationDetailsAddress: locationDetailsAddress,
        brandNameEn: brandNameEn,
        brandNameJp: brandNameJp,
        buildingNameEn: buildingNameEn,
        buildingNameJp: buildingNameJp,
        isFUEI: isFUEI,
        lotNo: lotNo ? lotNo : '',
        commuteType: commuteType,
        commuteTime: commuteTime,
        phoneNumber: phoneNumber,
        jobStationId: jobStationId,
        jobStationName: jobStationName,
        value: jobStationName,
        prefecture: prefecture,
        city1: city1,
        city2: city2,
        prefectureName: prefectureName,
        city1Name: city1Name,
        city2Name: city2Name,
        postalCode: postalCode,
        postalCodeId: postalCodeId
      },() => this.sendPreviewJobData())
      this.props.setSelectedPrefecture(prefecture)
      this.getPostalCodeAdressEdit(postalCode)
    }
  }

  initializeCompany() {
    this.setState({loading: true})
    initialize().then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        this.setState({options: response.data})
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  getAllLocations() {
    this.setState({loading: true})
    locations().then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        this.setState({locations: response.data})
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  handleChange = event => {
    const value = event.target.value;
    if (event.target.name==="postalCode") {
      const re = /^[0-9\b]+$/;
      if ((value === '' || re.test(value)) && value.length <= 7) {
        this.setState({ [event.target.name]: event.target.value },() => this.sendPreviewJobData());
        if (value.length === 7) {
          this.getPostalCodeAdress(value, true)
        }
      }
    } else if (event.target.name==="lotNo") {
      const reValidLotNum = /^[0-9]+(-[0-9]+){0,2}$/;
      const reNumAndDash = /^[0-9-]*$/;

      if (value === '') {
        this.setState({ [event.target.name]: value },() => this.sendPreviewJobData());
      } else if (reNumAndDash.test(value)) {
        if (value.substr(value.length - 1) === '-') {
          const prevValue = this.state.lotNo;
          if (prevValue.substr(prevValue.length - 1) !== '-' && (value.match(/-/g) || []).length < 3) {
            this.setState({ [event.target.name]: value },() => this.sendPreviewJobData());
          }
        } else if (reValidLotNum.test(value)) {
          this.setState({ [event.target.name]: value },() => this.sendPreviewJobData());
        }
      }
    }
    else {
      this.setState({ [event.target.name]: event.target.value },() => this.sendPreviewJobData());
    }

    if (event.target.name === "prefecture") {
      this.props.setSelectedPrefecture(value)
      this.sendPreviewJobData()
    }
  }

  handleChooseLocation = event => {
    let value = event.target.value;
    this.setState({ locationId: event.target.value });
    let selectedLocation = {};
    this.state.locations.forEach((option, id) => {
      if (option.id === parseInt(value)) {
        selectedLocation = option
      }
    })
    if (Object.keys(selectedLocation).length > 0) {
      var branchName = selectedLocation.branch
      var brandNameEn = selectedLocation.brand_name_en
      var brandNameJp = selectedLocation.brand_name_jp
      var buildingNameEn = selectedLocation.building_name_en
      var buildingNameJp = selectedLocation.building_name_jp
      var isFUEI = selectedLocation.is_fuei
      var lotNo = selectedLocation.lot_num
      var commuteType = selectedLocation.mode
      var commuteTime = selectedLocation.minutes
      var phoneNumber = selectedLocation.telephone
      var jobStationId = selectedLocation.nearest_station.id
      var jobStationName = selectedLocation.nearest_station.name
      value = selectedLocation.nearest_station.name
      var prefecture = selectedLocation.prefecture.id
      var prefectureName = selectedLocation.prefecture.name
      var city1Name = selectedLocation.city.name
      var city2Name = selectedLocation.ward.name
      var city1 = selectedLocation.city.id
      var city2 = selectedLocation.ward.id
      var postalCode = selectedLocation.postcode.code
      var postalCodeId = selectedLocation.postcode.id
      this.setState({
        brandNameEn: brandNameEn,
        brandNameJp: brandNameJp,
        branchName: branchName,
        buildingNameEn: buildingNameEn,
        buildingNameJp: buildingNameJp,
        isFUEI: isFUEI,
        lotNo: lotNo ? lotNo : '',
        commuteType: commuteType,
        commuteTime: commuteTime,
        phoneNumber: phoneNumber,
        jobStationId: jobStationId,
        jobStationName: jobStationName,
        value: value,
        prefecture: prefecture,
        city1: city1,
        city2: city2,
        prefectureName: prefectureName,
        city1Name: city1Name,
        city2Name: city2Name,
        postalCode: postalCode,
        postalCodeId: postalCodeId
      },() => this.sendPreviewJobData())
      this.props.setSelectedPrefecture(prefecture)
      this.getPostalCodeAdressEdit(selectedLocation.postcode.code)
    }
    else {
      this.setState({
        brandNameJp: "",
        brandNameEn: "",
        branchName: "",
        postalCode: "",
        prefecture: "",
        city1: "",
        city2: "",
        lotNo: "",
        buildingNameJp: "",
        buildingNameEn: "",
        nearestStation: "",
        commuteTime: "",
        commuteType: "",
        isFUEI: false,
        phoneNumber: "",
        value: '',
        jobStationId: '',
      },() => this.sendPreviewJobData())
    }
  }

  getPostalCodeAdressEdit(value, refill) {
    this.setState({loading: true})
    var intValue = parseInt(value, 10)
    getPostalCode(intValue).then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        this.setState({postalCodeOptions: response.data})
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  encode_utf8(s) {
    return encodeURIComponent(s);
  }

  onSuggestionsFetchRequested = ({ value }) => {
			const trimmedValue = this.encode_utf8(value);

			let component = this;
			fetch(API_ROOT + `api/v2/main/utils/search_station/?web=1&query=${trimmedValue}`, {
					method: 'GET',
			}).then(function(response) {
					return response.json()
			}).then(function(json) {
					var result =  json.results
					component.setState({suggestions: result});
			}).catch(function(ex) {
					console.log('parsing failed', ex)
			});
	};

	onSuggestionsClearRequested = () => {
			this.setState({
					suggestions: [],
			});
	};

  getSuggestionValue = suggestion => {
      this.setState({
          jobStationId: suggestion.id,
          jobStationName: suggestion.name_jp
      });
      if (this.props.edit) {
        console.log(this.state);
      }
      return suggestion.name_jp;
  };

  onSuggestionSelected = (event, { suggestion }) => {
      this.setState({
          jobStationId: suggestion.id,
          jobStationName: suggestion.name_jp
      },() => this.sendPreviewJobData());
  };

  getPostalCodeAdress(value, refill) {
    this.setState({loading: true})
    var intValue = parseInt(value, 10)
    getPostalCode(intValue).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        var prefecture = ''
        var city1 = ''
        var city2 = ''
        var prefectureName = ""
        var city1Name = ""
        var city2Name = ""
        var postalCodeId = ''
        if (response.data) {
          prefecture = response.data.prefectures[0].id
          city1 = response.data.cities[0].id
          city2 = response.data.wards[0].id
          prefectureName = response.data.prefectures[0].name
          city1Name = response.data.cities[0].name
          city2Name = response.data.wards[0].name
          postalCodeId = response.data.postcode_id
        }
        this.setState({postalCodeOptions: response.data, postalCodeId: postalCodeId})
        if (refill) {
          this.setState({prefecture: prefecture, city1: city1, city2: city2, prefectureName: prefectureName, city1Name: city1Name, city2Name: city2Name}, () => this.props.setSelectedPrefecture(prefecture))
        }
      }
      else {
        this.setState({loading: false})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  getWards(value, refill) {
    this.setState({loading: true})
    var intValue = parseInt(value, 10)
    getWardDetails(intValue).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        var postalCodeOptions = this.state.postalCodeOptions
        postalCodeOptions.wards = response.data
        this.setState({postalCodeOptions: postalCodeOptions})
        if (refill) {
          this.setState({ city2: ''})
        }
      }
      else {
        this.setState({loading: false})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  getCities(value, refill) {
    this.setState({loading: true})
    var intValue = parseInt(value, 10)
    getCitiesDetails(intValue).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        var postalCodeOptions = this.state.postalCodeOptions
        postalCodeOptions.cities = response.data
        this.setState({postalCodeOptions: postalCodeOptions})
        if (refill) {
          this.setState({city1: '', city2: ''})
        }
      }
      else {
        this.setState({loading: false})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  onChange = (event, { newValue }) => {
      this.setState({
          value: newValue
      });
      if (newValue === "") {
        this.setState({jobStationId: ""})
      }
  };

  sendPreviewJobData() {
    var { jobStationName, postalCode, commuteTime, brandNameEn,
      brandNameJp, lotNo, buildingNameJp, city2Name, city1Name, prefectureName, phoneNumber
    } = this.state;

    var payload = {}
    payload.brand_name_jp = brandNameJp
    payload.brand_name_en = brandNameEn
    payload.prefecture = prefectureName
    payload.city = city1Name
    payload.ward = city2Name
    payload.postcode = postalCode
    payload.lot_num = lotNo
    payload.nearest_station = jobStationName
    payload.minutes = commuteTime
    payload.building_name_jp = buildingNameJp ? buildingNameJp : null
    payload.phoneNumber = phoneNumber;
    this.props.previewJobsLocation(payload, this.props.locationType)
  }

  sendData(sendFromDraft=false) {
    const {
      jobStationId, postalCode, isFUEI, commuteTime, phoneNumber, commuteType, brandNameJp, brandNameEn, branchName,
      lotNo, postalCodeId, buildingNameJp, buildingNameEn, city2, city1, prefecture, locationId
    } = this.state;

    if (locationId) {
      this.props.verifyLocation({locationId: locationId}, this.props.locationType, sendFromDraft);
    } else {
      var payload = {}
      if (this.props.edit && this.props.type !== "copy") {
        payload.branch = branchName
        payload.telephone = phoneNumber
        if (branchName !== "" && (phoneNumber.length === 10 || phoneNumber.length === 11)) {
          this.props.verifyLocation(payload, this.props.locationType, sendFromDraft)
        }
        else {
          this.props.scrollToMyRef()
        }
      }
      else {
        payload.brand_name_jp = brandNameJp
        payload.brand_name_en = brandNameEn
        payload.branch = branchName
        payload.prefecture = prefecture
        payload.city = city1
        payload.ward = city2
        payload.postcode = postalCodeId
        payload.lot_num = lotNo
        payload.nearest_station = jobStationId
        payload.mode = commuteType
        payload.minutes = commuteTime
        payload.is_fuei = isFUEI
        payload.telephone = phoneNumber
        payload.building_name_jp = buildingNameJp ? buildingNameJp : null
        payload.building_name_en = buildingNameEn ? buildingNameEn : null
        if (brandNameEn !== "" && brandNameJp !== "" && branchName !== "" && postalCode !== "" &&
            jobStationId !== "" && prefecture !== "" && city1 !== "" && city2 !== "" && lotNo !== "" &&
            commuteType !== "" && commuteTime !== "" && phoneNumber !== "" && (phoneNumber.length === 10 || phoneNumber.length === 11)) {
          this.props.verifyLocation(payload, this.props.locationType, sendFromDraft)
        }
        else {
          if (sendFromDraft) {
            this.props.verifyLocation(payload, this.props.locationType, sendFromDraft)
            return;
          }
          this.props.scrollToMyRef()
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.draftDataCollection !== this.props.draftDataCollection && this.props.currentStage === 2) {
      this.sendData(true)
    }
    if (prevProps.collectData !== this.props.collectData && this.props.collectData !== false) {
      this.sendData()
    }
    if (
        Object.keys(this.props.jobDetails).length > 0 &&
        prevProps.jobDetails !== this.props.jobDetails &&
        this.props.locationType === "interview" &&
        this.props.jobDetails.interview_location
    ) {
        var locationId = this.props.jobDetails.interview_location.id
        var branchName = this.props.jobDetails.interview_location.branch
        var brandNameEn = this.props.jobDetails.interview_location.brand_name_en
        var brandNameJp = this.props.jobDetails.interview_location.brand_name_jp
        var locationDetailsBrand = this.props.jobDetails.interview_location.brand_name_jp
        var locationDetailsBranch = this.props.jobDetails.interview_location.branch
        var locationDetailsAddress = this.props.jobDetails.interview_location_address
        var buildingNameEn = this.props.jobDetails.interview_location.building_name_en
        var buildingNameJp = this.props.jobDetails.interview_location.building_name_jp
        var isFUEI = this.props.jobDetails.interview_location.is_fuei
        var lotNo = this.props.jobDetails.interview_location.lot_num
        var commuteType = this.props.jobDetails.interview_location.mode
        var commuteTime = this.props.jobDetails.interview_location.minutes
        var phoneNumber = this.props.jobDetails.interview_location.telephone
        var jobStationId = this.props.jobDetails.interview_location.nearest_station.id
        var jobStationName = this.props.jobDetails.interview_location.nearest_station.name
        var prefecture = this.props.jobDetails.interview_location.prefecture.id
        var prefectureName = this.props.jobDetails.interview_location.prefecture.name
        var city1Name = this.props.jobDetails.interview_location.city.name
        var city2Name = this.props.jobDetails.interview_location.ward.name
        var city1 = this.props.jobDetails.interview_location.city.id
        var city2 = this.props.jobDetails.interview_location.ward.id
        var postalCode = this.props.jobDetails.interview_location.postcode.code
        var postalCodeId = this.props.jobDetails.interview_location.postcode.id
        this.setState({
          locationId: locationId,
          branchName: branchName,
          locationDetailsBrand: locationDetailsBrand,
          locationDetailsBranch: locationDetailsBranch,
          locationDetailsAddress: locationDetailsAddress,
          brandNameEn: brandNameEn,
          brandNameJp: brandNameJp,
          buildingNameEn: buildingNameEn,
          buildingNameJp: buildingNameJp,
          isFUEI: isFUEI,
          lotNo: lotNo ? lotNo : '',
          commuteType: commuteType,
          commuteTime: commuteTime,
          phoneNumber: phoneNumber,
          jobStationId: jobStationId,
          jobStationName: jobStationName,
          value: jobStationName,
          prefecture: prefecture,
          city1: city1,
          city2: city2,
          prefectureName: prefectureName,
          city1Name: city1Name,
          city2Name: city2Name,
          postalCode: postalCode,
          postalCodeId: postalCodeId
        },() => this.sendPreviewJobData())
      } else if (this.props.isDraftView && prevProps.isDraftView !== this.props.isDraftView && this.props.draftJobDetails['step2'] && this.props.draftJobDetails['step2']['location']) {
        let brandNameEn = this.props.draftJobDetails['step2'].location.brand_name_en;
        let brandNameJp = this.props.draftJobDetails['step2'].location.brand_name_jp;
        let branchName = this.props.draftJobDetails['step2'].location.branch;
        let locationDetailsBrand = this.props.draftJobDetails['step2'].location.brand_name_jp;
        let locationDetailsBranch = this.props.draftJobDetails['step2'].location.branch;
        let locationDetailsAddress = this.props.draftJobDetails['step2'].location_address;
        let buildingNameEn = this.props.draftJobDetails['step2'].location.building_name_en;
        let buildingNameJp = this.props.draftJobDetails['step2'].location.building_name_jp;
        let isFUEI = this.props.draftJobDetails['step2'].location.is_fuei;
        let lotNo = this.props.draftJobDetails['step2'].location.lot_num;
        let commuteType = this.props.draftJobDetails['step2'].location.mode;
        let commuteTime = this.props.draftJobDetails['step2'].location.minutes;
        let phoneNumber = this.props.draftJobDetails['step2'].location.telephone;
        let data = {
          branchName: branchName, phoneNumber: phoneNumber,
          locationDetailsBrand: locationDetailsBrand, locationDetailsBranch: locationDetailsBranch, locationDetailsAddress: locationDetailsAddress,
          brandNameEn: brandNameEn, brandNameJp: brandNameJp,
          buildingNameEn: buildingNameEn,
          buildingNameJp: buildingNameJp,
          isFUEI: isFUEI,
          lotNo: lotNo,
          commuteType: commuteType,
          commuteTime: commuteTime
        };
        if (this.props.draftJobDetails['step2'].location.nearest_station) {
          let jobStationId = this.props.draftJobDetails['step2'].location.nearest_station.id;
          let jobStationName = this.props.draftJobDetails['step2'].location.nearest_station.name || this.props.draftJobDetails['step2'].location.nearest_station.name_jp;
          data.jobStationId = jobStationId;
          data.jobStationName = jobStationName;
          if (jobStationName) {
            data.value = jobStationName;
          }
        }
        if (this.props.draftJobDetails['step2'].location.prefecture) {
          let prefecture = this.props.draftJobDetails['step2'].location.prefecture.id;
          let prefectureName = this.props.draftJobDetails['step2'].location.prefecture.name || this.props.draftJobDetails['step2'].location.prefecture.name_jp;
          data.prefecture = prefecture;
          data.prefectureName = prefectureName;
        }
        if (this.props.draftJobDetails['step2'].location.city) {
          let city1Name = this.props.draftJobDetails['step2'].location.city.name || this.props.draftJobDetails['step2'].location.city.name_jp;
          let city1 = this.props.draftJobDetails['step2'].location.city.id;
          data.city1 = city1;
          data.city1Name = city1Name;
        }
        if (this.props.draftJobDetails['step2'].location.ward) {
          let city2Name = this.props.draftJobDetails['step2'].location.ward.name || this.props.draftJobDetails['step2'].location.ward.name_jp;
          let city2 = this.props.draftJobDetails['step2'].location.ward.id;
          data.city2 = city2;
          data.city2Name = city2Name;
        }
        if (this.props.draftJobDetails['step2'].location.postcode) {
          data.postalCode = this.props.draftJobDetails['step2'].location.postcode.code;
          data.postalCodeId = this.props.draftJobDetails['step2'].location.postcode.id;
        }
        if (this.props.draftJobDetails['step2'].location && this.props.draftJobDetails['step2'].location.id) {
          data.locationId = this.props.draftJobDetails['step2'].location.id;
        }
        this.setState(data, () => this.sendPreviewJobData())
      }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.collectData!==prevState.collectData){
      return {collectData: nextProps.collectData}
    }
    else return null;
  }

  render() {
    var { brandNameEn, brandNameJp, branchName, postalCode, jobStationId,
          prefecture, prefectureName, city1, city1Name, city2, city2Name, lotNo, buildingNameJp,
          buildingNameEn, commuteTime, commuteType, options,
          isFUEI, phoneNumber, postalCodeOptions, locationId, locationDetailsAddress
        } = this.state
    var { submitted, edit } = this.props
    const inputProps = {
      placeholder: this.props.language === 'En' ? 'Station Name' : '駅名',
      value: this.state.value ? this.state.value : '',
      onChange: this.onChange,
      disabled: this.state.locationId
    };

    var prefectureOptions = []
    var city1Options = []
    var city2Options = []
    var locationOptions = []
    var foo = new Array(60)
    var commuteTimeOptions = []
    for(var i = 0; i < foo.length; i++){
      var commuteTimeObj = {value: i+1, label: i+1}
      commuteTimeOptions.push(commuteTimeObj)
    }
    var commuteTypeOptions = [
      {value: "BS", label: "バス"},
      {value: "WK", label: "徒歩"}
    ]

    var isFUEIOptions = [
      {value: true, label: DashboardTranslations.add_location_is_FUEI_yes},
      {value: false, label: DashboardTranslations.add_location_is_FUEI_no}
    ]

    if (this.state.locations.length > 0) {
      locationOptions = this.state.locations.map((option, id) => {
        return {value: option.id, label: '' + option.brand_name_jp + '/' + option.branch}
      })
    }

    if (prefectureName && prefecture && !options.prefectures) {
      prefectureOptions = { value: prefecture, label: prefectureName };
    } else if (options.prefectures) {
      prefectureOptions = options.prefectures.map((option, id) => {
        return {value: option.value, label: option.name}
      })
    }

    if (city1 && city1Name && !postalCodeOptions.cities) {
      city1Options = { value: city1, label: city1Name };
    } else if (postalCodeOptions.cities) {
      city1Options = postalCodeOptions.cities.map((option, id) => {
        return {value: option.id, label: option.name}
      })
    }

    if (city2 && city2Name && !postalCodeOptions.wards) {
      city2Options = { value: city2, label: city2Name };
    } else if (postalCodeOptions.wards) {
      city2Options = postalCodeOptions.wards.map((option, id) => {
        return {value: option.id, label: option.name}
      })
    }

    var mandatoryBox =
      <span className="mandatory-container">
        {DashboardTranslations.field_mandatory_text}
      </span>


    return (
      <div className="add-locations-container" style={{display: this.props.currentStage === 2 ? "block" : "none"}}>
        {(edit && this.props.type !== "copy") &&
          <div style={{opacity: 0.6}}>
            <SelectDropdown
              label={DashboardTranslations.add_job_page_work_location_heading}
              disabled={this.props.type !== "copy"}
              selectPlaceholder="勤務地作成"
              name="locationId"
              value={locationId}
              options={locationOptions}
              handleChange={this.handleChange.bind(this)}
            />
          </div>
        }

        {(edit && this.props.type !== "copy" && options) &&
          <div className="location-details-container">
            <div className="label">{DashboardTranslations.add_job_page_address_label}</div>
            <div className="value">{locationDetailsAddress}</div>
          </div>
        }

        {(!edit || this.props.type === "copy") &&
          <SelectDropdown
            label='登録済みの勤務地データを利用する。'
            name="locationId"
            value={locationId}
            tooltipText={getTooltipMessage('add_location_create_new_location_tooltip_text')}
            selectPlaceholder='選択'
            explanatoryText='面接場所を新規登録する場合は「ブランド名」より入力開始してください。'
            options={locationOptions}
            handleChange={this.handleChooseLocation.bind(this)}
          />
        }

        {(!edit || this.props.type === "copy") && <InputBox
          errorMessage={DashboardTranslations.add_location_brand_name_jp_error_message}
          error={submitted === true && brandNameJp === ""}
          label='ブランド名・会社名（日本語表記）'
          name="brandNameJp"
          value={brandNameJp}
          disabled={this.state.locationId}
          explanatoryText='求人記事に表示されます。人材会社様などクライアント名が記載できない場合は、貴社名を入力してください。'
          tooltipText={getTooltipMessage('add_location_brand_name_jp_tootlip_text')}
          handleChange={this.handleChange.bind(this)}
        />}
        {(!edit || this.props.type === "copy") && <InputBox
          errorMessage={DashboardTranslations.add_location_brand_name_en_error_message}
          error={submitted === true && brandNameEn === ""}
          label='ブランド名・会社名（英語表記）'
          name="brandNameEn"
          disabled={this.state.locationId}
          explanatoryText='求人記事に表示されます。人材会社様などクライアント名が記載できない場合は、貴社名を入力してください。'
          tooltipText={getTooltipMessage('add_location_brand_name_en_tooltip_text')}
          value={brandNameEn}
          handleChange={this.handleChange.bind(this)}
        />}
        <InputBox
          errorMessage={DashboardTranslations.add_location_branch_name_error_message}
          error={submitted === true && branchName === ""}
          value={branchName}
          label={DashboardTranslations.add_location_branch_name_label}
          tooltipText={getTooltipMessage('add_location_branch_location_name_tooltip_text')}
          name="branchName"
          disabled={this.state.locationId}
          handleChange={this.handleChange.bind(this)}
          explanatoryText='求人記事に表示されません。「ブランド名＋店舗名」が記事名称となります。重複する記事名称は登録できません。'
        />
        {(!edit || this.props.type === "copy") && <InputBox
          errorMessage={DashboardTranslations.add_location_postcode_error_message}
          error={submitted === true && prefecture === ""}
          label={DashboardTranslations.add_location_postcode_label}
          name="postalCode"
          value={postalCode}
          disabled={this.state.locationId}
          specialExplanatoryText={this.props.locationType === "interview" ? DashboardTranslations.add_location_brand_name_jp_special_guidance_text: ""}
          handleChange={this.handleChange.bind(this)}
        />}
        {(!edit || this.props.type === "copy") && <SelectDropdown
          errorMessage={DashboardTranslations.add_location_prefecture_error_message}
          error={submitted === true && prefecture === ""}
          label={DashboardTranslations.add_location_prefecture_label}
          name="prefecture"
          value={prefecture}
          disabled={this.state.locationId}
          options={prefectureOptions}
          handleChange={this.handleChange.bind(this)}
        />}
        {(!edit || this.props.type === "copy") && <SelectDropdown
          errorMessage={DashboardTranslations.add_location_city1_error_message}
          error={submitted === true && city1 === ""}
          label={DashboardTranslations.add_location_city1_label}
          name="city1"
          value={city1}
          disabled={this.state.locationId}
          options={city1Options}
          handleChange={this.handleChange.bind(this)}
        />}
        {(!edit || this.props.type === "copy") && <SelectDropdown
          errorMessage={DashboardTranslations.add_location_city2_error_message}
          error={submitted === true && city2 === ""}
          label={DashboardTranslations.add_location_city2_label}
          name="city2"
          value={city2}
          disabled={this.state.locationId}
          options={city2Options}
          handleChange={this.handleChange.bind(this)}
        />}
        {(!edit || this.props.type === "copy") && <InputBox
          errorMessage={DashboardTranslations.add_location_lot_no_error_message}
          error={submitted === true && (lotNo === "" || lotNo.substr(lotNo.length - 1) === '-')}
          label={DashboardTranslations.add_location_lot_no_label}
          name="lotNo"
          explanatoryText='「丁」、「番」など漢字は入力しないでください。'
          value={lotNo}
          disabled={this.state.locationId}
          handleChange={this.handleChange.bind(this)}
        />}
        {(!edit || this.props.type === "copy") && <InputBox
          errorMessage={DashboardTranslations.add_location_building_name_jp_error_message}
          error={false}
          label={DashboardTranslations.add_location_building_name_jp_label}
          name="buildingNameJp"
          value={buildingNameJp}
          disabled={this.state.locationId}
          handleChange={this.handleChange.bind(this)}
        />}
        {(!edit || this.props.type === "copy") && <InputBox
          errorMessage={DashboardTranslations.add_location_building_name_en_error_message}
          error={false}
          label={DashboardTranslations.add_location_building_name_en_label}
          tooltipText={getTooltipMessage('add_location_building_name_en_tooltip_text')}
          name="buildingNameEn"
          value={buildingNameEn}
          disabled={this.state.locationId}
          handleChange={this.handleChange.bind(this)}
        />}
        {(!edit || this.props.type === "copy") && <div className="label">{DashboardTranslations.add_location_nearest_station_label}</div>}
        {(!edit || this.props.type === "copy") && submitted === true && jobStationId === "" && <div className="autosuggest-error">{DashboardTranslations.add_location_nearest_station_error_message}</div>}
        {(!edit || this.props.type === "copy") && <Autosuggest
					suggestions={this.state.suggestions}
					onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
					onSuggestionsClearRequested={this.onSuggestionsClearRequested}
					getSuggestionValue={this.getSuggestionValue}
					renderSuggestion={renderSuggestion}
					onSuggestionSelected={this.onSuggestionSelected}
          className="autosuggest-field"
					inputProps={inputProps} />}
        {(!edit || this.props.type === "copy") && <SelectDropdown
          errorMessage={DashboardTranslations.add_location_commute_type_error_message}
          error={submitted === true && commuteType === ""}
          label={DashboardTranslations.add_location_commute_type_label}
          name="commuteType"
          value={commuteType}
          disabled={this.state.locationId}
          options={commuteTypeOptions}
          handleChange={this.handleChange.bind(this)}
        />}
        {(!edit || this.props.type === "copy") && <SelectDropdown
          errorMessage={DashboardTranslations.add_location_commute_time_error_message}
          error={submitted === true && commuteTime === ""}
          label={DashboardTranslations.add_location_commute_time_label}
          name="commuteTime"
          value={commuteTime}
          disabled={this.state.locationId}
          options={commuteTimeOptions}
          handleChange={this.handleChange.bind(this)}
        />}
        {(!edit || this.props.type === "copy") && <SelectDropdown
          errorMessage={DashboardTranslations.add_location_is_FUEI_error_message}
          error={submitted === true && isFUEI === ""}
          label={DashboardTranslations.add_location_is_FUEI_label}
          name="isFUEI"
          value={isFUEI}
          disabled={this.state.locationId}
          tooltipText={getTooltipMessage('add_location_is_FUEI__tooltip_text')}
          options={isFUEIOptions}
          handleChange={this.handleChange.bind(this)}
        />}
        <InputBox
          errorMessage={DashboardTranslations.add_location_phone_number_error_message}
          error={submitted === true && (phoneNumber.length !== 10 && phoneNumber.length !== 11)}
          label={DashboardTranslations.add_location_phone_number_label}
          name="phoneNumber"
          disabled={this.state.locationId}
          // specialExplanatoryText={this.props.locationType === "interview" ? DashboardTranslations.add_location_phone_number_special_guidance_text: ""}
          value={phoneNumber}
          handleChange={this.handleChange.bind(this)}
        />

        {this.props.showDuplicateInterviewLocationError && <>
          <br/>
          <div className="error-message-container">同じ勤務地または面接場所（「ブランド名・会社名」＋「店舗名」の組み合わせ）が既に登録されています。</div>
        </>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token
    }
};

AddInterviewLocation = connect(mapStateToProps)(AddInterviewLocation);


export default AddInterviewLocation;
