import React, { Component } from 'react';
import { connect } from 'react-redux';

import modalImage from '../../images/modals/infoModalNoApplications.png';
import closeIcon from '../../images/svg/close-icon-with-circle.svg';

import './infoModalNoApplications.scss';

class InfoModalNoApplications extends Component {
    render() {
        return (
            <div className="info-modal-no-applications">
                <div className="modal-outer-container">
                    <img className="info-image-1" src={modalImage}  alt="ss" />

                    <button className="btn" onClick={() => this.props.closeInfoModalNoApplications()}>
                        閉じる
                    </button>
                    <img className="close-icon" src={closeIcon}  alt="ss" onClick={() => this.props.closeInfoModalNoApplications()}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
    }
};

export default connect(mapStateToProps)(InfoModalNoApplications);
