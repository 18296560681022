import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { store } from "./store.js"
import { createBrowserHistory } from 'history'
import 'react-app-polyfill/ie11';
import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';
import 'core-js/fn/number/is-nan';
import './App.scss'

import App from './App';

var browserHistory = createBrowserHistory()

ReactDOM.render(
    <Provider store={store}>
      <Router history={browserHistory}>
        <App history={browserHistory} />
      </Router>
    </Provider>,
    document.getElementById('root')
);
