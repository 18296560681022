import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import 'react-overlay-loader/styles.css';
import './styles/transactionSuccess.scss';
import DashboardTranslations from "../../DashboardTranslations";

const useStyles = theme => ({
  root: {
    height: 200,
    width: 200
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

class TransactionSuccess extends Component {
  backToBilling() {
    this.props.history.push('/dashboard/billing/');
  }

  render() {
    return (
        <div className="billing-page-container" style={{whiteSpace: "pre-line"}}>
          <span style={{fontSize: 32, color: "#555555"}}>{this.props.location.state.messageToDisplay}</span>
          {this.props.location.state.isInvVerificationPending && <div style={{whiteSpace: "pre-line", color: "#707070"}}>
            <div style={{fontSize: 20}}>
              すぐに掲載開始できるよう記事を作成しましょう！
            </div>
            {DashboardTranslations.under_inv_verification_desc}
          </div>}

          {this.props.location.state.isInvRejected && <div style={{whiteSpace: "pre-line", color: "#707070"}}>
            {DashboardTranslations.inv_rejected_desc}
          </div>}

          <br/>

          <div className="billing-page-inner" style={{margin: "20px auto"}}>
            <div className="transactions-button-container">
              <div className="section-link" onClick={() => this.backToBilling()}>
                お支払の管理に戻る
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    language: state.language,
    token: state.user.token
  }
};

TransactionSuccess = connect(mapStateToProps)(TransactionSuccess);
export default withStyles(useStyles)(TransactionSuccess)
