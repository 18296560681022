import React, { Component } from 'react';
import InputBox from '../../components/InputBox.js'
import { connect } from 'react-redux';
import DashboardTranslations from '../../../DashboardTranslations.js';

import '../styles/companyDetailsPart1.scss';
import { katakanaRegex } from '../../../regex';

class CompanyDetailsPart1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyNameEn: props.companyNameEn ? props.companyNameEn : "",
      companyNameJp: props.companyNameJp ? props.companyNameJp : "",
      companyNameKT: props.companyNameKT ? props.companyNameKT : "",
      companyPhoneNumber: props.companyPhoneNumber ? props.companyPhoneNumber : "",
    }
  }

  componentDidMount() {

  }

  handleChange = event => {
    var name = event.target.name
    var value = event.target.value
    this.setState({ [event.target.name]: value }, () => this.props.updateValues(name, value));
  }

  render() {
    var { companyNameEn, companyNameJp, companyNameKT, companyPhoneNumber
    } = this.state

    var { submitted } = this.props

    return (
      <div className="company-details-part1-container">
        <div className="form-helper-text">
          会社情報を入力して「次へ」ボタンを押してください
        </div>
        <div className="company-details-part1-form">
          <InputBox
            value={companyNameJp}
            errorMessage={DashboardTranslations.register_page_company_name_jp_error_message}
            error={submitted === true && (companyNameJp.length === 0 || companyNameJp.length > 30)}
            label="会社名 (日本語）"
            showMandatory={false}
            name="companyNameJp"
            handleChange={this.handleChange.bind(this)}
          />
          <InputBox
            value={companyNameEn}
            errorMessage={DashboardTranslations.register_page_company_name_en_error_message}
            error={submitted === true && (companyNameEn.length === 0 || companyNameEn.length > 30)}
            label="会社名 (英語）"
            name="companyNameEn"
            handleChange={this.handleChange.bind(this)}
          />
          <InputBox
            value={companyNameKT}
            errorMessage={DashboardTranslations.register_page_company_name_kt_error_message}
            error={submitted === true && !katakanaRegex.test(companyNameKT)}
            label="会社名 (カタカナ）"
            name="companyNameKT"
            handleChange={this.handleChange.bind(this)}
          />
          <InputBox
            value={companyPhoneNumber}
            errorMessage={DashboardTranslations.register_page_company_phone_number_error_message}
            error={submitted === true && (companyPhoneNumber.length <= 9 || companyPhoneNumber.length > 11)}
            label="電話番号 （半角数字、ハイフン無し）"
            name="companyPhoneNumber"
            handleChange={this.handleChange.bind(this)}
            className="single-field"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.user.isLoggedIn,
    }
};

CompanyDetailsPart1 = connect(mapStateToProps)(CompanyDetailsPart1);

export default CompanyDetailsPart1;
