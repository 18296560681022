import React from 'react';
import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
// import closeIconCircle from '../../images/close-icon-circle.png'
import DashboardTranslations from '../../DashboardTranslations.js';
import closeIcon from '../../images/close-icon.png'
import '../styles/errorModal.scss'
import {getErrorMessage} from "../../messages/errors";


const ErrorModal = (props) => (
    <div>
      <Dialog
        open={props.showError}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="error-modal"
        style={{}}
      >
        <DialogTitle className="dialog-title" id="alert-dialog-title"><img height="15" width="15" onClick={() => props.handleClose()} className="main-close-icon" src={closeIcon} alt="close-icon" /></DialogTitle>
        <div className="dialog-message-container">
          <div style={{padding: 15}}>
            <DialogContentText id="alert-dialog-description"  style={{fontSize: 14}}>
              <div className="close-icon-center" >
                <div className="error-modal-header-text">{DashboardTranslations.error_modal_header_text}</div>
              </div>
              {props.error ? props.error.split('\n').map ((item, i) => <div key={i}>{item}</div>) : getErrorMessage('generic')}
              <div className="ok-button" onClick={() => props.handleCloseRedirect ? props.handleCloseRedirect() : props.handleClose()}>OK</div>
            </DialogContentText>
          </div>
        </div>
      </Dialog>
    </div>
);

export default ErrorModal;
