import React, {Component} from 'react';
import {connect} from 'react-redux';
import {transactionHistoryAPI} from '../helperFunctions/dataServices.js'
import InnerHeader from '../components/InnerHeader.js'
import ScaleLoader from 'react-spinners/ScaleLoader';
import DashboardTranslations from '../../DashboardTranslations.js';

import 'react-overlay-loader/styles.css';
import './styles/transactionHistory.scss';
import TransactionCell from "./TransactionCell";

class TransactionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      transactionHistoryData: []
    }
  }

  componentDidMount() {
    this.transactionHistory()
  }

  transactionHistory() {
    this.setState({loading: true})
    transactionHistoryAPI().then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
    	  let transactionHistoryData = response.data;
          transactionHistoryData.sort((a, b) => b.transaction_date_timestamp - a.transaction_date_timestamp);
          this.setState({loading: false, transactionHistoryData: transactionHistoryData});
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  backArrowOnClick() {
    this.props.history.goBack();
  }


  render() {
    const {transactionHistoryData, loading} = this.state;
    const transactionCell = transactionHistoryData.map(
        (transaction, index) => <TransactionCell transaction={transaction} index={index} />
    )

    return (
      <div className="transaction-history-page-container">
        <InnerHeader
          primary={false}
          history={this.props.history}
          headerText={DashboardTranslations.transaction_page_header_text}
          backArrowOnClick={this.backArrowOnClick.bind(this)}
        />
        <ScaleLoader sizeUnit={"px"} height={50}
          width={4} color={'#007AFF'} loading={loading}
        />
        { !loading &&
          <div className="transaction-page-description">
            {DashboardTranslations.transaction_page_guidance_text}
          </div>
        }
        { !loading && transactionCell}
        {transactionCell.length === 0 && !loading ? DashboardTranslations.transaction_page_no_transaction_history_available_text : ""}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token
    }
};

TransactionHistory = connect(mapStateToProps)(TransactionHistory);

export default TransactionHistory;
