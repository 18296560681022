import React, { Component } from 'react';
import SelectDropdown from '../../components/SelectDropdown.js'
import InputBox from '../../components/InputBox.js'
import { connect } from 'react-redux';
import DashboardTranslations from '../../../DashboardTranslations.js';
import SuccessModal from '../../components/SuccessModal'
import ScaleLoader from 'react-spinners/ScaleLoader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FontAwesomeIcon from 'react-fontawesome'
import { ToggleLayer, Arrow } from "react-laag";
import '../styles/companyDetailsPart5.scss';
import { nameRegex, passwordRegex, emailRegex, katakanaRegex } from '../../../regex';

class CompanyDetailsPart3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {

  }

  handleChange = event => {
    var value = event.target.value
    this.setState({ [event.target.name]: value });
  }

  render() {
    let { loading } = this.props;

    return (
      <div className="company-details-part5-container">
        {loading &&
          <ScaleLoader sizeUnit={"px"} height={50}
            width={4} color={'#007AFF'} loading={loading}
          />
        }
        {!loading && <div className="main-detail">
          すぐに求人記事を作成して<br/>
          外国人採用を始めましょう！
        </div>}
        {!loading && <div className="sub-detail">
          お支払いは求人記事を作成してから！
        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.user.isLoggedIn,
    }
};

CompanyDetailsPart3 = connect(mapStateToProps)(CompanyDetailsPart3);

export default CompanyDetailsPart3;
