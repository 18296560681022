export const setRegisterStep = step => {
    return {
        type: 'SET_REGISTER_STEP',
        step
    }
};


export const registrationSuccess = response => {
    return {
        type: 'REGISTRATION_SUCCESSFUL',
        response
    }
};


export const loginSuccess = response => {
    return {
        type: 'LOGIN_SUCCESSFUL',
        response
    }
};


export const updateRegisterStep = step => {
    return {
        type: 'UPDATE_REGISTER_STEP',
        step
    }
};


export const logout = () => {
    return {
        type: 'LOGOUT'
    }
};


export const setLanguage = language => {
    return {
        type: 'SET_LANGUAGE',
        language
    }
};

export const selectedScreen = screenPath => {
    return {
        type: 'SET_SCREEN',
        screenPath
    }
};

export const completedJoyride = completed => {
    return {
        type: 'COMPLETED_JOYRIDE',
        completed
    }
};
