import DashboardTranslations from "../../DashboardTranslations";
import React, { useState, useEffect } from 'react';

import './styles/transactionCell.scss';
import downloadButton from '../../images/FileArrowDown.svg';
import {downloadInvoice} from "../helperFunctions/dataServices";


const convert = (timestamp) => {
    const dateObj = new Date(timestamp * 1000);
    return `${dateObj.getFullYear()}/${dateObj.getMonth() + 1}/${dateObj.getDate()}`;
}


const TransactionCell = (props) => {
    const [zoom, setZoom] = useState(1);

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setZoom(window.innerWidth / 430);
        }
    }, []);

    return (
        <div key={props.index} className="cell-container" style={{zoom: zoom}}>
            <div className="plan-name-container">
                <div className="plan-name">
                    {props.transaction.plan}
                </div>
            </div>
            <div className="transaction-date-container">
                <div className="transaction-date">
                    {DashboardTranslations.transaction_page_transaction_time_label} - {convert(props.transaction.transaction_date_timestamp)}
                </div>
            </div>

            <div className="download-invoice-button-container" onClick={() => downloadInvoice(props.transaction.id, props.transaction.is_cc)}>
                <img src={downloadButton} alt="Download Invoice Button" className="download-invoice-button"/>
            </div>

            <div className="transaction-id-label-container">
                <div className="label">
                    {DashboardTranslations.transaction_page_transaction_id_text}
                </div>
            </div>
            <div className="transaction-id-container">
                <div className="value">
                    {props.transaction.transaction_id}
                </div>
            </div>

            <div className="pre-tax-amount-label-container">
                <div className="label">
                    {DashboardTranslations.transaction_page_basic_amount_text}
                </div>
            </div>
            <div className="pre-tax-amount-container">
                <div className="value">
                    ¥ {(props.transaction.amount - props.transaction.tax).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
            </div>

            <div className="tax-amount-label-container">
                <div className="label">
                    {DashboardTranslations.transaction_page_tax_amount_text}
                </div>
            </div>
            <div className="tax-amount-container">
                <div className="value">
                    ¥ {props.transaction.tax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
            </div>

            <div className="total-amount-label-container">
                <div className="label">
                    {DashboardTranslations.transaction_page_total_amount_text}
                </div>
            </div>
            <div className="total-amount-container">
                <div className="value">
                    ¥ {props.transaction.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
            </div>

            <div className="cycle-duration-label-container">
                <div className="label">
                    {DashboardTranslations.transaction_page_start_stop_header_text}
                </div>
            </div>
            <div className="cycle-duration-container">
                <div className="value">
                    {convert(props.transaction.transaction_date_timestamp)} 〜 {convert(props.transaction.expiry_on)}
                </div>
            </div>
        </div>
    );
}

export default TransactionCell;
