import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerHeader from '../components/InnerHeader.js'
import ScaleLoader from 'react-spinners/ScaleLoader';
import DashboardTranslations from '../../DashboardTranslations.js';
import JobTypeImage from '../../images/job-type-image.svg'
import SelectPlanImage from '../../images/select-plan.png'
import SelectNewPlanImage from '../../images/new-select-plan-3.svg'

import { withStyles } from '@material-ui/core/styles';

import 'react-overlay-loader/styles.css';
import '../SelectPlan/styles/selectPlan.scss';

const useStyles = theme => ({
  table: {
    minWidth: 650,
  },
});

class SelectJobType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  selectJobType = (jobType) => {

    this.props.history.push({
      pathname: "/dashboard/add-job",
      state: {
        job_type: jobType
      }
    })

  };

  backArrowOnClick() {
    this.props.history.goBack();
  }

  render() {
    const {loading } = this.state;

    return (
        <div className="transaction-history-page-container margin-top-110">
          <InnerHeader
              primary={false}
              history={this.props.history}
              headerText={DashboardTranslations.select_job_type_page_header_text}
              backArrowOnClick={this.backArrowOnClick.bind(this)}
          />
          <ScaleLoader sizeUnit={"px"} height={50}
                       width={4} color={'#007AFF'} loading={loading}
          />
          <div className='select-plan-container-1'>
            購入プランはお決まりですか？
            <br/>
            購入するプランの中から作成する記事を選択してください
          </div>
          { !loading &&
          <div className="transaction-page-description">
            <div>
              <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                <div style={{ flex: '1 1 0%', marginTop: '12px', borderRight: '1px solid #e2dada'}}>
                  <div style={{ fontSize: '15px', fontWeight: '500' }}>
                    アルバイト限定プランを
                    <br/>
                    購入予定の企業様はこちらから
                  </div>
                  <div style={{marginTop: '12px'}}>
                    <button
                      className='button-style plan-button'
                      style={{background: '#FC6B6D', color: '#FFFFFF', fontWeight: 500, fontSize: '15px', width: '82%', padding: '20px', border: 'none'}}
                      onClick={()=> this.selectJobType('PT1')}
                    >
                      アルバイトの求人記事を作成する
                    </button>
                  </div>
                </div>
                <div style={{ flex: '1 1 0%', marginTop: '12px'}}>
                  <div>
                    <div style={{ fontSize: '15px', fontWeight: '500' }}>
                      オールインワン・プランを
                      <br/>
                      購入予定の企業様はこちらから
                    </div>
                    <div style={{marginTop: '12px'}}>
                      <button
                        className='button-style plan-button'
                        style={{background: '#28C1DD', color: '#FFFFFF', fontWeight: 500, fontSize: '15px', width: '80%', padding: '20px', border: 'none'}}
                        onClick={()=> this.selectJobType('PT2')}
                      >
                        アルバイトの求人記事を作成する
                      </button>
                    </div>
                  </div>
                  <div style={{marginTop: '12px'}}>
                    <button
                      className='button-style plan-button'
                      style={{background: '#1A9EFF', color: '#FFFFFF', fontWeight: 500, fontSize: '15px', width: '80%', padding: '20px', border: 'none'}}
                      onClick={()=> this.selectJobType('FT')}
                    >
                      正社員の求人記事を作成する
                    </button>
                  </div>
                  <div style={{marginTop: '12px'}}>
                    <button
                      className='button-style plan-button'
                      style={{background: '#7B70F7', color: '#FFFFFF', fontWeight: 500, fontSize: '15px', width: '80%', padding: '20px', border: 'none'}}
                      onClick={()=> this.selectJobType('SSV')}
                    >
                      特定技能の求人記事を作成する
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  color: '#FD595C',
                  fontSize: '15px',
                  marginTop: '10px',
                  marginBottom: '50px',
                }}
              >
                ※プラン間の記事の流用はできません。購入プランに該当する記事の作成が必要です。
              </div>
            </div>
          </div>
          }
          <div className='billing-body-container-2'>
            <div className='billing-heading-text'>
              料金プラン
            </div>
            <img
              src={SelectNewPlanImage}
              alt="close"
            />
          </div>
          <div className='billing-body-container-2'>
            <div className='billing-heading-text'>
              プラン詳細
            </div>
            <br/>
            <div>
              <img src={JobTypeImage} alt='Job Type Info' style={{width: '100%'}} />
            </div>

          </div>

          <div
            className='select-plan-container-2 container-2-mobile'
            style={{
              paddingBottom: '20px',
              textAlign: 'justify',
              fontSize: '14px'
            }}
          >
            ♢<span style={{fontWeight: '500'}}>掲載について</span>
            <div style={{marginLeft: '13px'}}>※１記事に「募集職種＋雇用形態＋勤務地」が各一つずつ掲載できます。</div>
            <div style={{marginLeft: '26px'}}>例１）正社員とアルバイトを募集する場合は2記事となります。</div>
            <div style={{marginLeft: '26px'}}>例２）同じ勤務地でも、皿洗い・ウエイターなど２職種の募集する場合は2記事となります。</div>
            <div style={{marginLeft: '13px'}}>※プラン間の記事の流用はできません。</div>
            {/*<div style={{marginLeft: '13px'}}>※2つのプランの同時掲載はできませんが、掲載中に別プランの購入はできます。</div>*/}

            <br/>
            <br/>
            ♢<span style={{fontWeight: '500'}}>お支払い方法</span>
            <div style={{marginLeft: '13px'}}>お支払はクレジットカードまたは請求書払いがあります。</div>
            <div style={{marginLeft: '13px'}}>クレジットカード払なら即掲載開始できます。請求書払いの場合はご利用にあたり審査が必要です。</div>
            <br/>
            <br/>
            ♢<span style={{fontWeight: '500'}}>その他の注意事項</span>
            <br/>
            <div style={{marginLeft: '13px'}}>
              <div>※料金は一括払いとなります。</div>
              <div>※購入したプランの変更やキャンセルはできません。</div>
              <div>※WORK JAPANは求人サイトであり、適性や能力等を保証するものではありません。</div>
              <div style={{marginLeft: '13px'}}>また、紹介フィーをいただく事はありません。</div>
            </div>
          </div>

        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    language: state.language,
    token: state.user.token
  }
};

SelectJobType = connect(mapStateToProps)(SelectJobType);
export default withStyles(useStyles)(SelectJobType)
