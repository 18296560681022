import React, { Component } from 'react';
import Switch from "react-switch";
import ApplicantCell from './ApplicantCell'  // Alert: Don't delete this line
import DashboardTranslations from '../../DashboardTranslations.js';
import { getErrorMessage } from '../../messages/errors';
import { API_ROOT } from '../../constants';
import { connect } from 'react-redux';
import { updateApplicantStatusAPI, updateSlots, addPicturesAPI, deleteImage } from '../helperFunctions/dataServices.js'
import axios from 'axios'
import ErrorModal from '../components/ErrorModal'
import ConfirmModal from '../components/ConfirmModal'
import SuccessModal from '../components/SuccessModal'
import ScaleLoader from 'react-spinners/ScaleLoader';
import "react-toggle-switch/dist/css/switch.min.css"
import './styles/jobCell.scss';
import closeIcon from '../../images/carousel-close.png'
import alert from '../../images/alert.png'
import videoIcon from "../../images/svg/video-icon-job-list.svg";
const Compress = require('compressorjs')

class JobCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showError: false,
      error: "",
      statusUpdateLoading: false,
      showJobStopConfirmation: false,
      showSuccess: false,
      paymentError: "",
      showPaymentError: false,
      subscriptionData: props.subscriptionData,
      showStyle: "Less",
      selectedFile: [],
      showImageUploadSuccess: false
    }
  }

  convertExpiryTimestamp(timestamp) {
    let date = new Date(timestamp*1000);
    date.setDate(date.getDate() + 10);

    const cDate = date.getDate();
    const cMonth = date.getMonth();
    const cYear = date.getFullYear();

    if (timestamp !== null) {
      return [cYear, "年", cMonth+1, "月", cDate, "日"].join('');
    }
    else {
      return "";
    }
  }

  closeConfirmation() {
    this.setState({showJobStopConfirmation: false})
  }

  styleChange() {
    if (this.state.showStyle === "Less") {
      this.setState({showStyle: "More"})
    }
    else {
      this.setState({showStyle: "Less"})
    }
  }

  async updateStatus(nextStatus) {
    if (this.props.subscriptionData.subscription_status === "BT") {
      this.setState({showPaymentError: true, paymentError: getErrorMessage('jobs_page_payment_required_error_text')})
    }
    else {
      var headers = {
          "Content-Type": "application/json",
          "Authorization": "Token "+ this.props.token
      }
      var payload = {}
      payload.status = nextStatus
      this.setState({statusUpdateLoading: true})
      await axios.patch(API_ROOT + 'api/v3/employer/jobs/'+this.props.job.id+'/', payload, {headers: headers})
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState({showAddJobs: false}, () => this.props.getJobs('update-status'))
          setTimeout(function() { this.setState({statusUpdateLoading: false}); }.bind(this), 3000);
        }
        else {
          this.setState({statusUpdateLoading: false, showError: true, error: getErrorMessage('from_api_response', {'apiErrorMessage': response.data.error})});
        }
      })
      .catch((err) => {
        this.setState({statusUpdateLoading: false});
        if (err.response.status === 402) {
          if (this.props.subscriptionData.subscription_status !== "IS") {
            this.setState({showPaymentError: true, paymentError: getErrorMessage('jobs_page_payment_required_error_text')});
          } else {
            if ((this.props.job.job_type === 'PT1' && this.props.subscriptionData.subscription_data.plan.is_all_in_one) || (this.props.job.job_type !== 'PT1' && !this.props.subscriptionData.subscription_data.plan.is_all_in_one)) {
              this.setState({showPaymentError: true, paymentError: getErrorMessage('mismatched_plan_purchased', {'planName': this.props.subscriptionData.subscription_data.plan.name_jp})});
            } else {
              this.setState({showPaymentError: true, paymentError: getErrorMessage('plan_max_reached', {'planName': this.props.subscriptionData.subscription_data.plan.name_jp})});
            }
          }
        }
      })
    }
  }

  requestUpdateSlot(id) {
    this.setState({loading: true})
    updateSlots(id).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({showAddJobs: false, loading: false}, () => this.props.getJobs())
      }
      else {
        this.setState({loading: false, showError: true, error: getErrorMessage('from_api_response', {'apiErrorMessage': response.data.error})})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  handleErrorClose = () => {
    this.setState({showError: false, error: '', paymentError: "", showPaymentError: false})
  }

  handleCloseRedirect() {
    if (this.props.subscriptionData.subscription_status !== 'IS') {
      this.props.history.push('/dashboard/billing')
    } else {
      this.handleErrorClose();
    }
  }

  stopJob() {
    this.updateStatus("ST", this.props.job.id)
    this.setState({showJobStopConfirmation: false,})
  }

  handleClose = () => {
    this.setState({ showSuccess: false, showImageUploadSuccess: false, successMessage: '' });
  };

  updateApplicantStatus(payload, id) {
    updateApplicantStatusAPI(payload, id).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({showAddJobs: false, loading: false}, () => {
          this.setState({showSuccess: true})
          this.props.getJobs()
        })
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.subscriptionData !== this.props.subscriptionData) {
      var subscriptionData = this.props.subscriptionData
      this.setState({subscriptionData});
    }
  }

  hideOverlay(id) {
    var elementId = id + "-overlay"
    var element = document.getElementById(elementId);
    element.style.display = "none"
  }

  deleteOldImages(id) {
    this.setState({loading: true})
    this.deleteImage(this.props.job.id, id)
  }

  deleteImage(jobId, pic) {
    var payload ={
      image_id: parseInt(pic)
    }
    deleteImage(jobId, payload).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        response.json().then(json => {
          this.setState({loading: false}, () => this.props.showImageUploadSuccess(json, "delete"))
        });
      }
      else {
        this.setState({loading: false})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
    bstr = window.atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    var blob = new Blob([u8arr], {type: 'image/png'});
    blob.lastModifiedDate = new Date();
    return blob
    // return new Blob([u8arr], filename, {type:"image/png"});
  }

  addPictures() {
    var pictureLabels = ["image1", "image2", "image3", "image4",  "image5"]
    var picturesPayload
    if (this.state.selectedFile.length > 0) {
      picturesPayload = new FormData()
      this.state.selectedFile.map((pic, id) => {
        picturesPayload.append(pictureLabels[id], pic)
      })
    }
    else if (this.state.selectedFile.id) {
      picturesPayload = {}
      picturesPayload.image_ids = [this.state.selectedFile.id]
    }

    addPicturesAPI(picturesPayload, this.props.job.id).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({loading: false}, this.props.showImageUploadSuccess(response.data, "add"))
      }
      else {
        this.setState({loading: false, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: false})
    })
  }

  compressImage(file) {
    return new Promise((resolve, reject) => {
        new Compress(file, {
            convertSize: 1, // the max size in MB, defaults to 2MB
            quality: .75, // the quality of the image, max is 1,
            maxWidth: 1920, // the max width of the output image, defaults to 1920px
            maxHeight: 1920, // the max height of the output image, defaults to 1920px
            resize: 'contain', // defaults to true, set false if you do not want to resize the image width and height
            success: (result) => {
                resolve(new File([result], file.name, {type: result.type}))
            },
            error: (error) => reject(error)
        })
    });
}


  fileChangeHandler(event) {
    // this.setState({selectedFile: event.target.files})
    var fileArray = []
    fileArray[0] = event.target.files[0]
    const compressPromises = [];
    for (const file of fileArray) {
        compressPromises.push(this.compressImage(file));
    }
    Promise.all(compressPromises).then((data) => {
      // returns an array of compressed images
      this.setState({
        selectedFile: data,
      }, () => this.addPictures());
    })
    .catch((e) => {
      // console.log(e);
    })
  }

  redirect(url, job) {
    this.props.history.push({
      pathname: url,
      state: {
        job_type: job.job_type
      }
    })
  }

  render() {
    const {job} = this.props;
    const {
      statusUpdateLoading,
      showError,
      error,
      showJobStopConfirmation,
      showPaymentError,
      paymentError,
      subscriptionData
    } = this.state;

    let switched = false;
    let nextStatus = 'RN';
    let currentStatus = DashboardTranslations.jobs_page_status_created;
    if (job.status === 'RN') {
      currentStatus = DashboardTranslations.jobs_page_status_running
      switched = true
      nextStatus = 'ST'
    }
    else if (job.status === 'ST') {
      currentStatus = DashboardTranslations.jobs_page_status_stopped
      nextStatus = 'RN'
      switched = false
    }
    else if (job.status === 'UP') {
      currentStatus = DashboardTranslations.jobs_page_status_updated
      nextStatus = 'RN'
      switched = false
    }
    else if (job.status === 'TR') {
      currentStatus = DashboardTranslations.jobs_page_status_terminated
      nextStatus = ''
    }

    let overlayHeaderText = <div className='white-text'>面接枠を登録してください！</div>;
    let nsrsPresent = false;

    if (job.applications.length > 0) {
      job.applications.map((application) => {
        if (application.status === "NSRS") {
          nsrsPresent = true
        }
      })
    }
    if (nsrsPresent) {
      overlayHeaderText = <div className='white-text'>面接枠を登録してください！</div>
    }

    let imagesList = [];
    for (let i = 0; i < 5; i++) {
      if (job.work_images[i]) {
        const id = job.work_images[i].id;
        imagesList[i] = <div>
          <div className="already-added-image-container">
            {id && <img alt="close-button" className="close-icon" src={closeIcon} onClick={() => this.deleteOldImages(id)}/>}
            <img alt="tooltip" className="already-added-image" src={job.work_images[i].url} />
          </div>
          {i === 0 && <div style={{color: '#1A9EFF', fontSize: 12, height: 0}}>記事内メイン画像</div>}
        </div>
      } else {
        if (i === 0 && job.default_image) {
          imagesList[0] = <div>
              <div className="already-added-image-container">
                <img alt="tooltip" className="already-added-image" src={job.default_image.url}/>
              </div>
            <div style={{color: '#1A9EFF', fontSize: 12, height: 0}}>記事内メイン画像</div>
          </div>;
        } else {
          imagesList[i] = <div className="image-upload">
            <label htmlFor={"file-input" + job.id + i}>
              <div className="single-job-image" />
            </label>
            <input type="file" accept="image/*" id={"file-input"+job.id+i} onChange={(event) => this.fileChangeHandler(event)} alt={"image-upload"+job.id+i}/>
          </div>;
        }
      }
    }

    const jobTypeUiOption = {
      PT1: {
        color: '#FC6B6D',
        text: 'アルバイト'
      },
      PT2: {
        color: '#28C1DD',
        text: 'アルバイト'
      },
      FT: {
        color: '#1A9EFF',
        text: '正社員'
      },
      SSV: {
        color: '#7B70F7',
        text: '特定技能'
      },
    }

    const isNoSlot = parseInt(job.open_slots_count) === 0;

    return (
      <div className="job-cell-container">
        {(job.status === "RN" && subscriptionData.subscription_status === "IS" && (isNoSlot || nsrsPresent)) && <div id={job.id + "-overlay"} className="special-add-slots-container">
          <div className="text">
            <div className="header">
              <img alt="close-button" className="alert-image" src={alert} />
              {overlayHeaderText}
            </div>
          </div>
          <div className="special-button-container" style={{display: "flex", width: 300, margin: "20px auto"}}>
            <button className="job-button single-button" onClick={() => this.props.history.push('/dashboard/add-interview-times/'+this.props.job.id)}>
              {DashboardTranslations.jobs_page_interview_times_button_text}
            </button>
            <button className="job-button single-button secondary-button" onClick={() => this.hideOverlay(job.id)}>
              仕事の詳細を見る
            </button>
          </div>
        </div>}

        <div className="job-cell-header" style={{alignItems: 'center'}}>
          <div className="header-left">
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <div
                style={{
                  borderRadius: '6px',
                  padding: '3px 8px',
                  background: '#FFFFFF',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: jobTypeUiOption[job.job_type].color
                }}
                className='job-type-button'
              >
                {jobTypeUiOption[job.job_type].text}
              </div>
              <div className="job-name" style={{marginLeft: '10px'}}>
                {job.work_location.brand_name_jp} -  {job.work_location.branch} -  {job.subcategory}
              </div>
            </div>
            <div className="interviewer-name">
              <div>面接官: {job.interview_in_charge.display_name}</div><div className="location-id">　面接場所: {job.interview_location ? job.interview_location.web_id : 'オンライン面接'}</div>
            </div>
            {(job.expiry_timestamp) &&
            <>
              <div className="blank-line-for-mobile" />
              <div className="interviewer-name">
                <div style={{fontSize: 12}}>面接枠の数: {job.open_slots_count} （面接枠はサービス利用期間終了から10日先の{this.convertExpiryTimestamp(job.expiry_timestamp)} までの間で登録してください）</div>
              </div>
            </>}
          </div>
          <div className="header-right">
            <ScaleLoader sizeUnit={"px"} height={35}
              width={3} color={'#FFFFFF'} loading={statusUpdateLoading}
            />
            {!statusUpdateLoading && <div className="switch-inner-container">
              <div className="switch-inner">
              <Switch
                checked={switched}
                onChange={() => nextStatus === "ST" ? this.setState({showJobStopConfirmation: true}) : this.updateStatus(nextStatus, job.id)}
                onColor="#FFFFFF"
                onHandleColor="#007AFF"
                offColor="#FFFFFF"
                offHandleColor="#FE7374"
                uncheckedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 15,
                      color: "#FE7374",
                      paddingRight: 2
                    }}
                  >
                    Off
                  </div>
                }
                checkedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 15,
                      color: "#007AFF",
                      paddingRight: 2
                    }}
                  >
                    On
                  </div>
                }
                className="react-switch"
                id="icon-switch"
              />
              </div>
              <div className="text">{currentStatus}</div>
              <div>
                {
                  (job.interview_location === null) &&
                  <div style={{marginTop: '10px'}}>
                    <img src={videoIcon} alt='Video Icon' style={{height: '15px'}} />
                  </div>
                }
              </div>
            </div>}
          </div>
        </div>
        <div className="job-details-container">
          <div className="job-images-container">
            {imagesList}
          </div>
          <div className="job-action-container">
            <button className="btn btn-primary btn-dimension job-button" onClick={() => this.props.history.push('/dashboard/add-interview-times/'+this.props.job.id)}>
              {DashboardTranslations.jobs_page_interview_times_button_text}
            </button>
            {job.status === "RN" && <button className="btn btn-primary btn-dimension job-button" onClick={() => this.redirect('/dashboard/view-job/'+job.id, job)}>
              {DashboardTranslations.jobs_page_view_job_button_text}
            </button> }
            {job.status !== "RN" && <button className="btn btn-primary btn-dimension job-button" onClick={() => this.redirect('/dashboard/edit-job/'+job.id, job)}>
              {DashboardTranslations.jobs_page_edit_job_button_text}
            </button> }
            <button className="btn btn-primary btn-dimension job-button" onClick={() => this.redirect('/dashboard/copy-job/'+job.id, job)}>
              {DashboardTranslations.jobs_page_copy_job_button_text}
            </button>

          </div>
        </div>

        <ErrorModal error={error} handleClose={this.handleErrorClose.bind(this)} showError={showError}/>
        <ErrorModal error={paymentError} handleClose={this.handleErrorClose.bind(this)} showError={showPaymentError} handleCloseRedirect={this.handleCloseRedirect.bind(this)}/>
        <ConfirmModal confirmAction={this.stopJob.bind(this)}
          confirmationText={DashboardTranslations.jobs_page_stop_job_confirmation_message}
          handleClose={this.closeConfirmation.bind(this)}
          showConfirmationModal={showJobStopConfirmation}/>
        <SuccessModal successMessage={DashboardTranslations.jobs_page_feedback_updated_successfully} handleClose={this.handleClose.bind(this)} showSuccess={this.state.showSuccess}/>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token
    }
};

JobCell = connect(mapStateToProps)(JobCell);


export default JobCell;
