import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    color: "#9e9e9e", // Set the color of the checkbox
    "&$checked": {
        backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg id='CheckIcon'%3e%3cg clip-path='url(%23clip0_14_288)'%3e%3cpath id='Vector' d='M16.8889 29.3333L32.5556 13.6667L29.4444 10.5556L16.8889 23.1111L10.5556 16.7778L7.44445 19.8889L16.8889 29.3333ZM4.44445 40C3.22223 40 2.17556 39.5644 1.30445 38.6933C0.433337 37.8222 -0.00147771 36.7763 3.77287e-06 35.5556V4.44445C3.77287e-06 3.22223 0.435559 2.17556 1.30667 1.30445C2.17778 0.433337 3.22371 -0.00147771 4.44445 3.77287e-06H35.5556C36.7778 3.77287e-06 37.8244 0.435559 38.6956 1.30667C39.5667 2.17778 40.0015 3.22371 40 4.44445V35.5556C40 36.7778 39.5644 37.8244 38.6933 38.6956C37.8222 39.5667 36.7763 40.0015 35.5556 40H4.44445Z' fill='%231A9EFF'/%3e%3c/g%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_14_288'%3e%3crect width='40' height='40' rx='4' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "transparent",
        borderRadius: "4px",
    },
    "&$unchecked": {
      color: "primary", // Set the color when checkbox is checked
    },
    "&$indeterminate": {
      backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_1_2)'%3e%3cmask id='mask0_1_2' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='40' height='40'%3e%3cpath d='M36 0H4C1.79086 0 0 1.79086 0 4V36C0 38.2091 1.79086 40 4 40H36C38.2091 40 40 38.2091 40 36V4C40 1.79086 38.2091 0 36 0Z' fill='white'/%3e%3c/mask%3e%3cg mask='url(%23mask0_1_2)'%3e%3cpath d='M16.8889 29.3333L32.5556 13.6667L29.4444 10.5556L16.8889 23.1111L10.5556 16.7778L7.44445 19.8889L16.8889 29.3333ZM4.44445 40C3.22223 40 2.17556 39.5644 1.30445 38.6933C0.433337 37.8222 -0.00147771 36.7763 3.77288e-06 35.5556V4.44445C3.77288e-06 3.22223 0.435559 2.17556 1.30667 1.30445C2.17778 0.433337 3.22371 -0.00147771 4.44445 3.77288e-06H35.5556C36.7778 3.77288e-06 37.8244 0.435559 38.6956 1.30667C39.5667 2.17778 40.0015 3.22371 40 4.44445V35.5556C40 36.7778 39.5644 37.8244 38.6933 38.6956C37.8222 39.5667 36.7763 40.0015 35.5556 40H4.44445Z' fill='%23F2F3F7'/%3e%3c/g%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_1_2'%3e%3crect width='40' height='40' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ")`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "transparent",
      borderRadius: "4px",
      outline: `2px solid #888`,
    },
  },
  checked: {},
  indeterminate: {
    color: "red",
  },
};

class MyCheckbox extends React.Component {
  render() {
    const { classes, agreeToPolicy, handleCheckbox } = this.props;

    return (
      <Checkbox
        onChange={handleCheckbox("agreeToPolicy")}
        checked={agreeToPolicy}
        classes={{
          root: classes.root,
          checked: classes.checked,
          indeterminate: classes.indeterminate,
        }}
        color="default"
        indeterminate={!agreeToPolicy}
        icon={
          <span
            className={classes.icon}
            style={{
              backgroundImage:
                'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox%3D%220 0 24 24%22%3E%3Cpath d%3D%22M20 5.41L8.71 16.71a.996.996 0 0 1-1.41 0L4 13.41l1.29-1.3 3.3 3.29 10-10L20 5.41z%22%2F%3E%3C%2Fsvg%3E")',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        }
      />
    );
  }
}

export default withStyles(styles)(MyCheckbox);
