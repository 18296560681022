import React from 'react';
// import Homepage from '../components/Homepage';

class NotFound extends React.Component {

  componentDidMount() {
    // this.props.history.push('/register')
  }

  render() {
      return(
        <div>
          <h3>404 page not found</h3>
          <p>We are sorry but the page you are looking for does not exist.</p>
        </div>
      )
  }
}
export default NotFound;
