// import { combineReducers } from 'redux';
import { combineReducers } from 'redux'
import user from './user';
import joyride from './joyride';
// import registerStep from './registerStep';
import language from './language';

export default combineReducers(
	{
    user,
    language,
		joyride,
	}
)


// const wjApp = combineReducers({
//     user,
//     registerStep,
//     language,
//     timeslots,
//     jobApps
//
//     // todos,
//     // visibilityFilter
// });
//
// export default wjApp;
