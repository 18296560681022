import React, { Component } from 'react';
import InputBox from '../components/InputBox.js'
import { connect } from 'react-redux';
import { resetPasswordAPI } from '../helperFunctions/dataServices.js'
import DashboardTranslations from '../../DashboardTranslations.js';
import './styles/resetPassword.scss';
import SuccessModal from '../components/SuccessModal'
import { passwordRegex } from '../../regex';
import {getErrorMessage} from "../../messages/errors";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      resetPasswordToken: null,
      loading: false,
      showSuccess: false,
      successMessage: "",
      token: null,
      submitted: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push('/dashboard/jobs')
    }

    var token = this.props.history.location.pathname.replace('/reset-password/', '')
    this.setState({resetPasswordToken: token})
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    this.setState({submitted: true})

    const payload = {
        token: this.state.resetPasswordToken,
        new_password: this.state.password,
    };
    this.setState({loading: true});

    if (this.state.confirmPassword === this.state.password && passwordRegex.test(this.state.password)) {
      resetPasswordAPI(payload).then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState({showSuccess: true, loading: false,successMessage: "パスワードが再設定されました。"})
        }
        else {
          this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
        }
      })
      .catch((e) => {
        this.setState({loading: false, showError: true})
      })
    }


    event.preventDefault();
  }

  handleClose = () => {
    this.setState({ showSuccess: false, successMessage: '' });
    this.props.history.push('/register')
  };

  render() {
    var { password, confirmPassword, submitted
        } = this.state


    var { showSuccess, successMessage } = this.state

    let resetPasswordDescription = DashboardTranslations.reset_password_form_description.split ('\n').map ((item, i) => <div key={i}>{item}</div>);

    return (
      <div className="reset-password-container">
        <div className="form-container">
          <div className="reset-password-header">
            {DashboardTranslations.reset_password_form_header}
          </div>
          <div className="reset-password-description">
            {resetPasswordDescription}
          </div>
          <div className="fields-container">

            <div className="single-fields-container">
              <InputBox
                value={password}
                errorMessage={DashboardTranslations.register_page_password_error_message}
                error={submitted === true && !passwordRegex.test(password) ? true : false}
                label={DashboardTranslations.reset_password_new_password_label}
                name="password"
                inputType="password"
                handleChange={this.handleChange.bind(this)}
                className="single-field"
              />
            </div>

            <div className="single-fields-container">
              <InputBox
                value={confirmPassword}
                errorMessage={DashboardTranslations.register_page_password_error_message}
                error={submitted === true && !passwordRegex.test(confirmPassword) ? true : false}
                label={DashboardTranslations.register_page_confirm_password_label}
                explanatoryText={DashboardTranslations.register_page_password_explanatory_text}
                name="confirmPassword"
                inputType="password"
                handleChange={this.handleChange.bind(this)}
                className="single-field"
              />
            </div>
            <div className="btn btn-primary" style={{margin: "20px 0px"}} onClick={(event) => this.handleSubmit(event)}>{DashboardTranslations.reset_password_submit_button_text}</div>
          </div>
        </div>
        <SuccessModal successMessage={successMessage} handleClose={this.handleClose.bind(this)} showSuccess={showSuccess}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token
    }
};

ResetPassword = connect(mapStateToProps)(ResetPassword);

export default ResetPassword;
