import React, {Component} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import '../styles/salesMerit.scss'

class SalesMerit extends Component {

  componentDidMount() {
  }

  render() {
    var iconName = this.props.item.name
    var image = ""
    // console.log(name);
    return (
      <div key={this.props.item.id} className="sales-merit-container">
        <Checkbox color="primary" className="radio-button" checked={this.props.selected} onClick={() => this.props.selectedMerits(this.props.item.id)}/>
        <div className="text">
          {iconName} {image}
        </div>
      </div>
    );
  }
}
export default SalesMerit;
