import React, { Component } from 'react';
import InnerHeader from '../components/InnerHeader.js'
import { connect } from 'react-redux';
import { notificationDataAPI } from '../helperFunctions/dataServices.js'
import daysArray from '../helperFunctions/daysArray.js'
import DashboardTranslations from '../../DashboardTranslations.js';
import ScaleLoader from 'react-spinners/ScaleLoader';
import './styles/notifications.scss';

var types = {
  "fixed_interview": "面接予約済み",
  "cancelled_interview": "応募者が面接をキャンセルしました。",
  "nsrs": "新しい面接枠設定がリクエストされてます。",
  "nsre_accepted": "面接日変更済み",
  "seeker_message": "応募者からのメッセージ",
}

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationData: [],
      loading: false,
    }
  }

  componentDidMount() {
    this.notificationData()
  }

  initializeReactGA() {

  }

  notificationData() {
    this.setState({loading: true})
    notificationDataAPI().then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        this.setState({notificationData: response.data, loading: false})
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  backArrowOnClick() {
    this.props.history.goBack();
  }

  convert(timestamp) {
    var date = new Date(timestamp*1000);
    var cDate = date.getDate();
    var cMonth = date.getMonth() + 1;
    var cYear = date.getFullYear();
    var day = date.getDay();
    var dayString = daysArray.days[day].name
    var hour = date.getHours()
    var minutes = date.getMinutes()
    if (parseInt(hour)<10) {
      hour = "0" + hour
    }
    if (parseInt(minutes)<10) {
      minutes = "0" + minutes
    }
    var finalDate = cYear+"/"+cMonth+"/"+cDate+" ("+dayString+") "+hour+":"+minutes+" "

    if (timestamp !== null) {
      return finalDate;
    }
    else {
      return "";
    }

  }

  render() {
    var { notificationData, loading,
    } = this.state

    var notificationList
    if (notificationData.length > 0) {
      notificationList = notificationData.map((single, index) => {
        var url= "/dashboard/applicant-details/"+single.job_application
        if (single.job_application === null) {
          url= "/dashboard/applicant-details/"+single.cancelled_job_application+"/cancelled"
        }
        var className = "single-notification"
        if (!single.is_read) {
          className = "single-notification not-read"
        }
        return (
          <div className={className}>
            <div className="notification-type">
              {types[single.notification_type]}
            </div>
            <div className="notification-message">
              {this.convert(single.created_at)}
            </div>
            <div className="notification-message">
              応募者<a href={url} target="_blank" >詳細はこちら</a>
            </div>
            <div className="notification-message">
              {single.notification_text}
            </div>
          </div>
        )
      })
    }

    return (
      <div className="notifications-container">
        <InnerHeader
          primary={true}
          history={this.props.history}
          headerText="お知らせ"
          backArrowOnClick={this.backArrowOnClick.bind(this)}
        />
        <ScaleLoader sizeUnit={"px"} height={50}
          width={4} color={'#007AFF'} loading={loading}
        />
        <div className="notification-list">
          {notificationList}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token,
        joyride: state.joyride,
        user: state.user
    }
};

Notifications = connect(mapStateToProps)(Notifications);

export default Notifications;
