import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DashboardTranslations from "../../DashboardTranslations.js";
import closeIcon from "../../images/close-icon.png";
import "../styles/ScreeningModal.scss";

const ScreeningModal = (props) => (
  <div>
    <Dialog
			open={props.showScreening}
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className="success-modal"
			PaperProps={{ style: {
				maxHeight: '90%'
			}}}
			style={{zIndex: 99}}
		>
			{props.newStatus === 'ESF' && <div className="screening-body">
					<div className="screening-title">
					選考不合格にしますか？
					</div>
					<div className="screening-text">
					一度、選考不合格にすると、<br/>この応募者はこの求人に応募できなくなります。
					</div>
					<div className="screening-btn-container">
						<div className="screening-btn" onClick={props.handleClose}>
							キャンセル
						</div>
						<div className="screening-btn" onClick={props.confirmAction}>
							選考不合格にする
						</div>
					</div>
				</div>}
			{props.newStatus === 'ESP' && <div className="screening-body">
					<div className="screening-title">
						応募者を面接に招待する
					</div>
					<div className="screening-text">
						面接日を選択するよう応募者に通知を送信します。応募者が面接日を選択すると、管理画面でお知らせします。
					</div>
					<div className="screening-btn-container">
						<div className="screening-btn" onClick={props.handleClose}>
							キャンセル
						</div>
						<div className="screening-btn" onClick={props.confirmAction}>
							送信する
						</div>
					</div>
				</div>}
			{props.newStatus === 'NO_SLOT' && <div className="screening-body">
					<div className="screening-title">
						面接枠を登録してください
					</div>
					<div className="screening-text">
						面接枠が登録されていないため、応募者を面接に招待することができません。面接枠を登録して、再度 
						<div className='option-container'>
							<svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
								<path d="M0.429211 4.96002C0.220211 4.77002 0.220211 4.45002 0.429211 4.25002C0.649211 4.06002 1.00121 4.06002 1.21021 4.25002L3.49821 6.33002L9.79021 0.610022C9.99921 0.410022 10.3512 0.410022 10.5712 0.610022C10.7802 0.800022 10.7802 1.12002 10.5712 1.32002L3.88321 7.39002C3.67421 7.59002 3.32221 7.59002 3.10221 7.39002L0.429211 4.96002Z" fill="white"/>
							</svg>
							<div className='option-text'>面接する</div>
						</div>
						ボタンよりお進みください。
					</div>
					<div className="screening-btn-container">
						<div className="screening-btn" onClick={props.handleClose}>
							戻る
						</div>
						<div className="screening-btn" onClick={props.confirmAction}>
							面接枠を登録する
						</div>
					</div>
				</div>}
		</Dialog>
  </div>
);

export default ScreeningModal;
