import React, { Component } from 'react';
import { connect } from 'react-redux';

import InfoImage3 from '../../images/info-modal-image-3.png'
import './infoModalOutOfSubscription.scss';

class InfoModalOutOfSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {

    return (
      <div className="info-modal-out-of-subscription">
        <div className="modal-outer-container">
          <div className="modal-inner-container">
            <div className="header">
              お支払方法について
            </div>

            <div className="sub-header">
              お支払いはクレジットカード払いか請求書払いを選べます。<br /><br />

              請求書払いは審査に最大5日ほどかかりますので<br />
              すぐに掲載を開始できるクレジットカード払いをオススメします。<br /><br />

              ◆ ご利用期間について ◆<br />
              クレジットカード払いの場合はお支払日から28日間、<br />
              請求書払いの場合は審査が通過した日から28日間となります。<br /><br />

              あらかじめ記事を作成しておいてからお支払いいただくと<br />
              28日間フルに無駄なくご利用いただけます。<br /><br />
            </div>

            <img className="info-image-1" src={InfoImage3} />


            <button style={{backgroundColor: "#FFFFFF",margin: "10px auto", fontSize: 17, borderRadius: "12px", width: "300px", border: "1px solid #007Aff", color: "#007AFF"}} onClick={() => this.props.closeInfoModalOutOfSubscription()}>
              閉じる
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.user.isLoggedIn,
    }
};

InfoModalOutOfSubscription = connect(mapStateToProps)(InfoModalOutOfSubscription);

export default InfoModalOutOfSubscription;
