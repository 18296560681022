import React, { Component } from 'react';
import '../styles/dashboardLanding.scss';

class DashboardLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    }
  }

  componentDidMount() {
    this.props.history.push('/dashboard/jobs')
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {

    return (
      <div className="dashboard-container">
      </div>
    );
  }
}

export default DashboardLanding;
