import React, { Component } from 'react';
import InputBox from '../components/InputBox.js'
import { forgotPasswordAPI } from '../helperFunctions/dataServices.js'
import DashboardTranslations from '../../DashboardTranslations.js';
import './styles/forgotPassword.scss';
import ErrorModal from '../components/ErrorModal'
import { connect } from 'react-redux';
import SuccessModal from '../components/SuccessModal'
import { emailRegex } from '../../regex';
import {getErrorMessage} from "../../messages/errors";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
      showSuccess: false,
      successMessage: "",
      showError: false,
      error: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push('/dashboard/jobs')
    }
  }

  handleErrorClose = () => {
    this.setState({showError: false, error: '', email: ""})
  }

  handleClose = () => {
    this.setState({ showSuccess: false, successMessage: '' });
    this.props.history.push('/register')
  };


  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    this.setState({submitted: true})
    const payload = {email: this.state.email};
    this.setState({loading: true});
    if (emailRegex.test(this.state.email)) {
      forgotPasswordAPI(payload).then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState({showSuccess: true, successMessage: "パスワード再設定用のメールを登録されたアカウントに送信しました。"})
        }
        else {
          this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
        }
      })
      .catch((e) => {
        this.setState({loading: false, showError: true})
      })
    }
    event.preventDefault();
  }



  render() {
    var { email,
        } = this.state

    var { showSuccess, successMessage, submitted, showError, error } = this.state

    let forgotPasswordDescription = DashboardTranslations.forgot_password_form_description.split ('\n').map ((item, i) => <div key={i}>{item}</div>);

    return (
      <div className="forgot-password-container">
        <div className="main-image-container">
          <div className="page-header">{DashboardTranslations.forgot_password_page_header}</div>
        </div>
        <div className="form-container">
          <div className="reset-password-header">
            {DashboardTranslations.forgot_password_form_header}
          </div>
          <div className="reset-password-description">
            {forgotPasswordDescription}
          </div>
          <div className="fields-container">
            <div className="single-fields-container">
              <InputBox
                errorMessage={DashboardTranslations.register_page_email_error_message}
                error={submitted === true && !emailRegex.test(email) ? true : false}
                label={DashboardTranslations.register_page_email_label}
                name="email"
                value={email}
                handleChange={this.handleChange.bind(this)}
                className="single-field"
              />
            </div>

            <div className="btn btn-primary" style={{margin: "20px 0px"}} onClick={(event) => this.handleSubmit(event)}>{DashboardTranslations.forgot_password_submit_button_text}</div>
            <SuccessModal successMessage={successMessage} handleClose={this.handleClose.bind(this)} showSuccess={showSuccess}/>
          </div>
        </div>
        <ErrorModal error={error} handleClose={this.handleErrorClose.bind(this)} showError={showError}/>


      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token
    }
};

ForgotPassword = connect(mapStateToProps)(ForgotPassword);

export default ForgotPassword;
