import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addCompanyDetailsAPI, initialize } from '../helperFunctions/dataServices.js'
import { setRegisterStep } from '../../actions';
import ErrorModal from '../components/ErrorModal'
import CompanyDetailsPart1 from './components/CompanyDetailsPart1'
import CompanyDetailsPart2 from './components/CompanyDetailsPart2'
import CompanyDetailsPart3 from './components/CompanyDetailsPart3'
import CompanyDetailsPart4 from './components/CompanyDetailsPart4'
import CompanyDetailsPart5 from './components/CompanyDetailsPart5'
import { katakanaRegex } from '../../regex';

import './styles/initialCompanyDetails.scss';
import {getErrorMessage} from "../../messages/errors";

var stages = [
  {id:1, name: "会社情報"},
  {id:2, name: "住所"},
  {id:3, name: "代表者様情報"},
  {id:4, name: "担当者様情報"},
]

var lotNoPattern = new RegExp(/^\d+(-\d+){0,3}$/)

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStage: 1,
      options: [],
      companyNameEn: "",
      companyNameKT: "",
      companyNameJp: "",
      companyPhoneNumber: "",
      dispatchAgency: false,
      industry: "",
      industryType: "",
      industryOtherValue: "",
      showIndustryOther: false,
      postalCode: "",
      postalCodeId: 0,
      prefecture: "",
      city1: "",
      city2: "",
      lotNo: "",
      loading: false,
      buildingNameJp: "",
      adminCEOSame: false,
      adminFirstName: "",
      adminLastName: "",
      adminFirstNameKT: "",
      adminLastNameKT: "",
      adminTelephoneNumber: "",
      CEOFirstName: "",
      CEOLastName: "",
      CEOFirstNameKT: "",
      CEOLastNameKT: "",
      stage1Submitted: false,
      stage2Submitted: false,
      stage3Submitted: false,
      stage4Submitted: false,
      showError: false,
    }
  }

  componentDidMount() {
    this.initializeCompany()
  }

  initializeCompany() {
    this.setState({loading: true})
    initialize().then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        this.setState({options: response.data, loading: false})
    	}
    	else {
    		this.setState({loading: false, showError: true, error: getErrorMessage('from_api_response', {'apiErrorMessage': response.data.error})})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  nextStage() {
    var { currentStage, companyNameEn, companyNameJp, companyNameKT, companyPhoneNumber,
      dispatchAgency, city1, city2, prefecture, lotNo, showIndustryOther, postalCodeId, industryType, industryOtherValue,
      CEOLastName, CEOFirstName, CEOFirstNameKT, CEOLastNameKT,
      adminCEOSame, adminLastName, adminFirstName, adminLastNameKT, adminFirstNameKT
    } = this.state

    if (currentStage === 1) {
      if (companyNameEn.length > 0 && companyNameJp.length > 0 && katakanaRegex.test(companyNameKT)) {
        if (companyPhoneNumber.length === 10 || companyPhoneNumber.length === 11) {
          this.setState({currentStage: this.state.currentStage+1})
        }
        else {
          this.setState({stage1Submitted: true})
        }
      }
      else {
        this.setState({stage1Submitted: true})
      }
    }
    else if (currentStage === 2) {
      if (lotNoPattern.test(lotNo) && city1 !== '' && city2 !== '' && prefecture !== '' && parseInt(postalCodeId) !== 0) {
        if (!dispatchAgency) {
          if (industryType !== "") {
            if (showIndustryOther) {
              if (industryOtherValue.length > 0) {
                this.setState({currentStage: this.state.currentStage+1})
              }
              else {
                this.setState({stage2Submitted: true})
              }
            }
            else {
              this.setState({currentStage: this.state.currentStage+1})
            }
          }
          else {
            this.setState({stage2Submitted: true})
          }
        }
        else {
          this.setState({currentStage: this.state.currentStage+1})
        }
      }
      else {
        this.setState({stage2Submitted: true})
      }
    }
    else if (currentStage === 3) {
      if (CEOLastName.length > 0 && CEOFirstName.length > 0 && katakanaRegex.test(CEOFirstNameKT) && katakanaRegex.test(CEOLastNameKT)) {
        this.setState({currentStage: this.state.currentStage+1})
      }
      else {
        this.setState({stage3Submitted: true})
      }
    }
    else if (currentStage === 4) {
      if (adminCEOSame === true) {
        this.validateAllValues()
      }
      else {
        if (adminLastName.length > 0 && adminFirstName.length > 0 && katakanaRegex.test(adminFirstNameKT) && katakanaRegex.test(adminLastNameKT)) {
          this.validateAllValues()
        }
        else {
          this.setState({stage4Submitted: true})
        }
      }
    }

  }

  validateAllValues() {
    var { companyNameEn, companyNameJp, companyNameKT, companyPhoneNumber, dispatchAgency, city1, city2, prefecture,
      lotNo, showIndustryOther, postalCodeId, industryType, industryOtherValue,
      CEOLastName, CEOFirstName, CEOFirstNameKT, CEOLastNameKT, buildingNameJp, adminTelephoneNumber,
      adminCEOSame, adminLastName, adminFirstName, adminLastNameKT, adminFirstNameKT
    } = this.state
    var payload = {}
    payload.company_name_en = companyNameEn
    payload.company_name_jp = companyNameJp
    payload.company_name_kt = companyNameKT
    payload.company_telephone = companyPhoneNumber

    payload.company_is_dispatch_agency = dispatchAgency
    if (this.state.dispatchAgency === true) {
      // console.log('log');
    }
    else {
      payload.company_industry = industryType
      if (showIndustryOther === true) {
        payload.company_custom_industry = industryOtherValue
      }
    }
    payload.company_postcode_id = postalCodeId
    payload.company_prefecture_id = prefecture
    payload.company_city_id = city1
    payload.company_ward_id = city2
    payload.company_lot_num = lotNo
    payload.company_building_name_jp = buildingNameJp ? buildingNameJp : null

    payload.ceo_first_name = CEOFirstName
    payload.ceo_last_name = CEOLastName
    payload.ceo_first_name_kt = CEOFirstNameKT
    payload.ceo_last_name_kt = CEOLastNameKT

    payload.admin_first_name = adminCEOSame ? CEOFirstName : adminFirstName
    payload.admin_last_name = adminCEOSame ? CEOLastName : adminLastName
    payload.admin_first_name_kt = adminCEOSame ? CEOFirstNameKT : adminFirstNameKT
    payload.admin_last_name_kt = adminCEOSame ? CEOLastNameKT : adminLastNameKT
    if (adminTelephoneNumber.length === 10 || adminTelephoneNumber.length === 11) {
      payload.admin_telephone = adminTelephoneNumber
    }

    this.addCompanyDetails(payload);
  }

  addCompanyDetails(payload) {
    this.setState({loading: true})
    addCompanyDetailsAPI(payload).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.props.dispatch(setRegisterStep(4))
        this.setState({currentStage: 5, loading: false})
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? response.response.data.error : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  previousStage() {
    this.setState({currentStage: this.state.currentStage-1})
  }

  closeModal() {
    this.props.closeModal()
  }

  updateValues(name, value) {
    this.setState({[name]: value})
  }

  handleClose() {
    this.setState({showError: false})
  }

  render() {
    var { currentStage, options, companyNameEn, companyNameJp, companyNameKT, companyPhoneNumber, stage1Submitted,
      dispatchAgency, postalCode, city1, city2, prefecture, lotNo, postalCodeId, buildingNameJp, industryType, industryOtherValue, stage2Submitted,
      CEOLastName, CEOFirstName, CEOFirstNameKT, CEOLastNameKT, stage3Submitted, showIndustryOther, loading, error,
      adminCEOSame, adminLastName, adminFirstName, adminLastNameKT, adminFirstNameKT, stage4Submitted, adminTelephoneNumber
    } = this.state

    var stageList= stages.map(stage => {
      return (
        <div id={stage.id} className="single-stage">
          <div
            style={{
              backgroundColor: stage.id === currentStage ? "#263242" : "#FFFFFF",
              color: stage.id === currentStage ? "#FFFFFF" : "#263242"
            }}
            className="stage-id">{stage.id}</div>
          <div
            style={{
              color: stage.id === currentStage ? "#333333" : "#858585"
            }}
            className="stage-name">{stage.name}</div>
        </div>
      )
    })

    return (
      <div className="initial-company-details-modal-container">
        <div className="modal-outer-container">
          <div className="modal-inner-container">
            <div className="company-details-header-container">
              <div className="header">
                {currentStage !== 5 ? "WORK JAPANにようこそ！" : "登録が完了しました！"}
              </div>
              <div className="sub-header">
                {currentStage !== 5 ? "求人記事の作成の前に貴社の情報をご登録ください" : "登録が完了しました！"}
              </div>
            </div>
            {currentStage !== 5 && <div className="stage-container">
              {stageList}
            </div>}
            {currentStage === 1 &&
              <CompanyDetailsPart1
                options={options}
                companyNameEn={companyNameEn}
                companyNameJp={companyNameJp}
                companyNameKT={companyNameKT}
                companyPhoneNumber={companyPhoneNumber}
                updateValues={this.updateValues.bind(this)}
                submitted={stage1Submitted}
              />
            }
            {currentStage === 2 &&
              <CompanyDetailsPart2
                dispatchAgency={dispatchAgency}
                postalCode={postalCode}
                postalCodeId={postalCodeId}
                city1={city1}
                city2={city2}
                prefecture={prefecture}
                lotNo={lotNo}
                industryType={industryType}
                industryOtherValue={industryOtherValue}
                buildingNameJp={buildingNameJp}
                showIndustryOther={showIndustryOther}
                options={options}
                submitted={stage2Submitted}
                updateValues={this.updateValues.bind(this)}
              />
            }
            {currentStage === 3 &&
              <CompanyDetailsPart3
                CEOLastName={CEOLastName}
                CEOFirstName={CEOFirstName}
                CEOLastNameKT={CEOLastNameKT}
                CEOFirstNameKT={CEOFirstNameKT}
                submitted={stage3Submitted}
                updateValues={this.updateValues.bind(this)}
                options={options}
              />
            }
            {currentStage === 4 &&
              <CompanyDetailsPart4
                adminCEOSame={adminCEOSame}
                adminLastName={adminLastName}
                adminFirstName={adminFirstName}
                adminLastNameKT={adminLastNameKT}
                adminFirstNameKT={adminFirstNameKT}
                adminTelephoneNumber={adminTelephoneNumber}
                submitted={stage4Submitted}
                updateValues={this.updateValues.bind(this)}
                options={options}
              />
            }
            {currentStage === 5 && <CompanyDetailsPart5 loading={loading} options={options}/>}
            <ErrorModal error={error} handleClose={this.handleClose.bind(this)} showError={this.state.showError}/>
            <div className="details-page-button-container">
              {currentStage !== 1 && currentStage !== 5 && <div className="previous-button" onClick={()=> this.previousStage()}>
                戻る
              </div>}
              {currentStage === 1 && currentStage !== 5 && <div className="dummy-button">
              </div>}
              {currentStage !== 5 && <button className="next-button" onClick={()=> this.nextStage()}>
                {currentStage !== 4 ? "次へ" : "次へ"}
              </button>}
            </div>
            {currentStage === 5 && <button className="company-details-close-button" onClick={()=> this.closeModal()}>
              記事を作成する
            </button>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.user.isLoggedIn,
    }
};

Register = connect(mapStateToProps)(Register);

export default Register;
