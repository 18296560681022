import React, {Component} from 'react';
import NoImage from '../../images/NoImage.png';
import {initializeJob} from '../helperFunctions/dataServices.js';
import SalesMeritsIconList from '../helperFunctions/SalesMeritsIconList';
import DashboardTranslations from '../../DashboardTranslations.js';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {connect} from 'react-redux';
import './styles/previewJobs.scss';
import {job} from "../helperFunctions/dataServices";
import InputBox from "../components/InputBox";
import {disabledFields} from "./constants";


var meritList = [
  {id: 1, name: "おすすめポイント", value: []},
  {id: 2, name: "働く時間帯", value: []},
  {id: 3, name: "外国人にやさしい", value: []},
  {id: 4, name: "将来性", value: []},
  {id: 5, name: "簡単/シンプル", value: []},
  {id: 7, name: "通勤について", value: []}
];

var days = {
  "Sun": "日",
  "Mon": "月",
  "Tue": "火",
  "Wed": "水",
  "Thu": "木",
  "Fri": "金",
  "Sat": "土",
}

var jpLevelOptions = {
  "0": "日本語力不問",
  "1": "日常会話",
  "2": "流暢",
  "3": "ビジネスレベル",
}

var qualificationOptions = {
  "2": "高校",
  "3": "専門学校",
  "4": "大学",
  "5": "修士課程",
  "6": "博士課程",
}


var engLevelOptions = {
  "0": "日常会話",
  "1": "流暢",
  "2": "ビジネスレベル"
}

var visaChangeOptions = {
  'Yes': "可",
  'No': "不可",
  'Conditional': "パフォーマンスによる",
}

var remoteJobOptions = {
  'Fully': "完全リモート",
  'Partially': "一部リモート",
  'Conditional': "なし",
}

var socialInsuranceOptions = {
  'true': "有り",
  'false': "無し",
}

class PreviewJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobsData: {},
      jobResponse: {},
      interviewLocationData: {},
      workLocationData: {},
      newMeritList: meritList,
      merits_groups: {},
      onlineIVTelephone: ''
    }
  }

  getValueOrNA(wrapped) {
    return function() {
      try {
        return wrapped.apply(this, arguments);
      } catch (TypeError) {
        return DashboardTranslations.not_applicable_text
      }
    }
  }

  isDisabled (fieldName) {
    if(!this.state.jobType) {
      this.setJobType(this.isDisabled)
    } else {
      return disabledFields[this.props.jobType].includes(fieldName)
    }
  }

  isInvisible(section) {
    const invisibleSectionMapper = {
      PT1: ['companyInfo', 'requirement', 'ssvSpecific', 'supportVisaChange', 'holidays', 'welfare'],
      PT2: ['ssvSpecific', 'holidays', 'welfare'],
      FT: ['ssvSpecific'],
      SSV: [],
    }
    return invisibleSectionMapper[this.props.jobType].includes(section)
  }

  componentDidMount() {
    this.initializeJobs();
  }

  initializeJobs() {
    this.setState({loading: true})
    initializeJob().then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        var jobsOptions = response.data
        this.setState({jobResponse: jobsOptions})
        var meritCategories = jobsOptions.merit_categories
        for (var category in meritCategories) {
          meritCategories[category] = meritCategories[category].map(item => {
            item.iconName = SalesMeritsIconList[item.icon_name] ? SalesMeritsIconList[item.icon_name] : '';
            return item
          })
        }
        var newMeritList = meritList.map((singleMerit, index) => {
          var single = {id: index, name: singleMerit.name, value: meritCategories[singleMerit.name]}
          return single;
        })
        this.setState({
          loading: false,
          merits_groups: meritCategories,
          newMeritList: newMeritList
        }, () => this.drawSalesMerits())
      } else {
        this.setState({loading: false})
      }
    })
      .catch((e) => {
        this.setState({loading: false})
      })
  }

  drawSalesMerits() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        jobsData: this.props.jobsData,
        interviewLocationData: this.props.interviewLocationData,
        onlineIVTelephone: this.props.onlineIVTelephone,
        workLocationData: this.props.workLocationData
      }, () => this.drawSalesMerits(), this.props.maintainHeight('job-detail-text'));
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps !== prevState) {
      return nextProps
    } else return null;
  }

  getIndustryName(industryId) {
    if(!industryId) return ''
    return this.state.jobResponse.industries.find(
      x => String(x.id) === String(industryId)
    ).name
  }

  getCompanyInfo() {
    if(this.props.adJobData.company_info_id) {
      return this.state.jobResponse.company_infos.find(
        info => String(info.id) === String(this.props.adJobData.company_info_id)
      )
    }
    return this.props.adJobData.company_info
  }

  getSSVIndustryName(industryId) {
    if(!industryId) return ''
    return this.state.jobResponse.ssv_industries.find(
      x => String(x.id) === String(industryId)
    ).name
  }

  getSkillName(skillId) {
    if(!skillId) return ''
    return this.state.jobResponse.skills.find(
      x => String(x.id) === String(skillId)
    ).name
  }

  getPositionName(positionId) {
    if(!positionId) return ''
    return this.state.jobResponse.positions.find(
      x => String(x.id) === String(positionId)
    ).name
  }

  getNationalityName(nationalityId) {
    if(!nationalityId) return ''
    return this.state.jobResponse.nationality_choices.find(
      x => String(x.id) === String(nationalityId)
    ).name
  }

  getCertificateName(certificateId) {
    if(!certificateId) return ''
    return this.state.jobResponse.certificates.find(
      x => String(x.id) === String(certificateId)
    ).name
  }

  getWhoCanApplyName(acceptanceId) {
    if(!acceptanceId) return ''
    return this.state.jobResponse.who_can_apply.find(
      x => String(x.id) === String(acceptanceId)
    ).name
  }

  getInterviewerDisplayName(interviewerId) {
    if(!interviewerId) return ''
    return this.state.jobResponse.members.find(
      x => String(x.id) === String(interviewerId)
    ).display_name
  }

  getVisaTypeName(visaId) {
    if(!visaId) return ''
    return this.state.jobResponse.visa_types.find(
      x => String(x.id) === String(visaId)
    ).name
  }

  getHolidayName(holidayId) {
    if(!holidayId) return ''
    return this.state.jobResponse.holidays.find(
      x => String(x.id) === String(holidayId)
    ).name
  }

  render() {

    let {workLocationData, interviewLocationData, jobsData, onlineIVTelephone} = this.state;

    let images =
      <div style={{
        height: 200,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: `url(${NoImage})`,
        border: "2px solid #ED5356"
      }}>
        <div style={{fontSize: 13, textAlign: "center", color: "#ED5356"}}>
          <div style={{margin: "20px auto", fontWeight: "bold", paddingTop: 20}}>
            ＜写真を掲載して応募数を増やしましょう！＞
          </div>
          <div style={{fontSize: 11, textAlign: "center", fontWeight: "bold"}}>
            仕事内容が伝わる写真を掲載しましょう！<br/>
            掲載数（5枚まで）が多いほど応募が増えます！
          </div>
        </div>
      </div>

    if (jobsData.imageList && jobsData.imageList.length > 0) {
      let carousel = jobsData.imageList.map((pic, index) => {
        return (
          <div key={index}>
            <img src={pic} style={{height: 200, width: "auto", maxWidth: "100%"}} alt={index}/>
            {index === 0 && <div style={{color: '#1A9EFF', fontSize: 12, marginTop: 5}}>この写真がメイン画像として求人記事に掲載されます</div>}
          </div>
        );
      })

      images =
          <Carousel
          showThumbs={false}
        >
          {carousel}
        </Carousel>
    }

    let trainingSalary = DashboardTranslations.not_applicable_text
    let salary = DashboardTranslations.not_applicable_text
    let brandNameJp = DashboardTranslations.add_location_brand_name_jp_label
    let brandNameEn = DashboardTranslations.add_location_brand_name_en_label

    if (workLocationData.brand_name_jp) {
      brandNameJp = workLocationData.brand_name_jp
    }
    if (workLocationData.brand_name_en) {
      brandNameEn = workLocationData.brand_name_en
    }

    if (jobsData.hourly_salary_max && jobsData.hourly_salary_min) {
      salary = "¥ " + jobsData.hourly_salary_min + " - " + jobsData.hourly_salary_max + " / 時間"
    }
    if (jobsData.training_hourly_salary_min && jobsData.training_hourly_salary_max) {
      trainingSalary = "¥ " + jobsData.training_hourly_salary_min + " - " + jobsData.training_hourly_salary_max + " / 時間"
    }

    let workLocation = DashboardTranslations.not_applicable_text
    let interviewLocation = DashboardTranslations.not_applicable_text
    let interviewStation = DashboardTranslations.not_applicable_text
    if (interviewLocationData.nearest_station) {
      interviewStation = interviewLocationData.nearest_station
    }
    let workStation = DashboardTranslations.not_applicable_text
    if (workLocationData.nearest_station) {
      workStation = workLocationData.nearest_station
    }
    if (workLocationData) {
      var {postcode, city, ward, prefecture, building_name_jp, lot_num} = workLocationData
      if (postcode) {
        workLocation = "〒" + postcode + ", "
      }
      if (prefecture) {
        workLocation = workLocation + prefecture + ", "
      }
      if (city) {
        workLocation = workLocation + city + ", "
      }
      if (ward) {
        workLocation = workLocation + ward + ", "
      }
      if (lot_num) {
        workLocation = workLocation + lot_num + ", "
      }
      if (building_name_jp) {
        workLocation = workLocation + building_name_jp + ", "
      }
      if (workLocation === "") {
        workLocation = DashboardTranslations.not_applicable_text
      }
      workLocation = workLocation.replace(/,([^,]*)$/, '$1');
    }

    if (interviewLocationData) {
      var {postcode, city, ward, prefecture, building_name_jp, lot_num} = interviewLocationData
      if (postcode) {
        interviewLocation = "〒" + postcode;
      }
      if (prefecture) {
        interviewLocation += ', ' + prefecture;
      }
      if (city) {
        interviewLocation += ', ' + city;
      }
      if (ward) {
        interviewLocation += ', ' + ward;
      }
      if (lot_num) {
        interviewLocation += ', ' + lot_num;
      }
      if (building_name_jp) {
        interviewLocation += ', ' + building_name_jp;
      }
      if (interviewLocation === "") {
        interviewLocation = DashboardTranslations.not_applicable_text
      }
    }

    let shifts = DashboardTranslations.not_applicable_text
    if (jobsData.shifts) {
      shifts = jobsData.shifts.map((singleShift, index) => {
        var newShift = singleShift.days.map(singleDay => {
          return days[singleDay];
        })
        return (
          <div className="single-time value" key={index} style={{}}>
            <div className="label">{newShift.join(", ")} : {singleShift.timeFrom + " ~ " + singleShift.timeTo}</div>
          </div>
        );
      })
    }

    const na_text = '未入力'

    return (
      Boolean(Object.keys(this.state.jobResponse).length) && <div className="preview-jobs-container">
          <div style={{marginTop: '20px'}}>

            {
              !this.isInvisible('companyInfo') &&
              <div>
                <div className='header'>
                  会社情報
                </div>
                <hr/>
                <div className='d-flex'>
                  <div className='flex-auto'>
                    {
                      !this.isDisabled('companyNameJp') &&
                      <div>
                        <div className='key-text'>
                          会社名（日本語表記）
                        </div>
                        <div>
                          {this.getCompanyInfo().name_jp || na_text}
                        </div>
                      </div>
                    }

                    {
                      !this.isDisabled('companyNameEn') &&
                      <div className='mt-10'>
                        <div className='key-text'>
                          会社名（英語表記）
                        </div>
                        <div>
                          {this.getCompanyInfo().name_en || na_text}
                        </div>
                      </div>
                    }

                    {
                      !this.isDisabled('companyIndustryType') &&
                      <div className='mt-10'>
                        <div className='key-text'>
                          業種
                        </div>
                        <div>
                          {this.getIndustryName(this.getCompanyInfo().industry) || na_text}
                        </div>
                      </div>
                    }

                    {
                      !this.isDisabled('companyEmployee') &&
                      <div className='mt-10'>
                        <div className='key-text'>
                          社員数（人）
                        </div>
                        <div>
                          {this.getCompanyInfo().num_of_employees || na_text}
                        </div>
                      </div>
                    }

                    {
                      !this.isDisabled('nationalities') &&
                      <div className='mt-10'>
                        <div className='key-text'>
                          勤務中の外国人社員の国籍
                        </div>
                        <div>
                          {
                            this.getCompanyInfo().nationalities_hired.length ? (
                              this.getCompanyInfo().nationalities_hired.map(x => <div>{this.getNationalityName(x)}</div>)
                            ) : na_text
                          }
                        </div>
                      </div>
                    }

                    {
                      !this.isDisabled('website') &&
                      <div className='mt-10'>
                        <div className='key-text'>
                          ホームページ
                        </div>
                        <div>
                          {this.getCompanyInfo().website || na_text}
                        </div>
                      </div>
                    }

                  </div>
                  <div>
                    <button className='redirect-button' onClick={() => this.props.redirectToStage(3)}>編集</button>
                  </div>
                </div>
              </div>
            }

            <div className='mt-20'>
              <div className='header'>
                勤務地
              </div>
              <hr/>
              <div className='d-flex'>
                <div className='flex-auto'>
                  <div>
                    <div className='key-text'>
                      ブランド名（日本語表記）
                    </div>
                    <div>
                      {brandNameJp}
                    </div>
                  </div>
                  <div className='mt-10'>
                    <div className='key-text'>
                      ブランド名（英語表記）
                    </div>
                    <div>
                      {brandNameEn}
                    </div>
                  </div>
                  <div className='mt-10'>
                    <div className='key-text'>
                      住所
                    </div>
                    <div>
                      {workLocationData.address ? workLocationData.address : workLocation}
                    </div>
                  </div>
                  <div className='mt-10'>
                    <div className='key-text'>
                      最寄駅
                    </div>
                    <div>
                      {`${workStation}駅 ${(workLocationData.commuteType === 'BS') ? 'バス' : '徒歩'}${workLocationData.minutes}分`}
                    </div>
                  </div>
                  <div className='mt-10'>
                    <div className='key-text'>
                      電話番号
                    </div>
                    <div>
                      {workLocationData.phoneNumber}
                    </div>
                  </div>
                  <div className='mt-10'>
                    <div className='key-text'>
                      風営法対象職種
                    </div>
                    <div>
                      {(workLocationData.is_fuei) ? '該当職種' : '対象外'}
                    </div>
                  </div>
                </div>
                <div>
                  <button className='redirect-button' onClick={() => this.props.redirectToStage(1)}>編集</button>
                </div>
              </div>
            </div>

            <div className='mt-20'>
              <div className='header'>
                面接方法
              </div>
              <hr/>
              <div className='d-flex'>
                <div className='flex-auto'>
                  <div>
                    <div className='key-text'>
                      面接担当者
                    </div>
                    <div>
                      {this.getInterviewerDisplayName(this.props.adJobData.interview_in_charge)}
                    </div>
                  </div>
                  <div className='mt-10'>
                    <div className='key-text'>
                      面接場所
                    </div>
                    <div>
                      {this.props.isSameLocation ?
                          (workLocationData.address ? workLocationData.address : workLocation) :
                          (
                              Object.keys(interviewLocationData).length > 0 ?
                                  (interviewLocationData.address ? interviewLocationData.address : interviewLocation) :
                                  <>
                                    オンライン面接<br/>
                                    <span style={{color: '#9B9B9B', fontSize: 10}}>
                                      ZoomやSkypeなど面接方法の連絡が必要です。
                                    </span>
                                  </>
                          )
                      }
                    </div>
                  </div>

                  {
                    !onlineIVTelephone &&
                    <div className='mt-10'>
                      <div className='key-text'>
                        最寄駅
                      </div>
                      <div>
                        {
                          this.props.isSameLocation ?
                              `${workStation}駅 ${(workLocationData.commuteType === 'BS') ? 'バス' : '徒歩'}${workLocationData.minutes}分` :
                              `${interviewStation}駅 ${(interviewLocationData.commuteType === 'BS') ? 'バス' : '徒歩'}${interviewLocationData.minutes}分`
                        }
                      </div>
                    </div>
                  }

                  <div className='mt-10'>
                    <div className='key-text'>
                      電話番号
                    </div>
                    <div>
                      {
                        onlineIVTelephone ?
                            onlineIVTelephone :
                            (
                                this.props.isSameLocation ?
                                    workLocationData.phoneNumber :
                                    interviewLocationData.phoneNumber
                            )
                      }
                    </div>
                  </div>
                </div>
                <div>
                  <button className='redirect-button' onClick={() => this.props.redirectToStage(2)}>編集</button>
                </div>
              </div>
            </div>

            <div className='mt-20'>
              <div className='header'>
                仕事内容
              </div>
              <hr/>
              <div className='d-flex'>
                <div className='flex-auto'>
                  <div>
                    <div className='key-text'>
                      職種
                    </div>
                    <div>
                      {jobsData.jobCategoryName} ({jobsData.jobSubcategoryName})
                    </div>
                  </div>

                  {
                    !this.isDisabled('position') &&
                    <div className='mt-10'>
                      <div className='key-text'>
                        ポジション
                      </div>
                      <div>
                        {this.getPositionName(this.props.adJobData.position) || na_text}
                      </div>
                    </div>
                  }

                  {
                    !this.isDisabled('jobDetail') &&
                    <div className='mt-10'>
                      <div className='key-text'>
                        仕事詳細
                      </div>
                      <div>
                        <InputBox
                          id='job-detail-text'
                          readOnly={true}
                          textArea={true}
                          textOnly={true}
                          label=''
                          value={this.props.adJobData.description || na_text}
                        />
                      </div>
                      {this.props.maintainHeight('job-detail-text')}
                    </div>
                  }

                </div>
                <div>
                  <button className='redirect-button' onClick={() => this.props.redirectToStage(3)}>編集</button>
                </div>
              </div>
            </div>

            {
              !this.isInvisible('requirement') &&
              <div className='mt-20'>
                <div className='header'>
                  応募資格
                </div>
                <hr/>
                <div className='d-flex'>
                  <div className='flex-auto'>

                    {
                      !this.isDisabled('preferredExperienceYear') &&
                      <div>
                        <div className='key-text'>
                          仕事経験
                        </div>
                        <div>
                          {
                            this.props.adJobData.preferred_experience_year ?
                              `${this.props.adJobData.preferred_experience_year} 年以上` :
                              na_text
                          }
                        </div>
                      </div>
                    }

                    {
                      !this.isDisabled('preferredQualification') &&
                      <div className='mt-10'>
                        <div className='key-text'>
                          最終学歴
                        </div>
                        <div>
                          {qualificationOptions[this.props.adJobData.preferred_qualification] || na_text}
                        </div>
                      </div>
                    }

                    {
                      !this.isDisabled('preferredSkills') &&
                      <div className='mt-10'>
                        <div className='key-text'>
                          スキル
                        </div>
                        <div>
                          {
                            this.props.adJobData.preferred_skills.length ? (
                              this.props.adJobData.preferred_skills.map(x => <div>{this.getSkillName(x)}</div>)
                            ) : na_text
                          }
                        </div>
                      </div>
                    }

                    {
                      !this.isDisabled('preferredCertificates') &&
                      <div className='mt-10'>
                        <div className='key-text'>
                          希望保有資格
                        </div>
                        <div>
                          {
                            this.props.adJobData.preferred_certificates.length ? (
                              this.props.adJobData.preferred_certificates.map(x => <div>{this.getCertificateName(x)}</div>)
                            ) : na_text
                          }
                        </div>
                      </div>
                    }

                    {
                      !this.isDisabled('preferredJPLevel') &&
                      <div className='mt-10'>
                        <div className='key-text'>
                          日本語力
                        </div>
                        <div>
                          {jpLevelOptions[this.props.adJobData.preferred_jp_level] || na_text}
                        </div>
                      </div>
                    }

                    {
                      !this.isDisabled('preferredEnLevel') &&
                      <div className='mt-10'>
                        <div className='key-text'>
                          英語力
                        </div>
                        <div>
                          {engLevelOptions[this.props.adJobData.preferred_en_level] || na_text}
                        </div>
                      </div>
                    }

                    {
                      !this.isDisabled('preferredVisaTypes') &&
                      <div className='mt-10'>
                        <div className='key-text'>
                          歓迎する在留資格
                        </div>
                        <div>
                          {
                            this.props.adJobData.preferred_visa_types.length ? (
                              this.props.adJobData.preferred_visa_types.map(x => <div>{this.getVisaTypeName(x)}</div>)
                            ) : na_text
                          }
                        </div>
                      </div>
                    }

                  </div>
                  <div>
                    <button className='redirect-button' onClick={() => this.props.redirectToStage(3)}>編集</button>
                  </div>
                </div>
              </div>
            }

            {
              !this.isInvisible('ssvSpecific') &&
              <div className='mt-20'>
                <div className='header'>
                  特定技能採用に関する項目
                </div>
                <hr/>
                <div className='d-flex'>
                  <div className='flex-auto'>
                    <div>
                      <div className='key-text'>
                        採用対象業種
                      </div>
                      <div>
                        {
                          this.props.adJobData.ssv_industries.length ? (
                            this.props.adJobData.ssv_industries.map(x => <div>{this.getSSVIndustryName(x)}</div>)
                          ) : na_text
                        }
                      </div>
                    </div>

                    <div className='mt-10'>
                      <div className='key-text'>
                        必須の試験合格業種
                      </div>
                      <div>
                        {
                          this.props.adJobData.test_certificates.length ? (
                            this.props.adJobData.test_certificates.map(x => <div>{this.getSSVIndustryName(x)}</div>)
                          ) : na_text
                        }
                      </div>
                    </div>

                    <div className='mt-10'>
                      <div className='key-text'>
                        歓迎する人材のタイプ
                      </div>
                      <div>
                        {
                          this.props.adJobData.who_can_apply.length ? (
                            this.props.adJobData.who_can_apply.map(x => <div>{this.getWhoCanApplyName(x)}</div>)
                          ) : na_text
                        }
                      </div>
                    </div>

                  </div>
                  <div>
                    <button className='redirect-button' onClick={() => this.props.redirectToStage(3)}>編集</button>
                  </div>
                </div>
              </div>
            }

            {
              !this.isInvisible('supportVisaChange') &&
              <div className='mt-20'>
                <div className='header'>
                  在留資格の変更・更新について
                </div>
                <hr/>
                <div className='d-flex'>
                  <div className='flex-auto'>
                    <div>
                      {visaChangeOptions[this.props.adJobData.will_support_visa_change] || na_text}
                    </div>
                  </div>
                  <div>
                    <button className='redirect-button' onClick={() => this.props.redirectToStage(3)}>編集</button>
                  </div>
                </div>
              </div>
            }

            <div className='mt-20'>
              <div className='header'>
                給与
              </div>
              <hr/>
              <div className='d-flex'>
                <div className='flex-auto'>

                  {
                    !this.isDisabled('hourlySalaryMin') &&
                    <div>
                      <div className='key-text'>
                        時給
                      </div>
                      <div>
                        ¥ {this.props.adJobData.hourly_salary_min.toLocaleString()} - {this.props.adJobData.hourly_salary_max.toLocaleString()}/時間
                      </div>
                    </div>
                  }

                  {
                    !this.isDisabled('monthlySalaryMin') &&
                    <div className='mt-10'>
                      <div className='key-text'>
                        月給
                      </div>
                      {
                        Boolean(this.props.adJobData.monthly_salary_min) ?
                          (<div>¥ {this.props.adJobData.monthly_salary_min.toLocaleString()} - {this.props.adJobData.monthly_salary_max.toLocaleString()}/月</div>) :
                          na_text
                      }
                    </div>
                  }

                  {
                    !this.isDisabled('annualSalaryMin') &&
                    <div className='mt-10'>
                      <div className='key-text'>
                        年収
                      </div>
                      {
                        Boolean(this.props.adJobData.annual_salary_min) ?
                          (<div>¥ {this.props.adJobData.annual_salary_min.toLocaleString()} - {this.props.adJobData.annual_salary_max.toLocaleString()}/年</div>) :
                          na_text
                      }
                    </div>
                  }

                  {
                    !this.isDisabled('bonusPaidPerYear') &&
                    <div className='mt-10'>
                      <div className='key-text'>
                        賞与
                      </div>

                      {
                        Boolean(this.props.adJobData.bonus_paid_per_year) ?
                          (<div>{this.props.adJobData.bonus_paid_per_year}回/年</div>) :
                          na_text
                      }
                    </div>
                  }

                  {
                    !this.isDisabled('raisePerYear') &&
                    <div className='mt-10'>
                      <div className='key-text'>
                        昇給
                      </div>

                      {
                        Boolean(this.props.adJobData.raise_paid_per_year) ?
                          (<div>{this.props.adJobData.raise_paid_per_year}回/年</div>) :
                          na_text
                      }
                    </div>
                  }

                  {
                    !this.isDisabled('overtimeHoursPerMonth') &&
                    <div className='mt-10'>
                      <div className='key-text'>
                        時間外勤務時間（1ヶ月）
                      </div>

                      {
                        Boolean(this.props.adJobData.over_time_hours_per_month) ?
                          (<div>{this.props.adJobData.over_time_hours_per_month} 時間/月</div>) :
                          na_text
                      }
                    </div>
                  }

                  <div className='mt-10'>
                    <div className='key-text'>
                      研修期間
                    </div>

                    {
                      Boolean(this.props.adJobData.training_days) ?
                        (<div>{this.props.adJobData.training_days} 日</div>) :
                        na_text
                    }
                  </div>

                  <div className='mt-10'>
                    <div className='key-text'>
                      研修期間の時給
                    </div>
                    {
                      Boolean(this.props.adJobData.training_hourly_salary_min) ?
                        (<div>¥ {this.props.adJobData.training_hourly_salary_min.toLocaleString()} - {this.props.adJobData.training_hourly_salary_max.toLocaleString()}/時間</div>) :
                        na_text
                    }
                  </div>
                  <div className='mt-10'>
                    <div className='key-text'>
                      面接交通費
                    </div>
                    <div>
                      {(jobsData.transportationCovered === 'true') ? '支給' : '支給なし'}
                    </div>
                  </div>

                </div>
                <div>
                  <button className='redirect-button' onClick={() => this.props.redirectToStage(3)}>編集</button>
                </div>
              </div>
            </div>

            <div className='mt-20'>
              <div className='header'>
                勤務時間
              </div>
              <hr/>
              <div className='d-flex'>
                <div className='flex-auto'>
                  <div className='key-text'>
                    {shifts}
                  </div>

                  {
                    !this.isDisabled('remoteWork') &&
                    <div className='mt-10'>
                      <div className='key-text'>
                        リモートワーク
                      </div>
                      <div>
                        {remoteJobOptions[this.props.adJobData.remote_work] || na_text}
                      </div>
                    </div>
                  }
                </div>
                <div>
                  <button className='redirect-button' onClick={() => this.props.redirectToStage(3)}>編集</button>
                </div>
              </div>
            </div>

            {
              !this.isInvisible('holidays') &&
              <div className='mt-20'>
                <div className='header'>
                  休日・休暇
                </div>
                <hr/>
                <div className='d-flex'>
                  <div className='flex-auto'>
                    <div>
                      {
                        this.props.adJobData.holidays.length ? (
                          this.props.adJobData.holidays.map(x => <div>{this.getHolidayName(x)}</div>)
                        ) : na_text
                      }
                    </div>
                  </div>
                  <div>
                    <button className='redirect-button' onClick={() => this.props.redirectToStage(3)}>編集</button>
                  </div>
                </div>
              </div>
            }

            {
              !this.isInvisible('welfare') &&
              <div className='mt-20'>
                <div className='header'>
                  福利厚生
                </div>
                <hr/>
                <div className='d-flex'>
                  <div className='flex-auto'>
                    <div>
                      <div className='key-text'>
                        社会保険
                      </div>
                      <div>
                        {socialInsuranceOptions[this.props.adJobData.is_social_insurance_provided] || na_text}
                      </div>
                    </div>
                  </div>
                  <div>
                    <button className='redirect-button' onClick={() => this.props.redirectToStage(3)}>編集</button>
                  </div>
                </div>
              </div>
            }

            <div className='mt-20'>
              <div className='header'>
                仕事や職場のイメージ
              </div>
              <hr/>
              <div className="work-images">
                {images}
              </div>
            </div>

            <div className='mt-20'>
              <div className='header'>
                セールスポイント
              </div>
              <hr/>
              <div>
                {jobsData.sales_merits_str.split(',').map(x => <div>{x}</div>)}
              </div>
            </div>

          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    language: state.language,
    token: state.user.token
  }
};

PreviewJobs = connect(mapStateToProps)(PreviewJobs);


export default PreviewJobs;
