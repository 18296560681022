import React, { Component } from 'react';
import { ENV_TYPE } from '../../constants';
import { connect } from 'react-redux';
import { completedJoyride } from '../../actions';
import { jobs, subscriptionAPI, initializeJob } from '../helperFunctions/dataServices.js';
import DashboardTranslations from '../../DashboardTranslations.js';
import ReactGA from 'react-ga';
import InnerHeader from '../components/InnerHeader';
import JobCell from './JobCell.js';
import Joyride from 'react-joyride';
import ScaleLoader from 'react-spinners/ScaleLoader';
import SuccessModal from '../components/SuccessModal';
import './styles/jobsLanding.scss';


class JobsLanding extends Component {
  constructor(props) {
    if (window.kommunicate && window.kommunicate._globals.preOrPostLogin === 'pre') {
      window.location.reload();
    }

    super(props);
    this.state = {
      jobs: [],
      loading: false,
      mainKey: 0,
      steps: [],
      isAdmin: false,
      showDescriptionModal: false,
      subscriptionData: [],
      jobsOptions: false,
      showImageUploadSuccess: false,
      imageUploadSuccessMessage: ""

    }
  }

  componentDidMount() {
    if (ENV_TYPE === "PROD") {
      this.initializeReactGA()
    }
    this.subscription()
    if (this.props.joyride.completed.indexOf("jobsLanding") > -1) {
      if (this.props.registerStep !== null && this.props.registerStep !== "null") {
        this.setState({steps: []});
      }
    }
    this.getJobs('initial')
    this.initializeJobs()
  }

  initializeReactGA() {
    ReactGA.initialize('UA-104174852-1');
    ReactGA.pageview('/dashboard/jobs');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.joyride !== this.props.joyride) {
      this.checkForJoyride()
    }
  }

  initializeJobs() {
    this.setState({loading: true})
    initializeJob().then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        var jobsOptions = response.data
        this.setState({jobsOptions: jobsOptions})
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  subscription() {
    subscriptionAPI().then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({subscriptionData: response.data, isAdmin: response.data.is_admin})
        this.checkForDescriptionModal()
      }
      else {
        // this.setState({loading: false})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
      if (e.response.status === 403) {
        this.setState({isAdmin: false})
      }
    })
  }

  handleShowDescription() {
    this.setState({showDescriptionModal: false})
  }

  checkForDescriptionModal() {
    if (this.state.subscriptionData.running_jobs_count === 0 && this.state.subscriptionData.is_cc_added_in_gmo === true && this.state.jobs.length > 0) {
      this.setState({showDescriptionModal: true})
    }
  }

  //Function to check if joyride for jobs landing page is already shown to user atleast once
  checkForJoyride() {
    if (this.props.registerStep !== null && this.props.registerStep !== "null") {
      if (this.props.joyride.completed.indexOf("sidebar") > -1) {
        if (this.props.joyride.completed.indexOf("jobsLanding") > -1) {
          this.setState({steps: []});
        }
        else {
          this.restartJoyride()
        }
      }
    }
  }

  handleJoyrideCallback(data) {
    if (data.action === "close" && data.lifecycle === "complete") {
      var joyride = this.props.joyride.completed
      if (joyride.indexOf("jobsLanding") > -1) {
        // joyride = joyride.filter(item => item !== "jobsLanding")
      }
      else {
        joyride[joyride.length] = "jobsLanding"
      }
      this.props.dispatch(completedJoyride(joyride));
    }
  }

  getJobs(type) {
    if (type === 'initial') {
      this.setState({loading: true})
    }
    jobs().then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        response.data.sort(this.sortJobs)
        this.setState({jobs: response.data, loading: false})
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  sortJobs(a, b) {
    // if (a.created_at_timestamp > b.created_at_timestamp)
    //   return -1;
    // if (a.created_at_timestamp < b.created_at_timestamp)
    //   return 1;
    // return 0;

    // https://stackoverflow.com/a/20554416/3775598

    if (a.status === 'RN')
      return -1;
    else if (b.status === 'RN')
      return 1;
    else if (a.created_at_timestamp > b.created_at_timestamp)
      return -1;
    else
      return 1;
  }

  redirectToAddJobs() {
    this.props.history.push('/dashboard/add-job')
  }

  redirectToViewInterviewTimes() {
    this.props.history.push('/dashboard/interview-times')
  }

  //Reset joyride for jobs landing page
  restartJoyride() {
    var steps= [
      {
        target: '.create-first-job-button',
        content: '勤務地や面接場所は、一度登録すると何度でも使えます。',
        title: '求人記事を作成しましょう。',
        disableBeacon: true,
      },
    ]
    this.setState({
      mainKey: this.state.mainKey + 1,
      steps: steps
    });
  }

  showImageUploadSuccess(data, type) {
    var jobs = this.state.jobs.map(job => {
      var singleJob = job
      if (job.id === data.id) {
        singleJob.work_images = data.work_images
      }
      return singleJob;
    })
    var message = "画像をアップロードしました"
    if (type === "delete") {
      message = "画像を削除しました"
    }
    this.setState({showImageUploadSuccess: true, imageUploadSuccessMessage: message, jobs: jobs})
  }

  handleImageUploadClose() {
    this.setState({showImageUploadSuccess: false, imageUploadSuccessMessage: ""})
  }

  render() {
    var { jobs, loading, steps, mainKey, showDescriptionModal, jobsOptions } = this.state
    var jobsList = jobs.map((job, index) => {
      return (
        <JobCell images={job.work_images} showImageUploadSuccess={this.showImageUploadSuccess.bind(this)} jobsOptions={jobsOptions} subscriptionData={this.state.subscriptionData} key={index} job={job} history={this.props.history} getJobs={this.getJobs.bind(this)}/>
      );
    })

    return (
      <div className="jobs-landing-container">
        <InnerHeader
          primary={true}
          history={this.props.history}
          headerText={DashboardTranslations.jobs_page_main_heading_small_screen}
          buttonOneText={DashboardTranslations.jobs_page_create_job_button_text}
          buttonOneClick={this.redirectToAddJobs.bind(this)}
          buttonTwoText={DashboardTranslations.jobs_page_view_calendar_button_text}
          buttonTwoClick={this.redirectToViewInterviewTimes.bind(this)}
        />
        <ScaleLoader sizeUnit={"px"} height={50}
          width={4} color={'#007AFF'} loading={loading}
        />
        {loading === false && jobs.length > 0 && <div className="create-job-button" onClick={() => this.props.history.push('/dashboard/select-job-type')}>
          {DashboardTranslations.jobs_page_create_job_button_text}
        </div>}

        <div className="job-list-container">
          {loading === false && jobs.length === 0 && <div className="first-time-job">
            <div className="first-time-job-message">
              <div style={{textAlign: "center"}}>
                <button className="guide" onClick={() => this.restartJoyride()}>使い方</button>
              </div>

              {DashboardTranslations.jobs_page_create_first_job_message}
            </div>
            <div className="create-first-job-button" onClick={() => this.props.history.push('/dashboard/select-job-type')}>
              {DashboardTranslations.jobs_page_create_job_button_text}
            </div>
            <Joyride
              autoStart={true}
              disableCloseOnEsc={true}
              disableOverlayClose={true}
              locale={{ back: '前へ', close: '閉じる', last: '閉じる', next: '次へ', skip: 'スキップ' }}
              run={true}
              key={mainKey}
              steps={steps}
              callback={this.handleJoyrideCallback.bind(this)}
            />
          </div>}
          {jobsList}
        </div>
        <SuccessModal successMessage={DashboardTranslations.jobs_page_start_running_job_modal_text} handleClose={this.handleShowDescription.bind(this)} showSuccess={showDescriptionModal}/>
        <SuccessModal successMessage={this.state.imageUploadSuccessMessage} handleClose={this.handleImageUploadClose.bind(this)} showSuccess={this.state.showImageUploadSuccess}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token,
        joyride: state.joyride,
        registerStep: state.user.registerStep,
    }
};

JobsLanding = connect(mapStateToProps)(JobsLanding);

export default JobsLanding;
