import React, { Component } from "react";
import InputBox from "../components/InputBoxNew.js";
import { loginSuccess, setRegisterStep } from "../../actions";
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import {
  registerEmployerAPI,
  loginAPI,
} from "../helperFunctions/dataServices.js";
import ErrorModal from "../components/ErrorModal";
import DashboardTranslations from "../../DashboardTranslations.js";
import SuccessModal from "../components/SuccessModal";
import EmployerRegistrationSuccessModal from "../components/EmployerRegistrationSuccessModal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import correct from "../../images/correct2.svg";
import wrong from "../../images/wrong2.svg";
import { ToggleLayer, Arrow } from "react-laag";
import ResizeObserver from "resize-observer-polyfill";
import "./styles/registerNew.scss";
import { passwordRegex, emailRegex } from "../../regex";
import close from "../../images/svg/close2.svg";
import flow1 from "../../images/flow-1.svg";
import flow2 from "../../images/flow-2.svg";
import flow3 from "../../images/flow-3.svg";
import flow4 from "../../images/flow-4.svg";
import flowArrow from "../../images/flow-arrow.svg";
import call from "../../images/call.svg";
import { getErrorMessage } from "../../messages/errors";
import CustomCheckbox from "../components/CustomCheckbox.js";

class Register extends Component {
  constructor(props) {
    if (
      window.kommunicate &&
      window.kommunicate._globals.preOrPostLogin === "post"
    ) {
      window.location.reload();
    }

    super(props);
    this.state = {
      showSuccess: false,
      email: "",
      companyNameTemp: "",
      contactName: "",
      contactNumber: "",
      password: "",
      confirmPassword: "",
      showPasswordTooltip: false,
      showConfirmPasswordTooltip: false,
      agreeToPolicy: false,
      showEmployerRegistrationModal: false,
      reloadOnClose: false,
      showPasswordHelper: false,
      showConfirmPasswordHelper: false,
      emailLogin: "",
      passwordLogin: "",
      openLoginDrawer: false,
      errorEnLogin: null,
      errorJpLogin: null,
      submitted: false,
      showError: false,
      loginSubmitted: false,
      error: "",
      referrer: null
    };
  }

  componentDidMount() {
    // localStorage.setItem("joyride", JSON.stringify([]));
    // this.fetchMemberDetails()
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard/jobs");
      window.location.reload();
    }
    if (this.props.history.location.search.indexOf("?verified=true") >= 0) {
      this.setState({ openLoginDrawer: true });
      // this.setState({showSuccess: true, successMessage: DashboardTranslations.register_page_email_verification_success_message})
    } else if (
      this.props.history.location.search.indexOf("?verified=false") >= 0
    ) {
      this.setState({
        showError: true,
        error: getErrorMessage(
          "register_page_email_verification_error_message"
        ),
      });
    }

    if (this.props.match.params.hasOwnProperty('referrer')) {
      this.setState({ referrer: this.props.match.params.referrer });
    }
  }

  handleChange = (event) => {
    var value = event.target.value;
    if (event.target.name === "password") {
      this.setState({ showPasswordHelper: true });
    } else if (event.target.name === "confirmPassword") {
      this.setState({ showConfirmPasswordHelper: true });
    }
    this.setState({ [event.target.name]: value });
  };

  handleLogin(event) {
    this.setState({ loginSubmitted: true });
    if (!this.state.emailLogin) {
      this.setState({
        errorEnLogin: "Email not provided.",
        error: "メールアドレスは必須項目です",
        // showError: true,
      });
      return;
    }

    // Validate Email
    if (this.state.emailLogin) {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailRegex.test(this.state.emailLogin)) {
        this.setState({
          errorEnLogin: "Email is in incorrect format.",
          error: "正しいメールアドレスを入力してください",
          // showError: true,
        });
        return;
      }
    }

    if (!this.state.passwordLogin) {
      this.setState({
        errorEnLogin: "Password not provided.",
        error: "パスワードは必須項目です",
        // showError: true,
      });
      return;
    }

    const payload = {
      email: this.state.emailLogin,
      password: this.state.passwordLogin,
    };
    this.setState({ loading: true });
    loginAPI(payload)
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState({ openLoginDrawer: false });
          this.props.dispatch(setRegisterStep(response.data.register_stage));
          this.props.dispatch(loginSuccess(response.data));
          this.props.history.push("/dashboard");
          window.location.reload();
        } else {
          this.setState({
            loading: false,
            showError: true,
            error: response.response.data.error
              ? getErrorMessage("from_api_response", {
                  apiErrorMessage: response.response.data.error,
                })
              : "",
          });
        }
      })
      .catch((e) => {
        this.setState({ loading: false, showError: true });
      });
    event.preventDefault();
  }

  handleCheckbox = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleSuccess() {
    this.setState({ showSuccess: false, showEmployerRegistrationModal: false });
    if (this.state.reloadOnClose) {
      window.location.href = "https://www.workjapan.jp/job-site-japan";
    }
  }

  handleCloseReloadPage() {
    this.setState({ showSuccess: false, showEmployerRegistrationModal: false });
    if (this.state.reloadOnClose) {
      window.location.href = "/register";
    }
  }

  handleErrorClose = () => {
    this.setState({ showError: false, error: "" });
  };

  isValid() {
    let {
      companyNameTemp,
      email,
      password,
      confirmPassword,
      agreeToPolicy,
      contactName,
      contactNumber,
    } = this.state;
    if (
      emailRegex.test(email) &&
      companyNameTemp.length > 0 &&
      contactName.length > 0 &&
      (contactNumber.length === 10 || contactNumber.length === 11) &&
      passwordRegex.test(password) &&
      passwordRegex.test(confirmPassword)
    ) {
      if (agreeToPolicy) {
        if (password === confirmPassword) {
          return true
        }
      }
    }
    return false
  }

  validate() {
    let {
      companyNameTemp,
      email,
      password,
      confirmPassword,
      agreeToPolicy,
      contactName,
      contactNumber,
      referrer
    } = this.state;
    if (
      emailRegex.test(email) &&
      companyNameTemp.length > 0 &&
      contactName.length > 0 &&
      (contactNumber.length === 10 || contactNumber.length === 11) &&
      passwordRegex.test(password) &&
      passwordRegex.test(confirmPassword)
    ) {
      if (agreeToPolicy) {
        if (password === confirmPassword) {
          let payload = {};
          payload.email = email;
          payload.company_name = companyNameTemp;
          payload.password = password;
          payload.contact_name = contactName;
          payload.contact_num = contactNumber;
          if (referrer) {
            payload.referrer = referrer;
          }
          this.registerEmployer(payload);
        } else {
          this.setState({
            showError: true,
            error: getErrorMessage(
              "register_page_passwords_do_not_match_error_message"
            ),
          });
        }
      } else {
        this.setState({
          showError: true,
          error: getErrorMessage("register_page_agree_to_policy_error_message"),
        });
      }
    } else {
      this.setState({ submitted: true });
    }
  }

  registerEmployer(payload) {
    registerEmployerAPI(payload)
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState({
            showEmployerRegistrationModal: true,
            successMessage:
              DashboardTranslations.register_page_registration_success_message,
            reloadOnClose: true,
          });
        } else {
          this.setState({
            loading: false,
            showError: true,
            error: response.response.data.error
              ? getErrorMessage("from_api_response", {
                  apiErrorMessage: response.response.data.error,
                })
              : "",
          });
        }
      })
      .catch((e) => {
        this.setState({ loading: false, showError: true });
      });
  }

  render() {
    let {
      companyNameTemp,
      email,
      password,
      confirmPassword,
      showPasswordHelper,
      showConfirmPasswordHelper,
      error,
      showError,
      showSuccess,
      submitted,
      agreeToPolicy,
      showEmployerRegistrationModal,
      loginSubmitted,
      emailLogin,
      passwordLogin,
      contactName,
      contactNumber,
    } = this.state;

    let isNumberPresentPassword = false;
    let isNumberPresentConfirmPassword = false;
    let isUpperCasePresentPassword = false;
    let isUpperCasePresentConfirmPassword = false;
    let isLowerCasePresentPassword = false;
    let isLowerCasePresentConfirmPassword = false;
    let isMinimumLengthPassword = false;
    let isMinimumLengthConfirmPassword = false;

    if (/\d/.test(password)) {
      isNumberPresentPassword = true;
    }
    if (/[A-Z]/.test(password)) {
      isUpperCasePresentPassword = true;
    }
    if (/[a-z]/.test(password)) {
      isLowerCasePresentPassword = true;
    }
    if (password.length >= 8) {
      isMinimumLengthPassword = true;
    }

    if (/\d/.test(confirmPassword)) {
      isNumberPresentConfirmPassword = true;
    }
    if (/[A-Z]/.test(confirmPassword)) {
      isUpperCasePresentConfirmPassword = true;
    }
    if (/[a-z]/.test(confirmPassword)) {
      isLowerCasePresentConfirmPassword = true;
    }
    if (confirmPassword.length >= 8) {
      isMinimumLengthConfirmPassword = true;
    }

    return (
      <div className="register-page-container">

        <div className="login-text-container">
          <h1 className="login-title">ご登録済み企業様はこちら</h1>
          <div className="login-main-container">
            <div className="login-text">
              こちらより求人管理画面にログインしてください
            </div>
            <button
              onClick={(event) => this.setState({ openLoginDrawer: true })}
            >
              ログイン
            </button>
          </div>
        </div>

        <br />

        <div className="register-form-container">
          <h1 className="register-form-title">求人掲載をはじめる</h1>
          <div className="register-form-main-container">
            <div className="register-form-top-text">クレジットカード払いなら、すぐに求人を開始できます。<br/>まずは企業登録をしてみましょう！</div>
            <div className="register-steps">
              <div className="register-steps-title">求人開始までの流れ</div>
              <div className="flow-steps-container">
                <div className="flow-step">
                  <img className="step-image" src={flow1} alt="flow1" />
                  <div className="step-title">ご登録</div>
                </div>
                <img className="flow-arrow" src={flowArrow}></img>
                <div className="flow-step">
                  <img className="step-image" src={flow2} alt="flow1" />
                  <div className="step-title">記事作成</div>
                </div>
                <img className="flow-arrow" src={flowArrow}></img>
                <div className="flow-step">
                  <img className="step-image" src={flow3} alt="flow1" />
                  <div className="step-title">お支払い</div>
                </div>
                <img className="flow-arrow" src={flowArrow}></img>
                <div className="flow-step">
                  <img className="step-image" src={flow4} alt="flow1" />
                  <div className="step-title">掲載開始</div>
                </div>
              </div>
              <div className="register-steps-subtitle">サポートが必要な方はお問い合わせください。</div>
              <div className="register-steps-call"><img src={call} alt="call"/>050-5213-0772</div>
            </div>
            <div className="form-fields">
              <InputBox
                value={email}
                errorMessage={
                  DashboardTranslations.register_page_email_error_message
                }
                isValid={() => {
                  return email.length > 0 && emailRegex.test(email)
                }}
                error={submitted === true && !emailRegex.test(email)}
                label={DashboardTranslations.register_page_email_label}
                name="email"
                autoComplete={false}
                showMandatory={true}
                handleChange={this.handleChange.bind(this)}
                className="single-field"
              />
              <InputBox
                value={companyNameTemp}
                errorMessage={
                  DashboardTranslations.register_page_company_name_jp_error_message
                }
                isValid={() => {
                  return companyNameTemp.length > 0 &&  companyNameTemp.length > 0
                }}
                error={
                  submitted === true &&
                  (companyNameTemp.length === 0 || companyNameTemp.length > 30)
                }
                label={
                  DashboardTranslations.register_page_company_name_jp_label
                }
                name="companyNameTemp"
                showMandatory={true}
                autoComplete={false}
                handleChange={this.handleChange.bind(this)}
                className="single-field"
              />

              <InputBox
                value={contactName}
                errorMessage="お名前を正しく入力してください"
                isValid={() => {
                  return contactName.length > 0 && contactName.length > 0
                }}
                error={
                  submitted === true &&
                  (contactName.length === 0 || contactName.length > 30)
                }
                label="お名前"
                name="contactName"
                showMandatory={true}
                autoComplete={false}
                handleChange={this.handleChange.bind(this)}
                className="single-field"
              />

              <InputBox
                value={contactNumber}
                errorMessage="電話番号を正しく入力してください"
                isValid={() => {
                  return contactNumber.length > 0 && (contactNumber.length === 10 || contactNumber.length === 11)
                }}
                error={
                  submitted === true &&
                  (contactNumber.length <= 9 || contactNumber.length > 11)
                }
                label="電話番号"
                explanatoryText={"※半角数字、ハイフン無し"}
                name="contactNumber"
                showMandatory={true}
                autoComplete={false}
                handleChange={this.handleChange.bind(this)}
                className="single-field"
              />

              <ToggleLayer
                ResizeObserver={ResizeObserver}
                renderLayer={({ isOpen, layerProps, arrowStyle, layerSide }) =>
                  showPasswordHelper &&
                  !passwordRegex.test(password) && (
                    <div
                      ref={layerProps.ref}
                      className="layer layer-popover"
                      style={{
                        ...layerProps.style,
                        zIndex: 999,
                        backgroundColor: "#FFFFFF",
                        boxShadow: '3px 3px 12px 3px #ccc',
                        borderRadius: "12px",
                        padding: "10px",
                      }}
                    >
                      <div className="register-page-validation-tooltip-container">
                        <div
                          className="validation-inner-container"
                        >
                          <div className="single-validation">
                            <img
                              src={isNumberPresentPassword ? correct : wrong}
                              style={{
                                width: 20,
                                height: 20,
                                margin: "0px 5px",
                              }}
                            />
                            数字
                          </div>
                          <div className="single-validation">
                            <img
                              src={isUpperCasePresentPassword ? correct : wrong}
                              style={{
                                width: 20,
                                height: 20,
                                margin: "0px 5px",
                              }}
                            />
                            大文字
                          </div>
                          <div className="single-validation">
                            <img
                              src={isLowerCasePresentPassword ? correct : wrong}
                              style={{
                                width: 20,
                                height: 20,
                                margin: "0px 5px",
                              }}
                            />
                            小文字
                          </div>
                          <div className="single-validation">
                            <img
                              src={isMinimumLengthPassword ? correct : wrong}
                              style={{
                                width: 20,
                                height: 20,
                                margin: "0px 5px",
                              }}
                            />
                            ８文字以上
                          </div>
                        </div>
                      </div>
                      <Arrow
                        style={arrowStyle}
                        layerSide={layerSide}
                        backgroundColor="#FFFFFF"
                        roundness={0.5}
                      />
                    </div>
                  )
                }
                placement={{
                  anchor: "RIGHT_BOTTOM",
                  autoAdjust: true,
                  triggerOffset: 10,
                }}
              >
                {({ triggerRef, toggle }) => (
                  <div ref={triggerRef}>
                    <InputBox
                      value={password}
                      errorMessage={
                        DashboardTranslations.register_page_password_error_message
                      }
                      isValid={() => {
                        return password.length > 0 && passwordRegex.test(password)
                      }}
                      error={
                        submitted === true && !passwordRegex.test(password)
                          ? true
                          : false
                      }
                      label={DashboardTranslations.register_page_password_label}
                      name="password"
                      explanatoryText={
                        DashboardTranslations.register_page_password_explanatory_text
                      }
                      inputType="password"
                      autoComplete={false}
                      showMandatory={true}
                      handleChange={this.handleChange.bind(this)}
                      className="single-field"
                    />
                  </div>
                )}
              </ToggleLayer>

              <ToggleLayer
                ResizeObserver={ResizeObserver}
                renderLayer={({ isOpen, layerProps, arrowStyle, layerSide }) =>
                  showConfirmPasswordHelper &&
                  !passwordRegex.test(confirmPassword) && (
                    <div
                      ref={layerProps.ref}
                      className="layer layer-popover"
                      style={{
                        ...layerProps.style,
                        backgroundColor: "#FFFFFF",
                        boxShadow: '3px 3px 12px 3px #ccc',
                        borderRadius: "12px",
                        padding: "10px",
                      }}
                    >
                      <div className="register-page-validation-tooltip-container">
                        <div
                          className="validation-inner-container"
                        >
                          <div className="single-validation">
                            <img
                              src={
                                isNumberPresentConfirmPassword ? correct : wrong
                              }
                              style={{
                                width: 20,
                                height: 20,
                                margin: "0px 5px",
                              }}
                            />
                            数字
                          </div>
                          <div className="single-validation">
                            <img
                              src={
                                isUpperCasePresentConfirmPassword
                                  ? correct
                                  : wrong
                              }
                              style={{
                                width: 20,
                                height: 20,
                                margin: "0px 5px",
                              }}
                            />
                            大文字
                          </div>
                          <div className="single-validation">
                            <img
                              src={
                                isLowerCasePresentConfirmPassword
                                  ? correct
                                  : wrong
                              }
                              style={{
                                width: 20,
                                height: 20,
                                margin: "0px 5px",
                              }}
                            />
                            小文字
                          </div>
                          <div className="single-validation">
                            <img
                              src={
                                isMinimumLengthConfirmPassword ? correct : wrong
                              }
                              style={{
                                width: 20,
                                height: 20,
                                margin: "0px 5px",
                              }}
                            />
                            ８文字以上
                          </div>
                        </div>
                      </div>
                      <Arrow
                        style={arrowStyle}
                        layerSide={layerSide}
                        backgroundColor="#FFFFFF"
                        roundness={0.5}
                      />
                    </div>
                  )
                }
                placement={{
                  anchor: "RIGHT_BOTTOM",
                  autoAdjust: true,
                  triggerOffset: 10,
                }}
              >
                {({ triggerRef, toggle }) => (
                  <div ref={triggerRef}>
                    <InputBox
                      value={confirmPassword}
                      errorMessage={
                        DashboardTranslations.register_page_password_error_message
                      }
                      isValid={() => {
                        return confirmPassword.length > 0 && passwordRegex.test(confirmPassword) && (confirmPassword.length == 0 || password === confirmPassword)
                      }}
                      error={
                        submitted === true &&
                        !passwordRegex.test(confirmPassword)
                          ? true
                          : false
                      }
                      label={"パスワード（再入力）"}
                      name="confirmPassword"
                      explanatoryText={
                        DashboardTranslations.register_page_password_explanatory_text
                      }
                      inputType="password"
                      showMandatory={true}
                      autoComplete={false}
                      handleChange={this.handleChange.bind(this)}
                      className="single-field"
                    />
                  </div>
                )}
              </ToggleLayer>
            </div>

            <div className="terms-and-conditions">
              <FormControlLabel
                control={
                  <CustomCheckbox agreeToPolicy={agreeToPolicy} handleCheckbox={this.handleCheckbox} />
                }
              />
              <span>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.workjapan.jp/terms"
                >
                  {" "}
                  利用規約
                </a>
                に同意します
              </span>
            </div>
            <button
              className="register-submit-button"
              onClick={() => this.validate()}
            >
              登録する
            </button>
          </div>
        </div>
        <SuccessModal
          successMessage={
            DashboardTranslations.register_page_add_member_success_message
          }
          handleClose={this.handleSuccess.bind(this)}
          showSuccess={showSuccess}
        />
        <EmployerRegistrationSuccessModal
          email={email}
          handleClose={this.handleCloseReloadPage.bind(this)}
          handleCloseReloadPage={this.handleCloseReloadPage.bind(this)}
          showSuccess={showEmployerRegistrationModal}
        />
        <ErrorModal
          error={error}
          handleClose={this.handleErrorClose.bind(this)}
          showError={showError}
        />
        <Modal
          anchor="top"
          open={this.state.openLoginDrawer}
          onClose={() => this.setState({ openLoginDrawer: false })}
        >
          <div className="backdrop-layer">
            <div className="login-modal-container">
            <div className="login-modal-inner">
              <div className="responsive-header">
                <div className="login-header-text">
                  {DashboardTranslations.login_button_text}
                </div>
                <div
                  className="burger"
                  onClick={() => this.setState({ openLoginDrawer: false })}
                >
                  <img src={close} alt="close" />
                </div>
              </div>
              <div className="login-inner-modal">
                <form>
                  <InputBox
                    value={emailLogin}
                    errorMessage={
                      DashboardTranslations.register_page_email_error_message
                    }
                    error={
                      loginSubmitted === true && !emailRegex.test(emailLogin)
                        ? true
                        : false
                    }
                    label={DashboardTranslations.register_page_email_label}
                    name="emailLogin"
                    handleChange={this.handleChange.bind(this)}
                    className="single-field"
                  />
                  <InputBox
                    value={passwordLogin}
                    errorMessage={
                      DashboardTranslations.register_page_password_error_message
                    }
                    error={
                      loginSubmitted === true &&
                      !passwordRegex.test(passwordLogin)
                        ? true
                        : false
                    }
                    label={DashboardTranslations.register_page_password_label}
                    name="passwordLogin"
                    inputType="password"
                    handleChange={this.handleChange.bind(this)}
                    className="single-field"
                  />
                  <div className="submit-button-container">
                    <div
                      className="btn btn-primary"
                      onClick={(event) => this.handleLogin(event)}
                    >
                      ログイン
                    </div>
                    <div className="forgot-password-text">
                      <a
                        style={{ textDecoration: "none" }}
                        href="/forgot-password"
                      >
                        パスワードをお忘れですか？
                      </a>{" "}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          </div>
          
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
  };
};

Register = connect(mapStateToProps)(Register);

export default Register;
