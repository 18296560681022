import React, { Component } from 'react';
import InputBox from '../../components/InputBox.js'
import { connect } from 'react-redux';
import DashboardTranslations from '../../../DashboardTranslations.js';

import '../styles/companyDetailsPart4.scss';
import { nameRegex, katakanaRegex } from '../../../regex';

class CompanyDetailsPart3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminLastNameKT: props.adminLastNameKT ? props.adminLastNameKT : "",
      adminFirstNameKT: props.adminFirstNameKT ? props.adminFirstNameKT : "",
      adminLastName: props.adminLastName ? props.adminLastName : "",
      adminFirstName: props.adminFirstName ? props.adminFirstName : "",
      adminTelephoneNumber: props.adminTelephoneNumber ? props.adminTelephoneNumber : "",
      submitted: false,
      adminCEOSame: false,
    }
  }

  componentDidMount() {

  }

  handleChange = event => {
    var name = event.target.name
    var value = event.target.value
    this.setState({ [event.target.name]: value }, () => this.props.updateValues(name, value));
  }

  render() {
    var { adminLastName, adminFirstName, adminLastNameKT, adminFirstNameKT, adminCEOSame, adminTelephoneNumber
    } = this.state

    var { submitted } = this.props

    return (
      <div className="company-details-part4-container">
        <div className="form-helper-text">
          担当者様情報を入力して「次へ」ボタンを押してください
        </div>
        <div className="company-details-part4-form">
          <div className="is-admin-ceo-same-container">
            <div className="admin-ceo-same-text">
              担当者様と代表者様は同じですか？
            </div>
            <div className="admin-ceo-same-values">
              <div className="admin-ceo-same-single">
                <input type="radio" name="adminCEOSame" value={adminCEOSame} checked={!!adminCEOSame} id="adminCEOSame" style={{marginRight: 10, marginTop: 3}} onChange={() => this.setState({adminCEOSame: true}, () => this.props.updateValues("adminCEOSame", true))} />
                <div className="text">
                  はい、同じです。
                </div>
              </div>
              <div className="admin-ceo-same-single">
                <input type="radio" name="adminCEOSame" value={adminCEOSame} checked={!adminCEOSame} id="adminCEOSame" style={{marginRight: 10, marginTop: 3}} onChange={() => this.setState({adminCEOSame: false}, () => this.props.updateValues("adminCEOSame", true))} />
                <div className="text">
                  いいえ、同じではないです。
                </div>
              </div>
            </div>
          </div>
          {!adminCEOSame && <div className="admin-details-container">
            <InputBox
              value={adminLastName}
              errorMessage={DashboardTranslations.register_page_last_name_error_message}
              error={submitted === true && !nameRegex.test(adminLastName)}
              label="担当者様の姓（漢字）"
              name="adminLastName"
              handleChange={this.handleChange.bind(this)}
            />
            <InputBox
              value={adminFirstName}
              errorMessage={DashboardTranslations.register_page_first_name_error_message}
              error={submitted === true && !nameRegex.test(adminFirstName)}
              label="担当者様の名（漢字）"
              name="adminFirstName"
              handleChange={this.handleChange.bind(this)}
            />
            <InputBox
              value={adminLastNameKT}
              errorMessage={DashboardTranslations.register_page_admin_last_name_kt_error_message}
              error={submitted === true && !katakanaRegex.test(adminLastNameKT)}
              label="担当者様の姓（カタカナ）"
              name="adminLastNameKT"
              handleChange={this.handleChange.bind(this)}
            />
            <InputBox
              value={adminFirstNameKT}
              errorMessage={DashboardTranslations.register_page_admin_first_name_kt_error_message}
              error={submitted === true && !katakanaRegex.test(adminFirstNameKT)}
              label="担当者様の名 （カタカナ）"
              name="adminFirstNameKT"
              handleChange={this.handleChange.bind(this)}
            />
            <InputBox
              value={adminTelephoneNumber}
              errorMessage={DashboardTranslations.register_page_company_phone_number_error_message}
              error={submitted === true && (adminTelephoneNumber.length <= 9 || adminTelephoneNumber.length > 11)}
              label="担当者様のお電話番号 （半角数字、ハイフン無し）"
              name="adminTelephoneNumber"
              handleChange={this.handleChange.bind(this)}
              className="single-field"
            />
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.user.isLoggedIn,
    }
};

CompanyDetailsPart3 = connect(mapStateToProps)(CompanyDetailsPart3);

export default CompanyDetailsPart3;
