import React, { Component } from 'react';
// import Select, {Option, OptGroup} from 'rc-select';
import '../styles/select.scss';
import HelpIcon from '../../images/help-icon.png'
import Tooltip from '@material-ui/core/Tooltip';
import DashboardTranslations from '../../DashboardTranslations.js';

class SelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "valid",
      selectedOption: props.value ? props.value : {}
    }
  }

  componentDidMount() {
  }

  handleChange = (e) => {
    this.props.handleChange(e);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      var selectedOption = this.props.value
      this.setState({selectedOption});
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.value!==prevState.selectedOption){
      return {value: nextProps.value}
    }
    else return null;
  }


  render() {
    var {label, error, errorMessage, type, options, leaveErrorSpace,
        value, disabled, explanatoryText, selectPlaceholder, noNullOption,
        tooltipText, showMandatory, showOptional, marginRight, mandatoryText } = this.props
    var inputContainerClass = "select-container"
    var singleSelectClassName = "single-select"
    if (type === "multiline") {
      inputContainerClass = "select-container multiline-select"
      singleSelectClassName = "multiple-select"
    }
    var displayOptions = []
    var newOptions = [{value: "", label: selectPlaceholder ? selectPlaceholder : DashboardTranslations.default_select_placeholder}]
    if (!noNullOption) {
      newOptions = newOptions.concat(options)
    }
    else {
      newOptions = options
    }
    displayOptions = newOptions.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)

    var mandatoryBox
    if (showMandatory) {
      mandatoryBox =
        <span className="mandatory-container">
          {DashboardTranslations.field_mandatory_text}
        </span>
    }
    var optionalBox
    if (showOptional) {
      optionalBox =
        <span className="optional-container">
          {DashboardTranslations.field_optional_text}
        </span>
    }
    var errorMessageClass = "error-message-container"
    if (leaveErrorSpace) {
      errorMessageClass = "error-message-container error-message-leave-space"
    }

    var errorText = ""
    if (error) {
      errorText = errorMessage
    }

    return (
      <div className={inputContainerClass} style={{marginRight: marginRight ? marginRight : 0}}>
        <div className="label-container">{label} {mandatoryText} {mandatoryBox} {optionalBox}
          {tooltipText &&
            <Tooltip title={tooltipText} placement="top">
              <img alt="tooltip" className="tooltip-text" src={HelpIcon} />
            </Tooltip>
          }
        </div>
        {<div className={errorMessageClass}>{errorText}</div>}
        <div className="drop-list">
          <select disabled={disabled} name={this.props.name} value={value} className={error ? "error "+singleSelectClassName : singleSelectClassName} onChange={(e) => this.handleChange(e)}>
            {displayOptions}
          </select>
        </div>
        {explanatoryText && <div className="explanatory-text">{explanatoryText}</div>}
      </div>
    );
  }
}

export default SelectDropdown;
// error={true}
// value={selectedOption}
// onChange={this.handleChange}
// options={options ? options : defaultOptions}
